import { FunctionComponent } from 'react';
import {isCurrentUserGod} from '../helpers/auth-helper';
import MGLogo from '../img/mg_logo.png'
import CaptinaLogo from '../img/captina_logo.png'

const SquamaFooter: FunctionComponent = () => {
    const current_user_is_god = isCurrentUserGod();
    return(
        <div className="main-footer background-footer white-font">
            <a className="footer-link" href="/legalmentions">Mentions légales</a>
            <a className="footer-link" href="/confidentialpolitics">Politique de confidentialité</a>
            <div className="footer-copyright">Copyright © 2021-2023 SQUAMA SAS All rights reserved.{(current_user_is_god)?"("+process.env.REACT_APP_LAST_UPDATED_WEBSITE+")":""}</div>
            <a className="footer-captina" href="https://www.captina.dev" target="_blank" rel="noreferrer">
                <img className="footer-logo-captina" src={CaptinaLogo} alt=""/>
            </a>
            <a className="footer-mg" href="https://www.georgeon-photos.com" target="_blank" rel="noreferrer">
                <img className="footer-logo-mg" src={MGLogo} alt=""/>
            </a>
        </div>
    );
}

export default SquamaFooter;