
export function transApi(in_text: string):string{
  switch(in_text) {
    case "The request has been successfully done":
      return "La requête a été traitée avec succès";
    case "Password has been updated":
      return "Le mot de passe a été modifié";
    case "The request failed":
      return "La requête a échouée";
    case "No request associated to this link":
      return "Il n'y a pas de requête associée à ce lien";
    case "You are unsubscribed":
      return "Vous êtes désinscris";
    default:
      return in_text;
  }
}
