import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import Station from '../../models/station';
import Company from '../../models/company';

import StationService from '../../services/station-service';
import CompanyService from '../../services/company-service';

import TextTable from '../TextTable';

const ModalShowTree: FunctionComponent = () => {

  const [stations, setStations] = useState<Station[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [showModal, setshowModal] = useState(false);

  const handleCloseModal = () => setshowModal(false);
  const handleShowModal = () => {
    CompanyService.getCompanies().then(companies => {
      setCompanies(companies)
    })
    setshowModal(true);
  }

  function modal_show_tree() {

    function company_selector(companies: Company[]) {
      const handleChange = (event: React.ChangeEvent<any>) => {
        StationService.getStationsForACompany(event.target.value).then(stations => {
          console.log(stations);
          setStations(stations)
        });
      }
      return (
        <select onChange={handleChange}>
          <option value="0">NoCompany</option>
          {
            (companies) && companies.map(company => {
              return (<option value={company.id} key={company.id}>{company.name}</option>)
            })
          }
        </select>
      )
    }

    const TransmitterColumns =
    [
      {
        header: "ID",
        accessorKey: "id",
      },
      {
        header: "MAC",
        accessorKey: "mac",
      },
    ]


    const StationColumns =
    [
      {
        header: "ID",
        accessorKey: "id",
      },
      {
        header: "Name",
        accessorKey: "name",
      },
      {
        header: "Battery",
        accessorKey: "battery",
      },
      {
        header: "Sensor ID",
        accessorKey: "sensor_id",
      },
      {
        header: "Transmitteurs",
        accessorKey: "transmitters",
        minSize:500,
        cell: (info:any) =><TextTable allValues={info.transmitters} valuesColumns={TransmitterColumns}/>
      },
      {
        header: "Contacts",
        accessorKey: "accesses",
        cell: (info:any) =><div><TextTable orderByDesc={true} orderBy="id" allValues={info.accesses} valuesColumns={TransmitterColumns}/>salut</div>
      },
    ];

    return (
      <Modal
        dialogClassName="modal-show-tree"
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header translate="yes" closeButton>
          <Modal.Title>Arbre de la société</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col align-self-center"></div>
              <div className="col align-self-center">
                {company_selector(companies)}
              </div>
              <div className="col align-self-center"></div>
              <TextTable orderByDesc={true} orderBy="date" searchedText={["value"]} allValues={stations} valuesColumns={StationColumns}/>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="squama-btn-navbar squama-btn-gestion-modal" onClick={handleCloseModal}>
            Fermer
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
  return (
    <div>
      {modal_show_tree()}
      <button type="button" className="squama-btn-navbar squama-btn-gestion" onClick={handleShowModal}>
        Visualiser l'arbre
      </button>
    </div>
  )
}

export default ModalShowTree;
