import { FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./squama-gestion.css";
import SquamaNavbar from '../components/squama-navbar';

import ModalCreateModifyCompany from '../components/modals/modal-create-modify-company';
import ModalCreateModifyUser from '../components/modals/modal-create-modify-user';
import ModalCreateModifyStation from '../components/modals/modal-create-modify-station';
import ModalCreateModifyTransmitteur from '../components/modals/modal-create-modify-transmitteur';
import ModalShowTree from '../components/modals/modal-show-tree';
import ModalShowTransmetteursTextTable from '../components/modals/modal-show-transmetteurs';
import ModalShowSensorsTextTable from '../components/modals/modal-show-sensors';
import ModalShowSensorTestsTextTable from '../components/modals/modal-show-sensor-tests';

import { isLoggedIn } from '../helpers/auth-helper';
import ModalShowDatatosendTextTable from '../components/modals/modal-show-datatosend';
import ModalShowLoginsTextTable from '../components/modals/modal-show-logins';
import { return_logo_from_type } from '../helpers/station-helper';

const SquamaGestion: FunctionComponent = () => {
  const displayName = "SquamaGestion:";
  const enableDebug = false;
  const history = useNavigate();
  const url = window.location.href;
  let suffix_url = "production"
  if(url.includes("alpha")){
    suffix_url="alpha";
  }else if(url.includes("beta")){
    suffix_url="beta";
  }

  if(enableDebug){
    console.log(displayName,"url:",url)
    console.log(displayName,"suffix_url:",suffix_url)
  }
  if (!isLoggedIn()) {
    history('/accueil');
  }

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-gestion-div-flex-1 the-background">
        <div className="main-gestion-div">
          <Link to='/map'>
            <button type="button" className="squama-btn-navbar squama-btn-gestion">
              Page Nanogis(Carte + Liste)
            </button>
          </Link>
        </div>
        <div className="main-gestion-div">
          <ModalShowTree />
          <ModalShowDatatosendTextTable />
        </div>
        <div className="main-gestion-div">
          <ModalShowLoginsTextTable />
        </div>
        <div className="main-gestion-div">
          <ModalShowTransmetteursTextTable />
          <ModalShowSensorsTextTable />
          <ModalShowSensorTestsTextTable />
        </div>
        <div className="main-gestion-div">
          {return_logo_from_type("loader","loader-logo")}
        </div>
        <div className="main-gestion-div">
          <ModalCreateModifyCompany />
          <ModalCreateModifyStation />
          <ModalCreateModifyUser />
          <ModalCreateModifyTransmitteur />
        </div>
        <div className="main-gestion-div">
          <div className="squama-item-navbar"><Link to={'https://logs-'+suffix_url+'.squama.fr'} target="_blank"><button type="button" className="squama-btn-navbar">Logs</button></Link></div>
          <div className="squama-item-navbar"><Link to={'https://broker-'+suffix_url+'.squama.fr'} target="_blank"><button type="button" className="squama-btn-navbar">Broker</button></Link></div>
          <div className="squama-item-navbar"><Link to={'https://flower-'+suffix_url+'.squama.fr'} target="_blank"><button type="button" className="squama-btn-navbar">Flower</button></Link></div>
          <div className="squama-item-navbar"><Link to={'https://bd-'+suffix_url+'.squama.fr/?pgsql=postgres%3A2345&username=aurelien_mathieu&db=api&ns=public'} target="_blank"><button type="button" className="squama-btn-navbar">DB</button></Link></div>
        </div>
      </div>
    </div>
  );
}

export default SquamaGestion;