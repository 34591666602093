import AuthenticationService from '../services/authentication-service';

export function adminAuthHeader() {
    return "BouRgeon"
}

export function authHeader() {
    // return authorization header with jwt token
    //getCurrentUserLoggedInId();
    const currentUser = AuthenticationService.currentUserValue();
    if (currentUser ) {
        return `${currentUser}`;
    } else {
        return "";
    }
}
