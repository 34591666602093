import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";

import Station from '../../models/station';
import Company from '../../models/company';

import StationService from '../../services/station-service';
import CompanyService from '../../services/company-service';

type ModalItems = {
  companyIdSelected: number;
  companyName: string;
  companyAddress: string;
  companyEmail: string;
  companyPhone: string;
}

const ModalCreateModifyCompany: FunctionComponent = () => {

  const { register, handleSubmit } = useForm<ModalItems>();

  const [stations, setStations] = useState<Station[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [theCompany, setTheCompany] = useState<Company | null>();
  const [companyName, setCompanyName] = useState<string>();
  const [companyAddress, setCompanyAddress] = useState<string>();
  const [companyPhone, setCompanyPhone] = useState<string>();
  const [companyEmail, setCompanyEmail] = useState<string>();
  const [isNewCompanySelected, setIsNewCompanySelected] = useState<boolean>();

  const [showModal, setshowModal] = useState(false);
  const handleCloseModal = () => {
    setshowModal(false);
  }

  const handleShowModal = () => {
    CompanyService.getCompanies().then(companies => {
      setCompanies(companies)
    })
    initDatas();
    setshowModal(true);
  }

  function initInputValues() {
    setCompanyName("");
    setCompanyAddress("");
    setCompanyPhone("");
    setCompanyEmail("");
    setStations([]);
  }

  function initDatas(){
    setIsNewCompanySelected(true);
    initInputValues();
  }

  function modal_create_company(){

    const onHookFormSubmit = (data: ModalItems) => {
      //console.log("data", data);
      let same_company_name = companies.find(company => (company.name === data.companyName));
      if ((same_company_name)&&(Number(same_company_name.id) !== Number(data.companyIdSelected))){
        window.confirm('Ce nom de société existe déjà !')
        console.log("This Company name already exists cannot modify");
        console.log("same_company_name.id:"+same_company_name.id);
        console.log("data.companyIdSelected:"+data.companyIdSelected);
        return;
      }
      if (Number(data.companyIdSelected) === 0) {
        let newCompany = new Company(0, data.companyName, data.companyAddress, data.companyPhone, data.companyEmail)
        console.log("New company:"+newCompany);
        CompanyService.addCompany(newCompany).then(() => handleCloseModal());
      }else{
        if (theCompany){
          theCompany.name = data.companyName;
          theCompany.address = data.companyAddress;
          theCompany.phone = data.companyPhone;
          theCompany.email = data.companyEmail;
          CompanyService.updateCompany(theCompany).then(() => handleCloseModal());
        }
      }
    };

    const onHookFormDelete = () => {
      //console.log("data", data);
      if (window.confirm('Etes vous sur de vouloir supprimer cette société?')){
        if (theCompany){
          //1st Delete associated Users (All are link on first station)
          //Not deleting users here !
          //Spine will do it
          //2nd Delete the company
          console.log ("Going to delete company id:"+theCompany.id+" named:"+theCompany.name);
          CompanyService.deleteCompany(theCompany.id).then(() => handleCloseModal());}
      }
    };
    
    const handleSelectChange = (event: React.ChangeEvent<any>) => {
      //console.log(event.target.value)
      if(Number(event.target.value) > 0){
        console.log("setIsNewCompanySelected(false)");
        setIsNewCompanySelected(false);
        CompanyService.getCompany(event.target.value).then(company => {
          setTheCompany(company);
          if(company){
            console.log(company)
            console.log("assigned name as :"+company.name)
            initInputValues();
            setCompanyName(company.name);
            setCompanyAddress(company.address);
            setCompanyPhone(company.phone);
            setCompanyEmail(company.email);
          }
        })
        StationService.getStationsForACompany(event.target.value,'["no_companies_desc"]').then(stations => {
          setStations(stations);
        });
      }else{
        console.log("setIsNewCompanySelected(true)");
        initDatas();
      }
    }

    function showDelete(){
      return(
        <div className="col align-self-center">
          <button type="button" className="squama-btn-navbar squama-btn-gestion-delete squama-btn-gestion-modal" onClick={onHookFormDelete}>
            Supprimer
          </button>
        </div>
      )
    }

    function hideDelete(){
      return(
        <div className="col align-self-center"></div>
      )
    }

      return(
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            onSubmit={handleSubmit(onHookFormSubmit)}
            backdrop="static"
            centered
          >
            <Modal.Header translate="yes" closeButton>
              <Modal.Title className="squama-font">Créer/Modifier une société</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row m-0">
                  <div className="col align-self-center"></div>
                  {
                  <div className="col align-self-center">
                    <select className="squama-font" {...register("companyIdSelected",{ required: true })} onChange={handleSelectChange}>
                      <option value="0">NouvelleSociété</option>
                      {
                      (companies) && companies.map(company => {
                        return(<option value={company.id} key={company.id}>{company.name}</option>)
                      })
                      }
                    </select>
                  </div>
                  }
                  <div className="col align-self-center"></div>
                  {(isNewCompanySelected) ? hideDelete() : showDelete()}
                  <div className="col align-self-center"></div>
                </div>
                <div><hr></hr></div>
                <div className="row m-0">
                  <div className="col-5 align-self-center squama-font">Nom :</div>
                  <div className="col-7 align-self-center">
                    <input id="companyName" type="text" className="stay-in-div" value={companyName} {...register("companyName",{ required: true })} onChange={(e) => {setCompanyName(e.target.value)}} />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-5 align-self-center squama-font">Adresse :</div>
                  <div className="col-7 align-self-center">
                    <input id="companyAddress" type="text" className="stay-in-div" value={companyAddress} {...register("companyAddress",{ required: true })} onChange={(e) => {setCompanyAddress(e.target.value)}}/>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-5 align-self-center squama-font">Téléphone :</div>
                  <div className="col-7 align-self-center">
                    <input id="companyPhone" type="text" className="stay-in-div" value={companyPhone} {...register("companyPhone",{ required: true })} onChange={(e) => {setCompanyPhone(e.target.value)}}/>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-5 align-self-center squama-font">Email :</div>
                  <div className="col-7 align-self-center">
                    <input id="companyEmail" type="text" className="stay-in-div" value={companyEmail} {...register("companyEmail",{ required: true })} onChange={(e) => {setCompanyEmail(e.target.value)}}/>
                  </div>
                </div>
                <div><hr></hr></div>
                <div className="row m-0">
                  <div className="col-5 align-self-center squama-font">Stations Associées :</div>
                  <div className="col-7 align-self-center">
                    <ul>
                      {
                        (stations) && stations.map(station => {
                          return(<li value={station.id} key={"station-"+station.id}>{station.name}</li>)
                      })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="squama-btn-navbar squama-btn-gestion-modal" onClick={handleCloseModal}>
                Annuler
              </button>
              <button type="submit" className="squama-btn-navbar squama-btn-gestion squama-btn-gestion-modal" onClick={handleSubmit(onHookFormSubmit)}>
                Enregistrer
              </button>
            </Modal.Footer>
          </Modal>
      )
    }
    return (
        <div className="squama-item-navbar">
          {modal_create_company()}
          <button type="button" className="squama-btn-navbar squama-btn-gestion" onClick={handleShowModal}>
          Créer/Modifier une société
          </button>
        </div>
    )
}

export default ModalCreateModifyCompany;