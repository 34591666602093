import Company from "../models/company";
import {authHeader} from '../helpers/auth-headers';
import Access from "../models/access";
import SiteService from "./site-service";

export default class CompanyService {
  static companies:Company[];

  static getCompanies(): Promise<Company[]> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader()
    }
    return fetch(SiteService.getApiUrl()+'companies',{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getCompany(id: number): Promise<Company|null> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader()
    }
    console.log("getCompany:"+id)
    return fetch(SiteService.getApiUrl()+`companies/${id}`,{headers})
    .then(response => response.json())
    .then(data => this.isEmpty(data) ? null : data)
    .catch(error => this.handleError(error));
  }

  static updateCompany(company: Company): Promise<Company> {
      return fetch(SiteService.getApiUrl()+`companies/${company.id}`, {
        method: 'PUT',
        body: JSON.stringify(company),
        headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
      })
      .then(response => response.json())
      .catch(error => this.handleError(error));
  }

  static deleteCompany(companyId: number): Promise<{}> {
    console.log("Delete company id:"+companyId);
    return fetch(SiteService.getApiUrl()+`companies/${companyId}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static addCompany(company: Company): Promise<Company> {
    company.created = new Date(company.created);
    return fetch(SiteService.getApiUrl()+`companies/0`, {
      method: 'POST',
      body: JSON.stringify(company),
      headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getOneUserIdOfCompany(companyId: number, userId: number): Promise<Access> {
    console.log("getOneUserIdOfCompany("+companyId+","+userId+")");
    return fetch(SiteService.getApiUrl()+`company_user/${companyId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json',
                  'user_id':userId.toString(),
                  'token': authHeader()}
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getUsersOfCompany(companyId: number): Promise<Access[]> {
    console.log("getUsersOfCompany("+companyId+")");
    return fetch(SiteService.getApiUrl()+`company_user/${companyId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static addUserToCompany(userId: number, companyId: number, accessContract: boolean, accessTechnical: boolean, userEmail: string): Promise<{}> {
    return fetch(SiteService.getApiUrl()+`company_user/${companyId}`, {
      method: 'POST',
      body: JSON.stringify({
        "user_id":userId,
        "user_email":userEmail,
        "company_id":companyId,
        "access_contract":accessContract,
        "access_technical":accessTechnical
      }),
      headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static updateUserToCompany(userId: number, companyId: number, accessContract: boolean, accessTechnical: boolean, userEmail: string): Promise<{}> {
    return fetch(SiteService.getApiUrl()+`company_user/${companyId}`, {
      method: 'PUT',
      body: JSON.stringify({
        "user_id":userId,
        "user_email":userEmail,
        "company_id":companyId,
        "access_contract":accessContract,
        "access_technical":accessTechnical
      }),
      headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static deleteUserToCompany(userId: number, companyId: number): Promise<{}> {
    return fetch(SiteService.getApiUrl()+`company_user/${companyId}`, {
      method: 'DELETE',
      body: JSON.stringify({
        "user_id":userId,
        "company_id":companyId
      }),
      headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static searchCompany(term: string): Promise<Company[]> {
    return fetch(SiteService.getApiUrl()+`companies?q=${term}`)
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static isEmpty(data: Object): boolean {
    return Object.keys(data).length === 0;
  }

  static handleError(error: Error): void {
    console.error(error);
  }
}
