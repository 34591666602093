import React, { FunctionComponent } from 'react';
import MatAProposLogo from '../img/apropos-mat-798x1095.png'
import AurelAProposLogo from '../img/apropos-aurel-798x1095.png'
import APropos4IMG from '../img/a_propos_4-488x323.png'
import APropos13IMG from '../img/a_propos_13-288x150.png'
import APropos15IMG from '../img/a_propos_15-288x150.png'
import MadeInFrance from '../img/made_in_france-615x615.png'
import SquamaNavbar from '../components/squama-navbar';

const SquamaAPropos: FunctionComponent = () => {
  
  function people_presentation(photo_url:string, name:string, presentation:string){
    return (
      <div className="apropos-2_3_together-line">
        <div className="apropos-2_3-left">
          <img src={photo_url} className="apropos-photo-nous" alt=""/>
        </div>
        <div className="apropos-2_3-right">
          <div className="apropos-2_3-right-name">{name}</div>
          <div className="apropos-2_3-right-presentation">{presentation}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-center-div the-background">
        <div className="apropos-main">
          <div className="apropos-1">
            QUI SOMMES NOUS ?
          </div>
          <div className="apropos-2_3_together">
            {people_presentation(
              MatAProposLogo,
              "MATHIEU GEORGEON",
              "Hydrobiologiste au sein de différentes structures de gestion piscicole, je bénéficie d'expertises en matière de biologie et écologie des populations, de fonctionnement des écosystèmes aquatiques et de restauration de la continuité."
            )}
            {people_presentation(
              AurelAProposLogo,
              "AURÉLIEN BOUIN",
              "Développeur expérimenté dans l'électronique et l'informatique, j'ai l'opportunité de travailler dans des domaines variés. Amateur de pêche et des milieux aquatiques depuis mon plus jeune âge, le projet SQUAMA me permet d'allier technique et sensibilité environnementale."
            )}
          </div>
          <div className="apropos-4_5_together">
            <div className="apropos-4">
              <div className="apropos-4-up">
                L’ORIGINE DU PROJET
              </div>
              <img src={APropos4IMG} className="apropos-4-img" alt=""/>
            </div>
            <div className="apropos-5">
              <div className="apropos-paragraphe">
                Spécialiste des migrateurs amphihalins, Mathieu a été confronté à l’impact des dysfonctionnements hydrauliques des passes à poissons qui remettent en cause d’une part les tendances populationnelles observées aux stations de comptage et d’autre part la pérennité de certaines espèces qui ne peuvent accéder à leurs zones de reproduction ou de croissance. Un constat malheureusement fréquent puisque plus de la moitié des passes à poissons ne seraient pas fonctionnelles à cause d’un défaut d’entretien (Germis et al, 2013 ; Saumon-Rhin, 2010 ; Boucault, 2007 ; Gaberel, 2005) et ce, malgré une obligation réglementaire !
              </div>
              <div className="apropos-paragraphe">
                Une surveillance chronophage (pas toujours compatible avec le plan de charge des gestionnaires), un coût élevé (en temps, en déplacements ou en prestataires) ou encore une conscience parfois limitée des personnes en charge du suivi des impacts environnementaux d’un dispositif non fonctionnel notamment en période de migration, peuvent le plus souvent expliquer ce constat.
              </div>
            </div>
          </div>
          <div className="apropos-6">
            <div className="apropos-paragraphe">
            Au-delà de cet exemple concret lié à la continuité écologique, il apparait de manière générale que le suivi et la surveillance des milieux aquatiques peut être couteux et chronophage. Il paraissait alors nécessaire de proposer des solutions pour accompagner les gestionnaires de manière à optimiser leur temps et augmenter leur réactivité en cas de crise (dysfonctionnement de passe à poissons en période sensible de migration, gestion des assecs…).
            </div>
            <div className="apropos-paragraphe">
            Dès lors, amis de longue date et habités d’une volonté commune d’agir pour la préservation de l’environnement et de la biodiversité nous avons mobilisé nos domaines de compétence respectifs pour développer le projet SQUAMA.
            </div>
          </div>
          <div className="apropos-7_11_together">
            <div className="apropos-7_10_together">
              <div className="apropos-7">
                <div className="apropos-7-up">
                  NOS VALEURS
                </div>
                <div className="apropos-paragraphe">
                Parce que l’engagement écologique et éthique est fondamental à nos yeux, nos produits sont fabriqués en France, en Anjou, à moins de 50 km de notre siège social !
                </div>
              </div>
              <div className="apropos-8_9_together">
                <div className="apropos-8">
                  <div className="apropos-paragraphe">
                    En produisant en France,
                    <br/>
                    &nbsp;nous garantissons :
                  </div>
                </div>
                <img src={MadeInFrance} className="apropos-9-img" alt=""/>
              </div>
              <div className="apropos-10">
                <div className="apropos-paragraphe">
                - un faible impact environnemental (en limitant les transports)
                </div>
                <div className="apropos-paragraphe">
                - des produits de qualité (soumis à des normes strictes et un savoir-faire précis de nos prestataires)
                </div>
                <div className="apropos-paragraphe">
                - des conditions de travail respectueuses des salariés ainsi que le soutien à l’emploi et à l’économie locale !
                </div>
              </div>
            </div>
            <div className="apropos-11_15_together">
              <div className="apropos-11">
                <div className="apropos-11-up">
                  NOS PARTENAIRES
                </div>
                <div className="apropos-paragraphe">
                Pour garantir la qualité de notre produit sur l’ensemble de son cycle de vie, il nous paraissait essentiel de s’entourer de partenaires de qualité qui partagent nos valeurs et avec qui nous travaillons en toute confiance.
                </div>
              </div>
              <div className="apropos-12_13-14_15_together">
                <div className="apropos-12_14">
                  <div className="apropos-paragraphe">
                    Ainsi, nos solutions sont éprouvées en conditions réelles d’utilisation grâce à nos partenaires testeurs qui nous informent régulièrement sur leur fonctionnement et nous proposent les améliorations qui leur paraissent souhaitables.
                  </div>
                </div>
                <img src={APropos13IMG} className="apropos-13_15-img" alt=""/>
              </div>
              <div className="apropos-12_13-14_15_together">
                <div className="apropos-12_14">
                  <div className="apropos-paragraphe">
                    De même, parce que nous proposons des prestations complémentaires en faveur des gestionnaires des milieux aquatiques et parce qu’elles connaissent parfaitement le domaine d’application de nos produits, nous avons établi des partenariats techniques et commerciaux avec les sociétés NanoGis et Stream-Innov.
                  </div>
                </div>
                <img src={APropos15IMG} className="apropos-13_15-img" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default SquamaAPropos;