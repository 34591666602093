import { useState, useEffect, FunctionComponent, Fragment } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  ColumnDef,
  SortingState,
} from '@tanstack/react-table';
//https://dev.to/esponges/create-a-reusable-react-table-component-with-typescript-56d4

export type Props = {
  allValues: object[];
  valuesColumns: ColumnDef<object, any>[];
  searchedText?: Array<string>;
  numberOfElementDisplayed?: number;
  orderBy?: string;
  orderByDesc?: boolean;
  allowPagination?: boolean;
  displayFooter?: boolean;
  classNameHeader?: string;
  classNameTH?: string;
  classNameTD?: string;
};

const TextTable: FunctionComponent<Props> = ({
  allValues,
  valuesColumns,
  searchedText,
  allowPagination = true,
  numberOfElementDisplayed = 20,
  orderBy = "",
  orderByDesc = false,
  displayFooter = true,
  classNameHeader = "table-text-thead",
  classNameTH = "table-text-th",
  classNameTD = "table-text-td"
}) => {

  const displayName = "TextTable:";
  const enableDebug = false;
  const [data, setData] = useState<object[]>(allValues);
  const [columns, setColumn] = useState<ColumnDef<object, any>[]>([]);
  const [sorting, setSorting] = useState<SortingState>([])

  // Use the useTable Hook to send the columns and data to build the table
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(), //order doesn't matter anymore!
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useEffect(() => {
    (allValues) && (allValues.length) && setData(allValues);
    if (orderBy !== "") {
      setSorting([{ id: orderBy, desc: orderByDesc }]);
    }
    if (valuesColumns) {
      setColumn(valuesColumns);
      /*
      const tmp = useMemo ( ()=> valuesColumns);
      setColumn(tmp);
      */
    }
    if (numberOfElementDisplayed !== 0) {
      table.setPageSize(numberOfElementDisplayed);
    }
    /*
    if(allowPagination===false){
      setPageSize(data.length);
    }
    */
  }, [allValues, valuesColumns, numberOfElementDisplayed]);


  return (
    <div>
      {/*
        allValues&&allValues.length&&allValues.map(oneValue=>{
          return(<div>{oneValue.value}</div>);
        })
      */}
      {(false) && (searchedText) && (searchedText.length) && searchedText.map((oneSearchedText: string) => {
        return (<div>{"Search " + oneSearchedText}</div>);
      })
      }
      {/*
          (true)&&(searchedText)&&(searchedText.length)&&searchedText.map((oneSearchedText:string)=>{
            return(<input
              onChange={(e)=>{setFilter(oneSearchedText, e.target.value);}}
              placeholder={"Search "+oneSearchedText}
            />)
            })
          */
      }
      {
        allValues && allValues.length && (
          <div className='text-table-main'>
            <table className='table-text-table'>
              <thead className={classNameHeader}>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                      return (
                        <th {...{
                          className: classNameTH,
                          key: header.id,
                          colSpan: header.colSpan,
                          style: { width: header.getSize() }
                        }}>
                          {header.isPlaceholder ? null : (
                            <div
                              {...{
                                className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                //https://www.compart.com/fr/unicode/U+25BC
                                //asc: ' 🔼',
                                //desc: ' 🔽',
                                //asc: ' ▴',
                                //desc: ' ▾',
                                //asc: ' ⏶',
                                //desc: ' ⏷',
                                asc: ' ▲',
                                desc: ' ▼',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          )}
                        </th>
                      )
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className='table-text-tbody'>
                {table.getRowModel().rows.map(row => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td {...{
                            className: classNameTD,
                            key: cell.id,
                            style: { width: cell.column.getSize() },
                          }}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="h-2" />
            {(displayFooter) && (
              <Fragment>
                <div className="flex items-center gap-2">
                  <button
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {'<<'}
                  </button>
                  <button
                    className="border rounded p-1"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {'<'}
                  </button>
                  <button
                    className="border rounded p-1"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    {'>'}
                  </button>
                  <button
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    {'>>'}
                  </button>
                  <span className="flex items-center gap-1">
                    <div>Page</div>
                    <strong>
                      {table.getState().pagination.pageIndex + 1} of{' '}
                      {table.getPageCount()}
                    </strong>
                  </span>
                  <span className="flex items-center gap-1">
                    | Aller à la page:
                    <input
                      type="number"
                      defaultValue={table.getState().pagination.pageIndex + 1}
                      onChange={e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        table.setPageIndex(page)
                      }}
                      className="border p-1 rounded w-16"
                    />
                  </span>
                  <select
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                      table.setPageSize(Number(e.target.value))
                    }}
                  >
                    {[10, 20, 30, 40, 50, 100, 200, 1000].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Montrer {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                <div>Affiche {table.getRowModel().rows.length} / {data.length} Lignes</div>
              </Fragment>
            )}
          </div>
        )
      }
    </div>
  );
}

export default TextTable;
