import { FunctionComponent, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {add_quarter_to_date, convert_date_to_UTC, format_date } from '../../helpers/format-date';
import TextTable from '../TextTable';
import Datatosend from '../../models/datatosend';
import DeviceapiService from '../../services/deviceapi-service';
import Transmitter from '../../models/transmitter';
import { io } from "socket.io-client";
import {authHeader} from '../../helpers/auth-headers';
import SiteService from '../../services/site-service';
import { Link } from 'react-router-dom';
import { transmitter_get_number_of_quarter_before_next_trame } from '../../helpers/station-helper';

const ModalShowDatatosendTextTable: FunctionComponent = () => {
  const displayName = "ModalShowDatatosendTextTable:";
  const enableDebug = false;

  const [datatosendToDisplay, setDatatosendToDisplay] = useState<Datatosend[]>([]);
  const [datatosend, setDatatosend] = useState<Datatosend[]>([]);
  const [transmitters, setTransmitters] = useState<Transmitter[]>([]);
  const [showModal, setshowModal] = useState(false);
  const [retrieveDatas, setRetrieveDatas] = useState(true);

  useEffect(() => {
    if(enableDebug) console.log(displayName,"useEffect show Modal");
    if(showModal || retrieveDatas){
      setRetrieveDatas(false);
      DeviceapiService.getDataToSend().then( data => {
        setDatatosend(data);
      });
    }else{
      setDatatosend([]);
      setDatatosendToDisplay([]);
    }
  },[showModal, retrieveDatas]);
  useEffect(() => {
    if(datatosend && datatosend.length && transmitters && transmitters.length){
      if(enableDebug) console.log(displayName,"useEffect associate transmitters to dataToSend");
      datatosend.forEach(one_datatosend=>{
        const the_transmitter = transmitters.find(one_transmitter => one_transmitter.mac === one_datatosend.device)
        if(the_transmitter){
          if(enableDebug) console.log(displayName,"the_transmitter:",the_transmitter);
          one_datatosend.station_id = the_transmitter.station_id;
          one_datatosend.station_name = the_transmitter.station_name;
          one_datatosend.company_id = the_transmitter.company_id;
          one_datatosend.company_name = the_transmitter.company_name;
          one_datatosend.last_trame_received = the_transmitter.last_trame_received;
          one_datatosend.current_send_each_n_quarter = the_transmitter.current_send_each_n_quarter;
        }else{
          if(enableDebug) console.log(displayName,"Not found");
        }
      })
    }
    setDatatosendToDisplay(datatosend);
  },[datatosend, transmitters]);
  useEffect(() => {
    if(enableDebug) console.log(displayName,"useEffect request transmitters to API");
    if(!showModal){return;}
    const socket = io(SiteService.getWebsocketUrl(), {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
      extraHeaders: {
        "my-custom-header": "1234" // WARN: this will be ignored in a browser
      }
    });
    socket.on("transmitters_desc", (the_transmitters_desc_str: string) => {
      const the_transmitters = JSON.parse(the_transmitters_desc_str);
      if (enableDebug) console.log(displayName, "Transmitters:", the_transmitters);
      setTransmitters(the_transmitters);
      setDatatosendToDisplay([]);
    });
    if(datatosend && datatosend.length){
      let transmitters_mac_array : Array<string> = [];
      datatosend.forEach(one=>{
        transmitters_mac_array.push(one.device)
      })
      if(enableDebug) console.log(displayName,"will request details for transmitters mac:",transmitters_mac_array);
      const the_request: any = {
        type: "transmitters_desc",
        number: transmitters_mac_array.length,
        liste: transmitters_mac_array,
        from: "transmitter_mac",
        filter: ["station_name","station_id","company_name","company_id"],
        token: authHeader()
      }
      socket.emit("request", the_request);
    }
  },[datatosend]);

  const handleCloseModal = () => setshowModal(false);
  const handleShowModal = () => setshowModal(true);
  const handleReloadModal = () => setRetrieveDatas(true);

  function modal_show_tree() {
    const TextValueColumns =
    [
      {
        header: "Data à envoyer aux transmetteurs",
        footer: (props:any) => props.column.id,
        columns: [
        {
          header: "ID",
          accessorKey: "id",
          maxSize: 50,
        },
        {
          header: "MAC",
          accessorKey: "device",
        },
        {
          header: "Data",
          accessorKey: "data",
          minSize:750,
        },
        {
          header: "Last Trame",
          accessorKey: "last_trame",
          minSize:145,
          accessorFn: (row:any) =>(row.last_trame_received),
        },
        {
          header: "Next Trame",
          accessorKey: "next_trame",
          minSize: 145,
          accessorFn: (row:any) =>(new Date(add_quarter_to_date(row.last_trame_received,transmitter_get_number_of_quarter_before_next_trame(row)))),
          cell: (info:any) => <div
                              className={
                                (new Date(info.getValue())>new Date())?
                                "modal-show-transmitter-table-element-ok":"modal-show-transmitter-table-element-ko"
                              }
                              >{format_date(info.getValue())}</div>,
        },
        {
          header: "One Time ?",
          accessorKey: "one_time",
          maxSize:50,
        },
        {
          header: "Type",
          accessorKey: "type",
          maxSize:50,
        },
        {
          header: "Station Name",
          accessorKey: "station_name",
          maxSize:100,
        },
        {
          header: "Station ID",
          accessorKey: "station_id",
          maxSize:50,
          cell: (info:any) => <Link to={'/stations/'+info.getValue()+'/setup'}>{info.getValue()}</Link>,
        },
        {
          header: "Company Name",
          accessorKey: "company_name",
          maxSize:100,
        },
        {
          header: "Company ID",
          accessorKey: "company_id",
          maxSize:50,
          cell: (info:any) => <Link to={'/companies/'+info.getValue()}>{info.getValue()}</Link>,
        },
        ]
      },
      ]
      ;

    return (
      <Modal
        dialogClassName="modal-show-tree"
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header translate="yes" closeButton>
          <Modal.Title>Données à envoyer aux transmetteurs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col align-self-center">Now(UTC):{format_date(convert_date_to_UTC(new Date()))}</div>
              <div className="col align-self-center">Now:{format_date(new Date())}</div>
            </div>
            {
              (datatosendToDisplay) && (datatosendToDisplay.length) && (
                <TextTable orderBy='next_trame' orderByDesc={false} allowPagination={true} numberOfElementDisplayed={30} searchedText={["id","device"]} allValues={datatosendToDisplay} valuesColumns={TextValueColumns}/>
              )
             }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="squama-btn-navbar squama-btn-gestion-modal" onClick={handleReloadModal}>Reload</button>
          <button type="button" className="squama-btn-navbar squama-btn-gestion-modal" onClick={handleCloseModal}>
            Fermer
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
  return (
    <div>
      {modal_show_tree()}
      <button type="button" className="squama-btn-navbar squama-btn-gestion" onClick={handleShowModal}>
        Visualiser Données à envoyer
      </button>
    </div>
  )
}

export default ModalShowDatatosendTextTable;
