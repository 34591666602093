import React, { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import {
        return_logo_from_type,
        is_transmitter_module_gprs_or_wifi,
        is_transmitter_type_module_gprs_or_wifi,
        transmitter_hw_is_at_least_esp32,
        limit_char,
        is_station_fully_loaded,
        transmitter_get_number_of_quarter_before_next_trame,
        transmitter_get_battery_strategy_explanation,
        fw_nicely_displayed
      } from '../helpers/station-helper';
import Station from '../models/station';
import StationService from '../services/station-service';
import AuthenticationService from '../services/authentication-service';
import Transmitter from '../models/transmitter';
import TransmitterService from '../services/transmitter-service';
import OtaFirmware from '../models/ota_firmware';
import { add_quarter_to_date, format_date } from '../helpers/format-date';
import {Item, setTransmitterItems, getItemFromName, updateItemFromNameWithValueAndSetter} from '../helpers/items-helper';
import { debugFunctionIdValue } from '../helpers/tools';

type Props = {
  station: Station,
  fullscreen: boolean
};

type sample_frequency_modem_type_value_text = {
  value: number,
  value_txt: string
};

const StationCardInstaller: FunctionComponent<Props> = ({station, fullscreen}) => {
  const displayName = "StationCardAlarm:";
  const enableDebug = false;
  const enableDebugUseEffect = false;
  const history = useNavigate();
  const [updating, setUpdating] = useState<boolean>(false);
  const [items, setItems] = useState<Item[]>([]);
  const [otaFirmwares, setOtaFirmwares] = useState<OtaFirmware[]>([{port:0, length:0, host: "", path: "", value_txt: "No"}]);
  const stationFullyLoaded = is_station_fully_loaded(station);

  const modem_type_const : Array<sample_frequency_modem_type_value_text> = [
    {value:1,value_txt:"GPRS"},
    {value:2,value_txt:"WIFI"},
  ];
  const samples_const : Array<sample_frequency_modem_type_value_text> = [
      {value:1,value_txt:"15 min"},
      {value:2,value_txt:"30 min"},
      {value:4,value_txt:"1 h"},
      {value:8,value_txt:"2 h"},
      {value:16,value_txt:"4 h"},
      {value:24,value_txt:"6 h"},
      {value:32,value_txt:"8 h"},
      {value:48,value_txt:"12 h"},
      {value:96,value_txt:"24 h"},
    ];
  const frequencies_const : Array<sample_frequency_modem_type_value_text> = [
      {value:1, value_txt: "96"},
      {value:2, value_txt: "48"},
      {value:4, value_txt: "24"},
      {value:8, value_txt: "12"},
      {value:16,value_txt:  "6"},
      {value:24,value_txt:  "4"},
      {value:32,value_txt:  "3"},
      {value:48,value_txt:  "2"},
      {value:96,value_txt:  "1"},
  ];
  const current_user_can_modify = AuthenticationService.getUserCanAccessModify(station);
  const current_user_is_god = AuthenticationService.getUserIsGod();

  useEffect(() => {
    (enableDebugUseEffect)&&console.log(displayName,"useEffect start for station named ",station.name);
    if(stationFullyLoaded){
      let newItemArray: Array<Item> = [];
      setTransmitterItems(newItemArray, station?.transmitters);
      setItems([...items,...newItemArray])
      TransmitterService.getDownloadableFirmware().then(r => {
        setOtaFirmwares(r);
      }).catch(error => console.log(displayName,error));
    }
    (enableDebugUseEffect)&&console.log(displayName,"useEffect end for station named ",station.name);
    // eslint-disable-next-line
  }, [station, stationFullyLoaded]);

  const updateStation = () => {
    StationService.updateStation(station).then(() => {
      history(window.location.pathname);
      window.location.reload();
    });
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    (enableDebug)&&debugFunctionIdValue(displayName, "handleInputChange", e.target.id, e.target.value);
    updateItemFromNameWithValueAndSetter(items, e.target.id, e.target.value.slice(0,100), setItems);//Max 100char);
  }

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    (enableDebug)&&debugFunctionIdValue(displayName, "handleTextAreaChange", e.target.id, e.target.value);
    updateItemFromNameWithValueAndSetter(items, e.target.id, e.target.value.slice(0,100), setItems);//Max 100char);
  }

  const handleInstallationDateOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    (enableDebug)&&debugFunctionIdValue(displayName, "handleInstallationDateOnChange", e.target.id, e.target.value);
    updateItemFromNameWithValueAndSetter(items, e.target.id, e.target.value, setItems);
  }

  const handleGenericSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    (enableDebug)&&debugFunctionIdValue(displayName, "handleGenericSelectChange", e.target.id, e.target.value);
    updateItemFromNameWithValueAndSetter(items, e.target.id, e.target.value, setItems);
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    (enableDebug)&&debugFunctionIdValue(displayName, "handleCheckboxChange", e.target.id, e.target.value);
    updateItemFromNameWithValueAndSetter(items, e.target.id, (e.target.checked) ? 1:0, setItems);
  }

  function handleResetValue(id: string) {
    (enableDebug)&&debugFunctionIdValue(displayName, "handleResetValue", id, "");
    (current_user_can_modify)&&updateItemFromNameWithValueAndSetter(items, id, "", setItems);
  }

  function getGPSPosition() {
    (enableDebug)&&console.log(displayName, "getGPSPosition");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getGPSPositionSuccess, getGPSPositionError);
    } else {
      console.log("Geolocation not supported");
    }
  }

  function getGPSPositionSuccess(position:any) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const theItems = [...items];
    theItems.find(one => {
      if(one.name.includes("gps_lat")){
        one.value = latitude;
      }
      if(one.name.includes("gps_lon")){
        one.value = longitude;
      }
      return null;
    })
    setItems(theItems);
    (enableDebug)&&console.log("Latitude:", latitude, "Longitude:",longitude);

  }

  function getGPSPositionError() {
    console.log("Unable to retrieve your location");
  }

  const update_transmitters = (item: Item): void => {
    (enableDebug)&&console.log(displayName,"item :",item);
    const transmitter_id = Number(item.name.split("-")[1]);
    const transmitter_update_type = String(item.name.split("-")[2]);
    (enableDebug)&&console.log(displayName,"transmitter_id :",transmitter_id);
    if(station && station.transmitters){
      let theOne = station.transmitters.find(one => one.id===transmitter_id);
      if(theOne){
        (enableDebug)&&console.log(displayName,"update "+transmitter_update_type+" for transmitter id:"+transmitter_id+" with value:"+item.value);
        if(transmitter_update_type==="frequency"){
          theOne.new_send_each_n_quarter = Number(item.value)
        }
        if(transmitter_update_type==="samples"){
          theOne.new_sample_each_n_quarter = Number(item.value)
        }
        if(transmitter_update_type==="temperaturemask"){
          theOne.new_temp_mask = Number(item.value)
        }
        if(transmitter_update_type==="freestyle"){
          theOne.freestyle = item.value
        }
        if(transmitter_update_type==="comments"){
          theOne.comments = item.value
        }
        if(transmitter_update_type==="gps_lat"){
          theOne.gps_lat = item.value
        }
        if(transmitter_update_type==="gps_lon"){
          theOne.gps_lon = item.value
        }
        if(transmitter_update_type==="installation_date"){
          theOne.installation_date = item.value
        }
        if(transmitter_update_type==="askgps"){
            theOne.askgps = Number(item.value > 0);
        }
        if(transmitter_update_type==="askversion"){
            theOne.askversion = Number(item.value > 0);
        }
        if(transmitter_update_type==="askuuid"){
            theOne.askuuid = Number(item.value > 0);
        }
        if(transmitter_update_type==="askvariables"){
            theOne.askvariables = Number(item.value > 0);
        }
        if(transmitter_update_type==="askpoweroff"){
            theOne.askpoweroff = Number(item.value > 0);
        }
        if(transmitter_update_type==="askforce_attach"){
            theOne.askforce_attach = Number(item.value > 0);
        }
        if(transmitter_update_type==="askupgrade_capteurs"){
            theOne.askupgrade_capteurs = Number(item.value > 0);
        }
        if(transmitter_update_type==="asksleep_n_min"){
            theOne.asksleep_n_min = item.value;
        }
        if(transmitter_update_type==="askutc_wake_up_hour"){
            theOne.askutc_wake_up_hour = item.value;
        }
        if(transmitter_update_type==="askutc_wake_up_minute"){
            theOne.askutc_wake_up_minute = item.value;
        }
        if(transmitter_update_type==="resendparameters"){
            theOne.resend_parameters = Number(item.value > 0);
        }
        if(transmitter_update_type==="askota"){
          let askota_has_been_set = false;
          if(item.value!==""){
            const the_selected_ota_values = otaFirmwares.find(ota=>ota.path===item.value);
            if(the_selected_ota_values){
              askota_has_been_set = true;
              theOne.firmware_ota_uc_path = the_selected_ota_values.path;
              theOne.firmware_ota_uc_host = the_selected_ota_values.host;
              theOne.firmware_ota_uc_port = the_selected_ota_values.port;
              theOne.firmware_ota_uc_length = the_selected_ota_values.length;
            }
          }
          if(!askota_has_been_set){
            (enableDebug)&&console.log(displayName,"Delete any previous value for askota parameters");
            theOne.firmware_ota_uc_path = "";
            theOne.firmware_ota_uc_host = "";
            theOne.firmware_ota_uc_port = 0;
            theOne.firmware_ota_uc_length = 0;
          }
        }
        if(transmitter_update_type==="modemtype"){
          theOne.new_modem_type = Number(item.value);
        }
        if(transmitter_update_type==="always_attach"){
          theOne.new_always_attach = Number(item.value > 0);
        }
        if(transmitter_update_type==="vin_mv"){
          theOne.new_vin_mv = Number(item.value > 0);
        }
        if(transmitter_update_type==="gprs_big_data"){
          theOne.new_gprs_big_data = Number(item.value > 0);
        }
        if(transmitter_update_type==="force_gsm"){
          theOne.new_force_gsm = Number(item.value > 0);
        }
        if(transmitter_update_type==="allow_nbiot"){
          theOne.new_allow_nbiot = Number(item.value > 0);
        }
        if(transmitter_update_type==="keep_sample"){
          theOne.new_keep_sample = Number(item.value > 0);
        }
        if(transmitter_update_type==="my_operator"){
          theOne.new_my_operator = item.value;
        }
        if(transmitter_update_type==="wifi_ssid_1"){
          theOne.new_wifi_ssid_1 = item.value;
        }
        if(transmitter_update_type==="wifi_pass_1"){
          theOne.new_wifi_pass_1 = item.value;
        }
        if(transmitter_update_type==="wifi_ssid_2"){
          theOne.new_wifi_ssid_2 = item.value;
        }
        if(transmitter_update_type==="wifi_pass_2"){
          theOne.new_wifi_pass_2 = item.value;
        }
        if(transmitter_update_type==="connector"){
          theOne.connector = item.value;
        }
        if(transmitter_update_type==="serial"){
          theOne.serial = item.value;
        }
        if(transmitter_update_type==="rs_squama_on"){
          theOne.new_rs_squama_on = Number(item.value > 0);
        }
        if(transmitter_update_type==="rs_modbus_on"){
          theOne.new_rs_modbus_on = Number(item.value > 0);
        }
        if(transmitter_update_type==="installation_squama"){
          theOne.installation_squama = Number(item.value > 0);
        }
      }
    }
  }

  const validateForm = () => {
    /*
    let newForm: FormInputs = inputFields;
    console.log(displayName,newForm.station_name.value)
    */
    items.map(one => {
      if (one.value !== one.initial_value){
        (enableDebug)&&console.log(displayName,"Item:"+one.name+" has changed :"+one.initial_value+"=>"+one.value)
        if(one.name.includes("transmitter")){
          update_transmitters(one)
        }
      }
      return null;
    })
    return true;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isFormValid = validateForm();
    if(isFormValid){
      (enableDebug)&&console.log(displayName,"Setup form is valid")
      setUpdating(true);
      updateStation();
    }
  }

  function installer_send(station:Station) {

    function installation_send_for_transmitter(transmitter:Transmitter) {
      let current_user_can_modify_send_frequency = current_user_can_modify;
      let current_user_can_ask_gps = current_user_is_god;
      let current_user_can_ask_uuid = current_user_is_god;
      let current_user_can_ask_resendparameters = current_user_is_god;
      let current_user_can_ask_temperature_mask = current_user_is_god;
      let current_user_can_ask_freestyle = current_user_is_god;
      let current_transmitter_is_at_least_esp32 = current_user_is_god && transmitter_hw_is_at_least_esp32(transmitter);
      let transmitter_frequency_sample_in_pending = false;
      let transmitter_last_value_send_each_n_quarter : number = 0;
      let transmitter_last_value_sample_each_n_quarter : number = 0;
      let transmitter_temp_mask_in_pending = false;
      let transmitter_last_value_temp_mask : number = 0;

      if(transmitter.new_send_each_n_quarter){
        transmitter_last_value_send_each_n_quarter = transmitter.new_send_each_n_quarter;
        transmitter_frequency_sample_in_pending = true;
      }else{
        transmitter_last_value_send_each_n_quarter = transmitter.current_send_each_n_quarter;
      }
      if(transmitter.new_temp_mask !== null){
        transmitter_last_value_temp_mask = transmitter.new_temp_mask;
        transmitter_temp_mask_in_pending = true;
      }else{
        transmitter_last_value_temp_mask = transmitter.current_temp_mask;
      }

      if(is_transmitter_module_gprs_or_wifi(transmitter)){
        if(transmitter.new_sample_each_n_quarter){
          transmitter_last_value_sample_each_n_quarter = transmitter.new_sample_each_n_quarter;
          transmitter_frequency_sample_in_pending = true;
        }else{
          transmitter_last_value_sample_each_n_quarter = transmitter.current_sample_each_n_quarter;
        }
      }else{
        transmitter_last_value_sample_each_n_quarter = transmitter_last_value_send_each_n_quarter;
        current_user_can_modify_send_frequency = false;
        current_user_can_ask_gps = false;
        current_user_can_ask_uuid = false;
        current_user_can_ask_resendparameters = false;
        current_transmitter_is_at_least_esp32 = false;
      }

      const theItems = [...items]
      const theOne = theItems.find(one => one.name === "transmitter-"+transmitter.id+"-samples");
      let transmitter_samples_selected = (theOne)?(theOne.value):(transmitter_last_value_sample_each_n_quarter);
      const theSecond = theItems.find(one => one.name === "transmitter-"+transmitter.id+"-frequency");
      let transmitter_frequency_selected = (theSecond)?(theSecond.value):(transmitter_last_value_send_each_n_quarter);

      function installation_ota_option(transmitter:Transmitter, value:OtaFirmware){
        return(
          <option key={"transmitter-"+transmitter.id+"-askota-"+value.path} value={value.path} label={value.value_txt}>{value.value_txt}</option>
        )
      }

      function installation_frequency_send_for_transmitter_handle_frequency_option(transmitter:Transmitter, value:number, value_name:string){
        const theItems = [...items]
        const theOne = theItems.find(one => one.name === "transmitter-"+transmitter.id+"-samples");
        let transmitter_samples_selected = (theOne)?(theOne.value):(transmitter_last_value_sample_each_n_quarter);
        if(transmitter_samples_selected>value){
          if(enableDebug){
            console.log(displayName,"installation_frequency_send_for_transmitter_handle_frequency_option")
            console.log(displayName,"transmitter_samples_selected:"+transmitter_samples_selected);
            console.log(displayName,"value:"+value);
            console.log(displayName,"---")
          }
          return;
        }
        return(
          <option key={"transmitter-"+transmitter.id+"-frequency-"+value} value={value} label={value_name}>{value_name}</option>
        )
      }

      function installation_frequency_send_for_transmitter_handle_samples_option(transmitter:Transmitter, value:number, samples_name:string){
        return(
          <option key={"transmitter-"+transmitter.id+"-sample-"+value} value={value} label={samples_name}>{samples_name}</option>
        )
      }

      function installation_modem_type_for_transmitter(transmitter:Transmitter, value:number, value_name:string){
        return(
          <option key={"transmitter-"+transmitter.id+"-modemtype-"+value} value={value} label={value_name}>{value_name}</option>
        )
      }

      const handleSamplesSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        (enableDebug)&&console.log(displayName,"e.target.id:"+e.target.id);
        (enableDebug)&&console.log(displayName,"e.target.value:"+e.target.value);
        const theItems = [...items]
        const theSampleFrequencyItem = theItems.find(one => one.name === e.target.id);
        if(theSampleFrequencyItem){theSampleFrequencyItem.value = e.target.value}
        const theSendFrequencyItem = theItems.find(one => one.name === e.target.id.replace("-samples","-frequency"));
        const thetransmitterType = theItems.find(one => one.name === e.target.id.replace("-samples","-type"));
        if(enableDebug){
          theSendFrequencyItem && console.log(displayName,theSendFrequencyItem);
          theSampleFrequencyItem && theSendFrequencyItem && console.log(displayName,theSendFrequencyItem.value+">"+theSampleFrequencyItem.value);
          thetransmitterType && console.log(displayName,"thetransmitterType:",thetransmitterType);
        }
        if(theSampleFrequencyItem && theSendFrequencyItem && thetransmitterType){
          if((theSendFrequencyItem.value===255)||(theSendFrequencyItem.value===0)||(theSendFrequencyItem.value<theSampleFrequencyItem.value)||!is_transmitter_type_module_gprs_or_wifi(thetransmitterType.value)){
            //be sure to update the frequency if frequency(number of quarters) is higher than samples(number of quarters)
            (enableDebug)&&console.log(displayName,"Update frequency to:"+theSampleFrequencyItem.value);
            theSendFrequencyItem.value=theSampleFrequencyItem.value;
          }
        }
        setItems(theItems);
      }
      return(
        <div key={transmitter.id+"-samples-frequency"} className="panel-station-installer-column">
          <div className="panel-station-installer-line-height panel-station-installer-value">{limit_char(19,transmitter.mac)}</div>
          <input id={"transmitter-"+transmitter.id+"-connector"} type="text" className="panel-station-installer-value panel-station-installer-line-height" disabled={!current_user_can_ask_freestyle} value={getItemFromName(items, "transmitter-"+transmitter.id+"-connector").value} onChange={e => handleInputChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-serial"} type="text" className="panel-station-installer-value panel-station-installer-line-height" disabled={!current_user_can_ask_freestyle} value={getItemFromName(items, "transmitter-"+transmitter.id+"-serial").value} onChange={e => handleInputChange(e)}/>
          <div className="panel-station-installer-line-height panel-station-installer-value">{format_date(transmitter.last_trame_received)}</div>
          <div className="panel-station-installer-line-height panel-station-installer-value">{format_date(add_quarter_to_date(transmitter.last_trame_received,transmitter_get_number_of_quarter_before_next_trame(transmitter)))+
            transmitter_get_battery_strategy_explanation(transmitter)}</div>
          <div className="panel-station-installer-line-height panel-station-installer-value">{transmitter.last_magnet&&format_date(transmitter.last_magnet)}</div>
          <div className="panel-station-installer-line-height panel-station-installer-value">{transmitter.last_operator}</div>
          <div className="panel-station-installer-line-height panel-station-installer-value">{transmitter.type}</div>
          <select key={transmitter.id+"-samples"} id={"transmitter-"+transmitter.id+"-samples"} disabled={!current_user_can_modify} value={transmitter_samples_selected} onChange={e => handleSamplesSelectChange(e)}  data-tip={((transmitter_frequency_sample_in_pending)?("Bientôt:"):("Actuel:"))+samples_const.find(one => {return one.value===transmitter_last_value_sample_each_n_quarter})?.value_txt} className="panel-station-installer-line-height panel-station-installer-value">
            {samples_const.map(one=>installation_frequency_send_for_transmitter_handle_samples_option(transmitter,one.value,one.value_txt))}
          </select>
          <select data-tip={((transmitter_frequency_sample_in_pending)?("Bientôt:"):("Actuel:"))+frequencies_const.find(one => {return one.value===transmitter_last_value_send_each_n_quarter})?.value_txt} className="panel-station-installer-line-height panel-station-installer-value" key={transmitter.id+"-frequency"} id={"transmitter-"+transmitter.id+"-frequency"} disabled={!current_user_can_modify_send_frequency} value={transmitter_frequency_selected} onChange={e => handleGenericSelectChange(e)}>
            {frequencies_const.map(one=>installation_frequency_send_for_transmitter_handle_frequency_option(transmitter,one.value,one.value_txt))}
          </select>
          <input id={"transmitter-"+transmitter.id+"-askgps"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-askgps").value > 0)} disabled={!current_user_can_ask_gps} onChange={e => handleCheckboxChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-askversion"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-askversion").value > 0)} onChange={e => handleCheckboxChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-askuuid"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-askuuid").value > 0)} disabled={!current_user_can_ask_uuid} onChange={e => handleCheckboxChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-askvariables"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-askvariables").value > 0)} disabled={transmitter.fw_version<40023} onChange={e => handleCheckboxChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-askpoweroff"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-askpoweroff").value > 0)} disabled={!current_user_can_ask_uuid} onChange={e => handleCheckboxChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-askforce_attach"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-askforce_attach").value > 0)} disabled={transmitter.fw_version<40020} onChange={e => handleCheckboxChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-askupgrade_capteurs"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-askupgrade_capteurs").value > 0)} disabled={transmitter.fw_version<40018} onChange={e => handleCheckboxChange(e)}/>
          <div className="panel-station-installer-line-height panel-station-installer-value">
            <input id={"transmitter-"+transmitter.id+"-asksleep_n_min"} type="number" value={getItemFromName(items, "transmitter-"+transmitter.id+"-asksleep_n_min").value} disabled={transmitter.fw_version<40022} onChange={e => handleInputChange(e)}/>
            <div data-tip="reset" onClick={()=>handleResetValue("transmitter-"+transmitter.id+"-asksleep_n_min")}>{return_logo_from_type("round_ko", "logo-sensor-half-size-png")}</div>
          </div>
          <div className="panel-station-installer-line-height panel-station-installer-value">
            <input id={"transmitter-"+transmitter.id+"-askutc_wake_up_hour"} type="number" value={getItemFromName(items, "transmitter-"+transmitter.id+"-askutc_wake_up_hour").value} disabled={transmitter.fw_version<40026} onChange={e => handleInputChange(e)}/>
            <div data-tip="reset" onClick={()=>handleResetValue("transmitter-"+transmitter.id+"-askutc_wake_up_hour")}>{return_logo_from_type("round_ko", "logo-sensor-half-size-png")}</div>
          </div>
          <div className="panel-station-installer-line-height panel-station-installer-value">
            <input id={"transmitter-"+transmitter.id+"-askutc_wake_up_minute"} type="number" value={getItemFromName(items, "transmitter-"+transmitter.id+"-askutc_wake_up_minute").value} disabled={transmitter.fw_version<40026} onChange={e => handleInputChange(e)}/>
            <div data-tip="reset" onClick={()=>handleResetValue("transmitter-"+transmitter.id+"-askutc_wake_up_minute")}>{return_logo_from_type("round_ko", "logo-sensor-half-size-png")}</div>
          </div>
          <input id={"transmitter-"+transmitter.id+"-resendparameters"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-resendparameters").value > 0)} disabled={!current_user_can_ask_resendparameters} onChange={e => handleCheckboxChange(e)}/>
          <div className="panel-station-installer-line-height panel-station-installer-value">{fw_nicely_displayed(transmitter.fw_version)}</div>
          <select key={transmitter.id+"-askota"} id={"transmitter-"+transmitter.id+"-askota"} className="panel-station-installer-line-height panel-station-installer-value" disabled={!current_transmitter_is_at_least_esp32} value={getItemFromName(items, "transmitter-"+transmitter.id+"-askota").value} onChange={e => handleGenericSelectChange(e)}>
            {otaFirmwares.map(one=>installation_ota_option(transmitter,one))}
          </select>
          <select key={transmitter.id+"-modemtype"} id={"transmitter-"+transmitter.id+"-modemtype"} disabled={!current_transmitter_is_at_least_esp32} value={getItemFromName(items, "transmitter-"+transmitter.id+"-modemtype").value} onChange={e => handleGenericSelectChange(e)} className="panel-station-installer-line-height panel-station-installer-value">
            {modem_type_const.map(one=>installation_modem_type_for_transmitter(transmitter,one.value,one.value_txt))}
          </select>
          <input data-tip={((transmitter_temp_mask_in_pending)?("Bientôt:"):("Actuel:"))+transmitter_last_value_temp_mask} id={"transmitter-"+transmitter.id+"-temperaturemask"} type="text" className="panel-station-installer-value panel-station-installer-line-height" disabled={!current_user_can_ask_temperature_mask} value={getItemFromName(items, "transmitter-"+transmitter.id+"-temperaturemask").value} onChange={e => handleInputChange(e)}/>
          <div className="panel-station-installer-value panel-station-installer-line-height">
            <input id={"transmitter-"+transmitter.id+"-gps_lat"} type="text" className="" disabled={!current_user_can_ask_freestyle} value={getItemFromName(items, "transmitter-"+transmitter.id+"-gps_lat").value} onChange={e => handleInputChange(e)}/>
            <div onClick={()=>getGPSPosition()}>{return_logo_from_type("target", "logo-sensor-half-size-png")}</div>
          </div>
          <div className="panel-station-installer-value panel-station-installer-line-height">
            <input id={"transmitter-"+transmitter.id+"-gps_lon"} type="text" className="" disabled={!current_user_can_ask_freestyle} value={getItemFromName(items, "transmitter-"+transmitter.id+"-gps_lon").value} onChange={e => handleInputChange(e)}/>
            <div onClick={()=>getGPSPosition()}>{return_logo_from_type("target", "logo-sensor-half-size-png")}</div>
          </div>
          <input id={"transmitter-"+transmitter.id+"-always_attach"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-always_attach").value > 0)} disabled={transmitter.fw_version<40020} onChange={e => handleCheckboxChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-vin_mv"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-vin_mv").value > 0)} disabled={!current_user_can_ask_uuid} onChange={e => handleCheckboxChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-gprs_big_data"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-gprs_big_data").value > 0)} disabled={!current_user_can_ask_uuid} onChange={e => handleCheckboxChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-force_gsm"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-force_gsm").value > 0)} disabled={!current_user_can_ask_uuid} onChange={e => handleCheckboxChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-allow_nbiot"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-allow_nbiot").value > 0)} disabled={transmitter.fw_version<40022} onChange={e => handleCheckboxChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-keep_sample"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-keep_sample").value > 0)} disabled={transmitter.fw_version<40023} onChange={e => handleCheckboxChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-my_operator"} type="text" className="panel-station-installer-line-height panel-station-installer-value" value={getItemFromName(items, "transmitter-"+transmitter.id+"-my_operator").value} disabled={transmitter.fw_version<40017} onChange={e => handleInputChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-wifi_ssid_1"} type="text" className="panel-station-installer-line-height panel-station-installer-value" value={getItemFromName(items, "transmitter-"+transmitter.id+"-wifi_ssid_1").value} disabled={!current_user_can_ask_uuid} onChange={e => handleInputChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-wifi_pass_1"} type="text" className="panel-station-installer-line-height panel-station-installer-value" value={getItemFromName(items, "transmitter-"+transmitter.id+"-wifi_pass_1").value} disabled={!current_user_can_ask_uuid} onChange={e => handleInputChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-wifi_ssid_2"} type="text" className="panel-station-installer-line-height panel-station-installer-value" value={getItemFromName(items, "transmitter-"+transmitter.id+"-wifi_ssid_2").value} disabled={!current_user_can_ask_uuid} onChange={e => handleInputChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-wifi_pass_2"} type="text" className="panel-station-installer-line-height panel-station-installer-value" value={getItemFromName(items, "transmitter-"+transmitter.id+"-wifi_pass_2").value} disabled={!current_user_can_ask_uuid} onChange={e => handleInputChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-rs_squama_on"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-rs_squama_on").value > 0)} disabled={transmitter.fw_version<40023} onChange={e => handleCheckboxChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-rs_modbus_on"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-rs_modbus_on").value > 0)} disabled={transmitter.fw_version<40023} onChange={e => handleCheckboxChange(e)}/>
          <textarea id={"transmitter-"+transmitter.id+"-comments"} cols={5} className="panel-station-installer-value panel-station-installer-line-height" disabled={!current_user_can_ask_freestyle} value={getItemFromName(items, "transmitter-"+transmitter.id+"-comments").value} onChange={e => handleTextAreaChange(e)}/>
          <input id={"transmitter-"+transmitter.id+"-freestyle"} type="text" className="panel-station-installer-value panel-station-installer-line-height" disabled={!current_user_can_ask_freestyle} value={getItemFromName(items, "transmitter-"+transmitter.id+"-freestyle").value} onChange={e => handleInputChange(e)}/>
          <div className="panel-station-installer-value panel-station-installer-line-height">
            <input id={"transmitter-"+transmitter.id+"-installation_date"} type="datetime-local" format-value="yyyy-MM-dd HH:mm" className="" disabled={!current_user_can_ask_freestyle} value={getItemFromName(items, "transmitter-"+transmitter.id+"-installation_date").value} onChange={(e) => handleInstallationDateOnChange(e)}/>
            <div data-tip="reset" onClick={()=>handleResetValue("transmitter-"+transmitter.id+"-installation_date")}>{return_logo_from_type("round_ko", "logo-sensor-half-size-png")}</div>
          </div>
          <input id={"transmitter-"+transmitter.id+"-installation_squama"} type="checkbox" className="panel-station-setup-checkbox filled-in panel-station-installer-line-height panel-station-installer-value" checked={(getItemFromName(items, "transmitter-"+transmitter.id+"-installation_squama").value > 0)} disabled={!current_user_can_ask_uuid} onChange={e => handleCheckboxChange(e)}/>
      </div>
      )
    }
    return(
      <div className="panel-setup-station-line">
        <div key={"sensor-station-god-"+station.id} className="panel-station-setup-sensor-content-part-line">
          <div className="panel-station-installer-column">
            <div className="panel-station-installer-title panel-station-installer-line-height">Transmetteur</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Connecteur</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Numéro série</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Dernière trame</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Prochaine trame</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Passage d'aimant</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Opérateur</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Type connection</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Mesure</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Emission</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">GPS</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Version</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">UUID</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Get variables</div>
            <div className="panel-station-installer-title panel-station-installer-line-height status-ko">Power off</div>
            <div data-tip="Force attach GPRS (1 fois)" className="panel-station-installer-title panel-station-installer-line-height">Force GPRS attach</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Upgrade capteurs</div>
            <div data-tip="Faire dormir transmetteur N min" className="panel-station-installer-title panel-station-installer-line-height">Force sleep</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Prochain réveil</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Prochain réveil</div>
            <div data-tip="Resend frequencies+temp_mask+modem+vin+big_data+force_gsm+nbiot+keep_sample+operator+wifi" className="panel-station-installer-title panel-station-installer-line-height">Renvoi params</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">FW actuel</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">µc OTA</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Modem</div>
            <div data-tip="0:Read last temperature sensor" className="panel-station-installer-title panel-station-installer-line-height">Mask T°C</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Latitude</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Longitude</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Toujours attach</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Get capteurs Vin</div>
            <div data-tip="big data" className="panel-station-installer-title panel-station-installer-line-height">GPRS upgrades</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Force GSM(2G)</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Autorise NB-IOT</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Keep sample</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Operateur Préf.</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">WiFi1 SSID</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">WiFi1 Pass</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">WiFi2 SSID</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">WiFi2 Pass</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">RS485 SQUAMA</div>
            <div data-tipe="microsensor pressure" className="panel-station-installer-title panel-station-installer-line-height">RS485 Modbus</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Comments</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Freestyle</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Date Installation</div>
            <div className="panel-station-installer-title panel-station-installer-line-height">Install. SQUAMA</div>
          </div>
          <div className="panel-station-installer-column">
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(MAC)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Type)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Serial)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Date)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Date)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Date)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">()</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">()</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Fréquence)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Envois par jour)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Demande)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Demande)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Demande)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Demande)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Demande)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Demande)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Demande)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(minutes)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Heure:0-24)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Minutes:0-59)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Demande)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(version)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Éxecuter)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Type)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Mask decimal)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Float)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Float)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(On/Off)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(On/Off)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(On/Off)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(On/Off)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(On/Off)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(On/Off)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Text)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Text)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Text)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Text)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Text)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(On/Off)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(On/Off)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Text)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">{"(Dict {\"one\":\"1\"})"}</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(Date)</div>
            <div className="panel-station-installer-title-explanation panel-station-installer-line-height">(On/Off)</div>
          </div>
          {station.transmitters.map(one=>installation_send_for_transmitter(one))}
        </div>
      </div>
    )
  }

  //displayItems(displayName, items);
  //console.log(displayName, station.contacts);

  return (
    <form onSubmit={e => handleSubmit(e)}>
      <div className="panel-setup-station-main">
        <div className="panel-installer-station-main-bottom">
          {(current_user_is_god)&&installer_send(station)}
        </div>
        {/* Do not disable following else even the admin can't validate when previously disabled */}
        <button type="submit" className="btn-station-card align-self-center" disabled={updating||!stationFullyLoaded}>
          {(updating||!stationFullyLoaded)?return_logo_from_type("loader","icon-sensor-30"):"Valider"}
        </button>
      </div>
      <ReactTooltip type="light" />
    </form>
  );
}
export default StationCardInstaller;
