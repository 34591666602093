import Station from "../models/station";
import {authHeader} from '../helpers/auth-headers';
import Transmitter from "../models/transmitter";
import SiteService from "./site-service";

export default class StationService {
  
  static stations:Station[];

  static deassociateTransmitterToStation(transmitterId: number, stationId: number): Promise<{}> {
    return fetch(SiteService.getApiUrl()+`station_associated_transmitters/0`, {
      method: 'DELETE',
      body: JSON.stringify({
        "transmitter_id":transmitterId,
        "station_id":stationId
      }),
      headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static associateTransmitterToStation(transmitterId: number, stationId: number): Promise<{}> {
    return fetch(SiteService.getApiUrl()+`station_associated_transmitters/0`, {
      method: 'POST',
      body: JSON.stringify({
        "transmitter_id":transmitterId,
        "station_id":stationId
      }),
      headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getAssociatedTransmittersForAStation(stationId: number): Promise<Transmitter[]> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader()
    }
    return fetch(SiteService.getApiUrl()+`station_associated_transmitters/${stationId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getNotAssociatedTransmittersForAStation(stationId: number): Promise<Transmitter[]> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader()
    }
    return fetch(SiteService.getApiUrl()+`station_not_associated_transmitters/${stationId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getStationsForACompany(id: number, with_filter: string = '[]'): Promise<Station[]> {
    return fetch(
      SiteService.getApiUrl()+`company_stations/${id}`,
      {
        headers : {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'with_filter': with_filter,
          'token': authHeader()
        },
        mode:'cors'
      })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getStation(id: number): Promise<Station|null> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader()
    }
    //console.log("getStation:"+id)
    return fetch(SiteService.getApiUrl()+`stations/${id}`,{headers})
    .then(response => response.json())
    .then(data => this.isEmpty(data) ? null : data)
    .catch(error => this.handleError(error));
  }

  static updateStation(station: Station): Promise<Station> {
    //console.log("will send JSON station:"+JSON.stringify(station));
      return fetch(SiteService.getApiUrl()+`stations/${station.id}`, {
        method: 'PUT',
        body: JSON.stringify(station),
        headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
      })
      .then(response => response.json())
      .catch(error => this.handleError(error));
  }

  static deleteStation(stationId: number): Promise<{}> {
    //console.log("Delete station id:"+stationId);
    return fetch(SiteService.getApiUrl()+`stations/${stationId}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json',
                'token': authHeader()}
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static addStation(station: Station): Promise<Station> {
    //the station.id is the company_id value to which the station have to be linked with
    return fetch(SiteService.getApiUrl()+`stations/0`, {
      method: 'POST',
      body: JSON.stringify(station),
      headers: { 'Content-Type': 'application/json',
      'token': authHeader()}
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static addUserToStation(userId: number, stationId: number, companyId: number, userEmail: string): Promise<{}> {
    return fetch(SiteService.getApiUrl()+`station_user/${stationId}`, {
      method: 'POST',
      body: JSON.stringify({
        "user_id":userId,
        "station_id":stationId,
        "company_id":companyId,
        "user_email":userEmail
      }),
      headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static deleteUserToStation(userId: number, stationId: number): Promise<{}> {
    return fetch(SiteService.getApiUrl()+`station_user/${stationId}`, {
      method: 'DELETE',
      body: JSON.stringify({
        "user_id":userId,
        "station_id":stationId
      }),
      headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static searchStation(term: string): Promise<Station[]> {
    return fetch(SiteService.getApiUrl()+`stations?q=${term}`)
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static isEmpty(data: Object): boolean {
    return Object.keys(data).length === 0;
  }

  static handleError(error: Error): void {
    console.error(error);
  }

  static getDownloadableForStation(station_id: number): Promise<string[]> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader()
    }
    return fetch(SiteService.getApiUrl()+`getdownloadfile/${station_id}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static downloadForStation(station_id: number, filename: string): Promise<void | Blob> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
    }
    return fetch(SiteService.getApiUrl()+`downloadfile/${station_id}/${filename}`,{headers})
    .then(response => response.blob())
    .catch(error => this.handleError(error));
  }

  static getTransmitter(id: number): Promise<Transmitter[]|null> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader()
    }
    //console.log("getTransmitter:"+id)
    return fetch(SiteService.getApiUrl()+`transmitters/${id}`,{headers})
    .then(response => response.json())
    .then(data => this.isEmpty(data) ? null : data)
    .catch(error => this.handleError(error));
  }

}
