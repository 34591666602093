import { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { io } from "socket.io-client";

import StationCard from '../components/station-card';
import SquamaNavbar from '../components/squama-navbar';
import LoaderLogo from "../img/loader-661x661.gif";

import Station from '../models/station';
import Company from '../models/company';
import {authHeader} from '../helpers/auth-headers';
import SiteService from '../services/site-service';
import {
  get_array_of_stations_id,
  is_stations_number_correct_and_filter_all_the_same_and_which_one,
  replace_light_stations_array_by_full_stations_array,
  return_logo_from_type } from '../helpers/station-helper';
import { isCurrentUserAdminNanogis, isCurrentUserGod } from '../helpers/auth-helper';
import { WebsocketEmitRequest, WebsocketReceiveAnswerRequest, WebsocketReceiveRequest } from '../models/websocket';
import { are_those_tables_equals } from '../helpers/tools';

const SquamaCompanyDashBoard: FunctionComponent = () => {
  //TODO : handle god / not god user
  const ALL_FILTERS = [
    //["name"],
    ["name", "analog_sensors", "water_level_sensors", "type_temperature", "type_liquid_level", "last_update"],
    //["name", "general_status", "analog_sensors", "water_level_sensors", "type_temperature"],
    []
  ]
  const displayName = "SquamaCompanyDashBoard:";
  const enableDebug = false;
  const enableMoreDebug = false;
  const enableDebugWebsocket = false;
  const enableMoreDebugWebsocket = false;
  const enableDebugStationsReceived = false;
  const enableMoreDebugStationsReceived = false;
  const current_user_is_god = isCurrentUserGod();
  const current_user_is_nanogis = isCurrentUserAdminNanogis();
  const params = useParams<{ id: string ,finalpath: string}>();
  const [updating, setUpdating] = useState<number>(0);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [stationsReceived, setStationsReceived] = useState<Station[]>([]);
  const [stationsDisplayed, setStationsDisplayed] = useState<Station[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number>(0);
  const [canShowLogout , setCanShowLogout] = useState(false);
  const [finalPath, setFinalPath] = useState<string>('');

  const [websocketEmit , setWebsocketEmit] = useState<WebsocketEmitRequest|undefined>(undefined);
  const [websocketReceiveAnswerRequest , setWebsocketReceiveAnswerRequest] = useState<WebsocketReceiveAnswerRequest|undefined>(undefined);
  const [websocketWebsocketReceiveRequest , setWebsocketReceiveRequest] = useState<WebsocketReceiveRequest|undefined>(undefined);

  const history = useNavigate();
  useEffect(() => {
    if(current_user_is_nanogis){
      history("/map");
    }
  }, [current_user_is_nanogis])
  useEffect(() => {
    if(params.finalpath){
      enableDebug && console.log(displayName, "finalpath:", params.finalpath);
      setFinalPath(params.finalpath);
    }
  }, [params.finalpath])
  useEffect(() => {
    if(enableDebugWebsocket) console.log(displayName, "useEffect with websocket");
    const socket = io(SiteService.getWebsocketUrl(), {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
      extraHeaders: {
        "my-custom-header": "1234" // WARN: this will be ignored in a browser
      }
    });
    socket.on("answer", (answer: any) => {
      if(enableDebugWebsocket) console.log(displayName, "Answer:", answer);
      setWebsocketReceiveAnswerRequest(answer);
    });
    const websocketChannelsListened = ["companies_desc", "stations_desc"];
    websocketChannelsListened.forEach(the_channel => {
      socket.on(the_channel, (the_str_received: string) => {
        if(enableMoreDebugWebsocket) console.log(displayName,the_channel, ":", the_str_received);
        setWebsocketReceiveRequest({channel:the_channel,answer:the_str_received});
      });
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      console.log(displayName, "logged_out:", logged_out_desc_str);
      setCanShowLogout(true);
      setStationsReceived([]);
      setStationsDisplayed([]);
    });
    //TODO
    //socket.on("disconnect", () => {
    //  if(enableDebugWebsocket) console.log(displayName, "WS disconnected");
    //  //https://socket.io/how-to/use-with-react
    //});
    if(websocketEmit){
      if(enableDebugWebsocket) console.log(displayName, "----------> websocket emit :",websocketEmit);
      socket.emit(websocketEmit.channel, websocketEmit.request);
    }
    return function cleanup() {
      if(enableDebugWebsocket){
        console.log(displayName, "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
        console.log(displayName, "! useEffect websocket.cleanup !");
        console.log(displayName, "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      }
      socket.disconnect();
    };
  }, [params.id, websocketEmit]);

  useEffect(() => {
    if(enableDebug) console.log(displayName, "useEffect main");
    const timer = setTimeout( () => setCanShowLogout(true) , 5000);
    if(websocketReceiveAnswerRequest){
      if(enableMoreDebug) console.log(displayName, "%%%%%%%%%%%%%%% Receive AnswerRequest:", websocketReceiveAnswerRequest);
      if(websocketReceiveAnswerRequest.type === "station_id") {
        //station_id answer list the station_id that the user is allowed to access for the company previously requested
        //we will ask stations_desc by station_id
        (current_user_is_god) && setUpdating(websocketReceiveAnswerRequest.liste.length);
        const the_request: any = {
          type: "stations_desc",
          number: websocketReceiveAnswerRequest.listeNumber,
          liste: websocketReceiveAnswerRequest.liste,
          from: "station_id",
          filter: ALL_FILTERS[0],
          answer_method: ["one_time"],
          token: authHeader()
        }
        setWebsocketEmit({channel:"request", request:the_request});
      }
      setWebsocketReceiveAnswerRequest(undefined);
    }
    if(websocketWebsocketReceiveRequest){
      if(enableMoreDebug) console.log(displayName, "#### Receive Request:", websocketWebsocketReceiveRequest);
      if(websocketWebsocketReceiveRequest.channel === "companies_desc"){
        setSelectedCompany(0);
        const the_companies = JSON.parse(websocketWebsocketReceiveRequest.answer);
        if(enableDebug) console.log(displayName, "Companies:", the_companies);
        setCompanies(the_companies);
      }
      else if(websocketWebsocketReceiveRequest.channel === "stations_desc"){
        if(enableMoreDebug) console.log(displayName, "Just received answer Json.length:", JSON.parse(websocketWebsocketReceiveRequest.answer).length);
        setStationsReceived(JSON.parse(websocketWebsocketReceiveRequest.answer));
      }
      setWebsocketReceiveRequest(undefined);
    }
    if(!companies.length){
      if(enableDebug) console.log(displayName, "Request all companies_desc");
      const the_request: any = {
        type: "companies_desc",
        number: 0,
        liste: [],
        from: "token",
        token: authHeader()
      }
      setWebsocketEmit({channel:"request", request:the_request});
    }
    if(!params.id && companies.length){
      if(enableDebug) console.log(displayName, "No params, probably in dashboard");
      history("/companies/" + companies[0].id.toString());
    } else if(params.id && selectedCompany !== Number(params.id)) {
      if(enableDebug) console.log(displayName, "Update company id to:",params.id);
      setSelectedCompany(Number(+params.id));
      setCanShowLogout(false);
    }
    return function cleanup() {
      if(enableDebug) console.log(displayName, "useEffect main.cleanup")
      clearTimeout(timer);
    };
  }, [params.id, companies.length, websocketReceiveAnswerRequest, websocketWebsocketReceiveRequest]);
  useEffect(() => {
    if(selectedCompany){
      const the_request: any = {
        type: "station_id",
        number: 0,
        liste: [selectedCompany],
        from: "company_id",
        token: authHeader()
      }
      setStationsReceived([]);
      setStationsDisplayed([]);
      setWebsocketEmit({channel:"request", request:the_request});
    }
  }, [selectedCompany]);
  useEffect(() => {
    if(enableDebugStationsReceived) console.log(displayName, "useEffect triggered by stationsReceived");
    if(enableMoreDebugStationsReceived){
      console.log(displayName, "begin stationsReceived:",stationsReceived);
      console.log(displayName, "begin stationsReceived.length:",stationsReceived.length);
      console.log(displayName, "begin stationsDisplayed:",stationsDisplayed);
      console.log(displayName, "begin stationsDisplayed.length:",stationsDisplayed.length);
    }
    if(stationsReceived.length){
      const newStationsDisplayed = replace_light_stations_array_by_full_stations_array(stationsDisplayed, stationsReceived)
      setStationsDisplayed(newStationsDisplayed);
      const same_filter = is_stations_number_correct_and_filter_all_the_same_and_which_one(newStationsDisplayed)
      if(same_filter.length){
        if(enableDebug) console.log(displayName, "*******************************************All same filter:", same_filter);
        //We can ask next filter steps
        const next_filter = get_next_filter(same_filter);
        if(!are_those_tables_equals(next_filter,same_filter)){
          if(enableDebug) console.log(displayName, "new request with new filter:", next_filter);
          const the_request: any = {
            type: "stations_desc",
            number: newStationsDisplayed.length,
            liste: get_array_of_stations_id(newStationsDisplayed),
            from: "station_id",
            filter: next_filter,
            token: authHeader()
          }
          setWebsocketEmit({channel:"request", request:the_request});
        }
    }

    }
    if(enableDebugStationsReceived){
      console.log(displayName, "end stationsReceived:",stationsReceived);
      console.log(displayName, "end stationsDisplayed:",stationsDisplayed);
    }
  }, [stationsReceived]);

  function get_next_filter(the_step:string[]):string[]{
    for (let index = 0; index < (ALL_FILTERS.length-1);index++){
      if(are_those_tables_equals(the_step,ALL_FILTERS[index])){
        return ALL_FILTERS[index+1];
      }
    }
    return ALL_FILTERS[ALL_FILTERS.length-1];
  }

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      {(companies && (companies?.length > 1)) ? (<div><hr></hr></div>) : (null)}
      <div className="row-navbar">
        {(companies) ? (undefined) : (<div><img className="loader-logo" src={LoaderLogo} alt="" /></div>)}
        {companies && (companies?.length > 1) && companies.map(company => (
          <div key={"div-" + company.id} className="squama-item-navbar squama-item-navbar-companies">
            <Link to={'/companies/' + company.id}>
              <button type="button" key={"button-" + company.id} className={(company.id === selectedCompany)?"squama-btn-navbar-companies btn-selected":"squama-btn-navbar-companies"} disabled={(selectedCompany === 0)}>
                {company.name}
              </button>
            </Link>
          </div>
        ))}
      </div>
      <div><hr></hr></div>
      <div className="main-center-div flex-station-cards the-background">
        {(updating || ((stationsDisplayed) && (stationsDisplayed.length))) ? (
          (stationsDisplayed) && stationsDisplayed.map(station => (
            <StationCard key={station.id} station={station} fullscreen={false} finalpath={finalPath} mapMode={false}/>
          ))
        ) : (canShowLogout)&&(
          <div className="main-center-column-div">
            <div className="flex-center white-font h1-font">Merci pour votre visite</div>
            <Link to='/accueil'><button type="button" className="squama-btn-navbar">
              Accueil
            </button></Link>
          </div>
        )}
        {
        (updating) && (updating > stationsDisplayed.length) ?
        ([...Array(updating - stationsDisplayed.length)].map((element,index) => (
          <div key={"loader-"+index} className='panel-station-default panel-station-default-not-fullscreen panel-station-body panel-station-simple-row-justify-center'>
            {return_logo_from_type("loader","icon-sensor-150")}
          </div>
        ))):undefined
        }
      </div>
    </div>
  );
}
export default SquamaCompanyDashBoard;
