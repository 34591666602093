import {authHeader} from '../helpers/auth-headers';
import Transmitter from "../models/transmitter";
import OtaFirmware from "../models/ota_firmware";
import SiteService from './site-service';

export default class TransmitterService {

  static isEmpty(data: Object): boolean {
    return Object.keys(data).length === 0;
  }

  static handleError(error: Error): void {
    console.error(error);
  }

  static getDownloadableFirmware(): Promise<OtaFirmware[]> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader()
    }
    return fetch(SiteService.getApiUrl()+`getucfirmware`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static downloadForStation(station_id: number, filename: string): Promise<void | Blob> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
    }
    return fetch(SiteService.getApiUrl()+`downloadfile/${station_id}/${filename}`,{headers})
    .then(response => response.blob())
    .catch(error => this.handleError(error));
  }

  static getTransmitter(id: number): Promise<Transmitter[]|null> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader()
    }
    //console.log("getTransmitter:"+id)
    return fetch(SiteService.getApiUrl()+`transmitters/${id}`,{headers})
    .then(response => response.json())
    .then(data => this.isEmpty(data) ? null : data)
    .catch(error => this.handleError(error));
  }

}
