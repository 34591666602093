import StationTransmitter from './station-transmitter';

export default class Company {
    id: number;
    name: string;
    created: Date;
    address: string;
    phone: string;
    email: string;
    stations: Array<StationTransmitter>;

    constructor(
        id: number,
        name: string = "",
        address: string = "",
        phone: string = "",
        email: string = "",
        created: Date = new Date(),
        stations = [],
       ) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.phone = phone;
        this.email = email;
        this.created = created;
        this.stations = stations;
       }
}