export function add_quarter_to_date(in_date: Date, nb_quarter: number){
  let theNewDate = new Date(in_date);
  return new Date(theNewDate.getTime()+nb_quarter*15*60000);
  }

export function convert_date_to_UTC(in_date: Date) {
  return new Date(in_date.getUTCFullYear(), in_date.getUTCMonth(), in_date.getUTCDate(), in_date.getUTCHours(), in_date.getUTCMinutes(), in_date.getUTCSeconds());
}

export function format_date(in_date: Date):string{
  //will return a different value if it has been obtain by websocket (which sync the date with browser)
  let new_in_date = new Date(in_date);
  return ((new_in_date.getHours()<10)?"0":"")+new_in_date.getHours()+":"+((new_in_date.getMinutes()<10)?"0":"")+new_in_date.getMinutes()+" "+new_in_date.getDate() + "/"+(((new_in_date.getMonth()+1)<10)?"0":"")+(new_in_date.getMonth()+1)+"/"+(new_in_date.getFullYear()-2000);
}


export function change_time_zone(date:Date, timeZone: string) {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      }),
    );
  }

  return new Date(
      date.toLocaleString('en-US', {
        timeZone,
      }),
    );
  }