import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SquamaNavbar from '../components/squama-navbar';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { useForm } from "react-hook-form";
import {fillContactMessage} from '../helpers/send-mail';
import EmailService from '../services/email-service';

type ModalItems = {
  contactName: string;
  companyName: string;
  companyAddress: string;
  companyPhone: string;
  companyEmail: string;
  object: string;
  captchaInput: string;
  message: string;
}

const SquamaContact: FunctionComponent = () => {
  const { register, handleSubmit } = useForm<ModalItems>();
  const [throwError, setThrowError] = useState<string>("");
  const history = useNavigate();
  useEffect(() => {
    loadCaptchaEnginge(8);
  },[]);

  let isDisabled = false;

  function check_valid_email(email: string) {
    let list = ["yopmail","jetable","link2mail",
      "haltospam","meltmail","anonymbox","10minutemail",
      "mailtemporaire","trashmail","dodgit","filzmail","trash",
      "spam"
    ]
    let returned = list.filter(one => email.includes(one));
    console.log(returned);
    if (returned.length > 0)
    {
      return false;
    }
    return true;
  }

  const handleTheSubmit = (data: ModalItems) => {
    setThrowError("");
    console.log("data", data);
    if(check_valid_email(data.companyEmail)){
      if(validateCaptcha(data.captchaInput)===true){
        console.log("Captcha correct !!");
        let theSubject: string = '[squama.fr]:PageWeb Contact :'+data.object;
        if(window.location.pathname === "/contactconnected"){
          theSubject = '[squama.fr]:PageWeb Contact Connected :'+data.object;
        }
        EmailService.sendEmailAPI(theSubject,
          fillContactMessage(
            data.contactName,
            data.companyName,
            data.companyAddress,
            data.companyPhone,
            data.companyEmail,
            data.object,
            data.message),
            data.companyEmail
            ).then(() => {
              window.alert('Message envoyé !');
            });
        if(window.location.pathname === "/contactconnected"){
          history("/dashboard");
        }else{
          history("/accueil");
        }
      }else{
        setThrowError("Mauvais Captcha");
      }
    }else{
      setThrowError("Email invalid !");
    }
  }

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-center-div flex-contact-page the-background">
          <div className="panel-contact">
          <div className="panel-contact-heading flex-center">Envoyer un message</div>
            <div className="panel-contact-left-part">
              <div className="panel-contact-body">
                {(throwError)?(
                  <div className="panel-contact-one-line error-font">{throwError}</div>
                ):null
                }
                <div className="panel-contact-one-line">
                  <div className="panel-contact-one-line-title">Objet*:</div>
                  <div className="panel-contact-one-line-content">
                    <select id="object" {...register("object",{ required: true })}>
                      <option value="Demande informations">Demande d'informations</option>
                      <option value="Demande devis">Demande de devis</option>
                      <option value="Demande technique">Demande technique</option>
                      <option value="Demande commande">Commande</option>
                      <option value="Autre demande">Autre</option>
                    </select>
                  </div>
                </div>
                <div className="panel-contact-one-line">
                  <div className="panel-contact-one-line-title">Prénom/NOM*:</div>
                  <div className="panel-contact-one-line-content"><input className="stay-in-div" id="contact-name" type="text" disabled={isDisabled} {...register("contactName",{ required: true })}/></div>
                </div>
                <div className="panel-contact-one-line">
                  <div className="panel-contact-one-line-title">Société*:</div>
                  <div className="panel-contact-one-line-content"><input className="stay-in-div" id="company-name" type="text" disabled={isDisabled} {...register("companyName",{ required: true })}/></div>
                </div>
                <div className="panel-contact-one-line">
                  <div className="panel-contact-one-line-title">Adresse:</div>
                  <div className="panel-contact-one-line-content"><input className="stay-in-div" id="company-address" type="text" disabled={isDisabled} {...register("companyAddress",{ required: false })}/></div>
                </div>
                <div className="panel-contact-one-line">
                  <div className="panel-contact-one-line-title">Téléphone*:</div>
                  <div className="panel-contact-one-line-content"><input className="stay-in-div" id="company-phone" type="tel" disabled={isDisabled} {...register("companyPhone",{ required: true, minLength: 10, maxLength: 10 })}/></div>
                </div>
                <div className="panel-contact-one-line">
                  <div className="panel-contact-one-line-title">Email*:</div>
                  <div className="panel-contact-one-line-content"><input className="stay-in-div" id="company-email" type="text" disabled={isDisabled} {...register("companyEmail",{ required: true, pattern: /^\S+@\S+$/i})}/></div>
                </div>
                <div className="panel-contact-one-line panel-contact-top-space">Je ne suis pas un robot :</div>
                <div className="panel-contact-one-line">Recopier les caractères ci-dessous*:</div>
                <div className="panel-contact-one-line">
                  <div className="panel-contact-one-line-content"><input className="stay-in-div" id="captcha-input" type="text" disabled={isDisabled} {...register("captchaInput",{ required: true, minLength: 5, maxLength: 10000 })}/></div>
                </div>
                <div className="panel-contact-one-line">
                  <div className="panel-contact-one-line-content flex-center"><LoadCanvasTemplate /></div>
                </div>
              </div>
              <div className="panel-contact-right-part">
                <div className="panel-contact-one-line">Message*:</div>
                <div className="panel-contact-one-line">
                  <textarea id="message" className="stay-in-div panel-contact-message-textarea" disabled={isDisabled} {...register("message",{ required: true })}/>
                </div>
              </div>
            </div>

            <div className="panel-contact-footer">
              <button type="button" className="squama-btn-navbar squama-btn-gestion-modal" disabled={isDisabled} onClick={handleSubmit(handleTheSubmit)}>
                Envoyer
              </button>
            </div>
          </div>
      </div>
    </div>
  );
}
  
export default SquamaContact;