import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Group } from '@visx/group';
import { scaleOrdinal, scaleTime, scaleLinear } from '@visx/scale';
import { LinePath, Line, Bar } from '@visx/shape';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { Brush } from '@visx/brush';
import { Bounds } from '@visx/brush/lib/types';
import { PatternLines } from '@visx/pattern';
import { min, max, extent, bisector } from 'd3-array';
import { CharWaterlevelValuesAndDescriptors, CharWaterlevelValues } from '../models/chart-waterlevel-value';
import { localPoint } from '@visx/event';
import { withTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { timeFormat, timeFormatDefaultLocale } from 'd3-time-format';
import { LegendOrdinal } from "@visx/legend";

type TooltipData = CharWaterlevelValues;

timeFormatDefaultLocale({
  dateTime    : '%a %b %e %X %Y',
  date        : '%d/%m/%Y',
  time        : '%H%H:%m%m:%s%s',
  periods     : ['', ''],
  days        : ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  shortDays   : ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
  months      : ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
  shortMonths : ['Janv', 'Fev', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec']
});

const embacleText = "EMBÂCLE(CM)"
const embacleColor = "#BFE6F7"
const alimentationText = "ALIMENTATION(CM)"
const alimentationColor = "#607D8B"
const referenceDepthColor = "#F6B960"

const hideMainBottomAxis=false;
const hideZoomBottomAxis=false;
const hideZoomLeftAxis=false;

// Initialize some variables
const axisColor = "#000";
const axisBottomTickLabelProps = {
  textAnchor: 'middle' as const,
  fontFamily: 'Arial',
  fontSize: 10,
  fill: axisColor,
};
const axisLeftTickLabelProps = {
  dx: '-0.25em',
  dy: '0.25em',
  fontFamily: 'Arial',
  fontSize: 10,
  textAnchor: 'end' as const,
  fill: axisColor,
};
const axisStandardWLeftTickLabelProps = {
  dx: '80px',
  dy: '+2em',
  fontFamily: 'Arial',
  fontSize: 10,
  textAnchor: 'end' as const,
  fill: axisColor,
};

// Initialize some variables
const brushMargin = { top: 10, bottom: 15, left: 50, right: 20 };
/* space between real chart and zoom zone */
const chartSeparation = 20;
const PATTERN_ID = 'brush_pattern';
export const accentColor = '#607D8B';//color inside the selected grille
export const background = '#FFFFFF';//main background mix 1
export const tooltipColor = '#004a55';
const selectedBrushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: 'black',
};

const formatDate = timeFormat("Le %d/%m/%y à %H:%M");

export type BrushProps = {
  allValuesAndDescriptors: CharWaterlevelValuesAndDescriptors;
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  compact?: boolean;
  enable_point?:boolean;
  is_pap?:boolean;
};

export default withTooltip<BrushProps, TooltipData>(
  ({
        compact = false,
        allValuesAndDescriptors,
        width,
        height,
        margin = {
          top: 20,
          left: 40,
          bottom: 20,
          right: 20,
        },
        showTooltip,
        hideTooltip,
        tooltipData,
        tooltipTop = 0,
        tooltipLeft = 0,
        enable_point = false,
        is_pap = false,
      }: BrushProps & WithTooltipProvidedProps<TooltipData>) => {

  const [filteredValues, setfilteredValues] = useState<CharWaterlevelValues[]>(allValuesAndDescriptors?.charwaterlevelvalues);
  const [allWLName, setAllWLName] = useState<string[]>([]);

  useEffect(() => {
    setfilteredValues(allValuesAndDescriptors?.charwaterlevelvalues);
    if(allValuesAndDescriptors?.charwaterlevelvalues){
      let t_allWLName:string[] = [];
      if(! is_pap){
        allValuesAndDescriptors?.charwaterleveldescriptors.forEach(one => {
          t_allWLName.unshift(one.name);
        })
      }else{
        allValuesAndDescriptors?.charwaterlevelvalues[0].allValues.forEach(one => {
          if((one.name === alimentationText)||(one.name === embacleText)){
            t_allWLName.push(one.name);
          }
        })
      }
      //console.log("t_allWLName:"+t_allWLName);
      setAllWLName(t_allWLName);
    }
    //setAllWLName
  },[allValuesAndDescriptors, is_pap]);

  // accessors
  const getDate = (d: CharWaterlevelValues) => new Date(d.date.replace(" ","T"));
  const bisectDate = bisector<CharWaterlevelValues, Date>(d => new Date(d.date.replace(" ","T"))).left;
  const getWLValue = (d:CharWaterlevelValues, n:string) => d.allValues.find(one => one.name === n)?.value || 0

  function getTheValue(d: CharWaterlevelValues) {
    if(allWLName){
      return getWLValue(d, allWLName[0]) || 0;
    }
    return 0;
  }
  
  function getTipValues(d: CharWaterlevelValues):string {
    let returned = ""
    if(allWLName){
      allWLName.forEach(one => {
        returned += one+"="+(getWLValue(d, one) || 0).toString()
        if(is_pap){returned +="cm;"}else{returned +=";"}
      })
    }else{
      returned += "0cm"
    }
    return returned;
  }
  function getDeeperDepth(d: CharWaterlevelValues):number {return d.deeperDepth;}
  function getUpperDepth(d: CharWaterlevelValues):number {return d.upperDepth;}
  function getReferenceDepth(d: CharWaterlevelValues):number {return d.referenceDepth;}

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return;
    const { x0, x1, y0, y1 } = domain;
    const burshCopy = allValuesAndDescriptors?.charwaterlevelvalues.filter(s => {
      const x = getDate(s).getTime();
      const y = getTheValue(s);
      return x > x0 && x < x1 && y > y0 && y < y1;
    });
    setfilteredValues(burshCopy);
  };

  const innerHeight = height - margin.top - margin.bottom;
  const topChartBottomMargin = compact ? chartSeparation / 2 : chartSeparation + 10;
  const topChartHeight = 0.8 * innerHeight - topChartBottomMargin;
  const bottomChartHeight = innerHeight - topChartHeight - chartSeparation;

  // bounds
  const xAmplitude = Math.max(width - margin.left - margin.right, 0);
  const yMax = Math.max(topChartHeight, 0);
  const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0);
  const yBrushMax = Math.max(bottomChartHeight - brushMargin.top - brushMargin.bottom, 0);

  // scales
  const dateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xAmplitude],
        domain: extent(filteredValues, getDate) as [Date, Date],
      }),
    [xAmplitude, filteredValues],
  );
  const WaterlevelValueScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        domain: [min(filteredValues, getDeeperDepth) || 0, max(allValuesAndDescriptors?.charwaterlevelvalues, getUpperDepth) || 0],
        //domain: [-10 , 30],
        nice: true,
      }),[yMax, filteredValues, allValuesAndDescriptors?.charwaterlevelvalues],);
  const brushDateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xBrushMax],
        domain: extent(allValuesAndDescriptors?.charwaterlevelvalues, getDate) as [Date, Date],
      }),
    [xBrushMax, allValuesAndDescriptors?.charwaterlevelvalues],
  );
  const brushWaterlevelValueScale = useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        domain: [min(allValuesAndDescriptors?.charwaterlevelvalues, getDeeperDepth) || 0, max(allValuesAndDescriptors?.charwaterlevelvalues, getUpperDepth) || 0],
        //domain: [-10 , 30],
        nice: true,
      }),
    [yBrushMax, allValuesAndDescriptors?.charwaterlevelvalues],
  );

  const initialBrushPosition = useMemo(
    () => ({
      start: { x: brushDateScale(getDate(allValuesAndDescriptors?.charwaterlevelvalues[Math.floor(allValuesAndDescriptors?.charwaterlevelvalues.length*(2/3))])) },
      end: { x: brushDateScale(getDate(allValuesAndDescriptors?.charwaterlevelvalues[allValuesAndDescriptors?.charwaterlevelvalues.length-1])) },
    }),
    [brushDateScale, allValuesAndDescriptors?.charwaterlevelvalues],
  );

  const x_tooltip_delta = margin.left;

  const handleMouseMove = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
      //console.log("useCallback called");
      const { x } = localPoint(event) || { x: 0 };

      const x0 = dateScale.invert(x - x_tooltip_delta);
      const index = bisectDate(filteredValues, x0, 1);
      const d0 = filteredValues[index - 1];
      const d1 = filteredValues[index];
      let d = d0;
      if (d1 && getDate(d1)) {
        d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
      }
      showTooltip({
        tooltipData: d,
        tooltipLeft: x,
        tooltipTop: WaterlevelValueScale(getTheValue(d)),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showTooltip, WaterlevelValueScale, dateScale, filteredValues, x_tooltip_delta, bisectDate]);

  //console.log("some info:");
  //console.log(WaterlevelValueScale);
  //console.log(allValuesAndDescriptors);
  //console.log("min:"+min(allValues, getTheMinValue));
  //console.log("max:"+max(allValues, getTheMaxValue));
  //console.log("minFiltered:"+min(filteredValues, getTheMinValue));
  //console.log("maxFiltered:"+max(filteredValues, getTheMaxValue));
  //console.log("maxFiltered:"+max(filteredValues, getTheMaxValue));
  //console.log("brushDateScale:"+extent(allValues, getDate));
  //console.log("dateScale:"+extent(filteredValues, getDate));
  //console.log(tooltipData);
  //tooltipData && console.log("tooltipData:"+getTheValue(tooltipData));

  function get_color_from_name(name:string){
    if(name===embacleText){
      return embacleColor;
    }
    return alimentationColor;
  }

  function lowerCaseAllWordsExceptFirstLettersDeleteCm(ine:string) {
    return ine.replace("(CM)", "").replace(/\S*/g, function (word) {
        return word.charAt(0) + word.slice(1).toLowerCase();
    });
  }

  function copyCharWaterlevelValues(input:CharWaterlevelValues[]){
    return JSON.parse(JSON.stringify(input));
  }

  function copyOneCharWaterlevelValues(input:CharWaterlevelValues){
    return JSON.parse(JSON.stringify(input));
  }

  function display_a_waterlevel_line(name:string,theValueUsed:CharWaterlevelValues[],xScale: any, yScale: any){
    if(!theValueUsed || !theValueUsed[0]){return undefined;}
    let copytheValueUsed:CharWaterlevelValues[] = copyCharWaterlevelValues(theValueUsed);
    let doublecopytheValueUsed:CharWaterlevelValues[]=[];

    let theZeroFillColorValue = 0;

    if((name !== alimentationText) && (name !== embacleText)){
      let index = allWLName.indexOf(name);
      theZeroFillColorValue = index;
      //console.log("Handling "+name+", index:"+index);
      copytheValueUsed.forEach(oneV => {
        let tmp = oneV.allValues.find(one => one.name === name);
        if(tmp !== undefined){tmp.value += index;}
      })
    }

    if(name === alimentationText){
      theZeroFillColorValue = getDeeperDepth(copytheValueUsed[0]);
    }

    //Double the values to make it bars
    copytheValueUsed.forEach((oneElement,index)=>{
      doublecopytheValueUsed.push(copyOneCharWaterlevelValues(oneElement));
      if(index+1 < copytheValueUsed.length){
        let copiedElement:CharWaterlevelValues = copyOneCharWaterlevelValues(oneElement);
        let nextElement:CharWaterlevelValues = copytheValueUsed[index+1];
        copiedElement.date=nextElement.date;
        doublecopytheValueUsed.push(copiedElement);
      }

    })

    //Set first point to fill curve
    /*
    let tmp = copytheValueUsed[0].allValues.find(one => one.name === name);
    if(tmp !== undefined){
      tmp.value = theZeroFillColorValue;
    }
    */
    let newStart:CharWaterlevelValues=copyOneCharWaterlevelValues(doublecopytheValueUsed[0]);
    let testo:Date = new Date(newStart.date) ;
    testo = new Date( testo.getTime() - (1*60000))
    newStart.date = testo.toString()
    let tmpo = newStart.allValues.find(one => one.name === name);
    if(tmpo !== undefined){
      tmpo.value = theZeroFillColorValue;
    }
    doublecopytheValueUsed.unshift(newStart)

    //Set last point to fill curve
    /*
    let tmp2 = doublecopytheValueUsed[doublecopytheValueUsed.length-1].allValues.find(one => one.name === name);
    if(tmp2 !== undefined){tmp2.value = theZeroFillColorValue;}
    */
    let newEnd:CharWaterlevelValues=copyOneCharWaterlevelValues(doublecopytheValueUsed[doublecopytheValueUsed.length-1]);
    let tmpe = newEnd.allValues.find(one => one.name === name);
    if(tmpe !== undefined){
      tmpe.value = theZeroFillColorValue;
    }
    doublecopytheValueUsed.push(newEnd)

    return (
      <LinePath<CharWaterlevelValues>
          key={`${Math.random()}`}
          id={`${Math.random()}`}
          data={doublecopytheValueUsed}
          x={d => xScale(getDate(d)) || 0}
          y={d => yScale(getWLValue(d,name)) || 0}
          stroke="#333"
          strokeWidth={1}
          strokeOpacity={0.1}
          shapeRendering="geometricPrecision"
          markerMid="url(#marker-circle)"
          markerStart="url(#marker-line)"
          markerEnd="url(#marker-arrow)"
          fill={get_color_from_name(name)}
      />
    )
  }

  const legendPapColorScale = scaleOrdinal({
    domain: [lowerCaseAllWordsExceptFirstLettersDeleteCm(embacleText),lowerCaseAllWordsExceptFirstLettersDeleteCm(alimentationText),"Côte minimale"],
    range: [embacleColor,alimentationColor,referenceDepthColor],
  });

  return (
    <div>
      <svg width={width} height={height}>
        {/* Legend part (text, lines) */}
        {/*<rect x={0} y={0} width={width} height={height} fill={`url(#${GRADIENT_ID})`} rx={0} />Problem on mobile*/}{/* rx is the corner round */}
        {/* Main chart part */}
        <Group left={margin.left} top={topChartBottomMargin}>
            {(is_pap)&&(
              <text x="-5" y="10" transform="rotate(-90)" fontSize={10}>
                {"cm"}
              </text>)
            }
            {
              (allWLName) && allWLName.map(one => display_a_waterlevel_line(one, filteredValues,dateScale,WaterlevelValueScale))
            }
            {(is_pap) && (
            <Line
              from={{ x: 0, y: WaterlevelValueScale(getReferenceDepth(allValuesAndDescriptors?.charwaterlevelvalues[0])) }}
              to={{ x: xAmplitude, y: WaterlevelValueScale(getReferenceDepth(allValuesAndDescriptors?.charwaterlevelvalues[0])) }}
              stroke={referenceDepthColor}
              strokeWidth={3}
              pointerEvents="none"
              strokeDasharray="10,5"
            />)}
            <Bar
              x={0}
              y={0}
              width={xAmplitude}
              height={yMax}
              fill="transparent"
              rx={0}
              onMouseMove={handleMouseMove}
              onMouseLeave={() => hideTooltip()}
              onTouchStart={() => hideTooltip()}
              onTouchMove={handleMouseMove}
            />
            {tooltipData && ! enable_point && (
            <g>
              <Line
                from={{ x: tooltipLeft - x_tooltip_delta, y: margin.top }}
                to={{ x: tooltipLeft - x_tooltip_delta, y: innerHeight + margin.top }}
                stroke={tooltipColor}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
            </g>
          )}
            {tooltipData && enable_point && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                stroke={tooltipColor}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={tooltipColor}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
          {!hideMainBottomAxis && (
            <AxisBottom
              top={yMax}
              scale={dateScale}
              numTicks={width > 520 ? 10 : 5}
              stroke={axisColor}
              tickStroke={axisColor}
              tickLabelProps={() => axisBottomTickLabelProps}
            />
          )}
          {(is_pap) && (
            <AxisLeft
              scale={WaterlevelValueScale}
              numTicks={5}
              stroke={axisColor}
              tickStroke={axisColor}
              tickLabelProps={() => axisLeftTickLabelProps}
            />
          )}
          {(!is_pap) && (
            <AxisLeft
              hideZero
              scale={WaterlevelValueScale}
              numTicks={allWLName.length}
              stroke={axisColor}
              tickStroke={axisColor}
              tickLabelProps={() => axisStandardWLeftTickLabelProps}
              tickFormat={function(d,i){ return allWLName[i] }}
            />
          )}
        </Group>
        {/* Zoom part */}
          <Group left={margin.left+10} top={topChartHeight + topChartBottomMargin + margin.top}>
            {(allWLName) && allWLName.map(one => display_a_waterlevel_line(one, allValuesAndDescriptors?.charwaterlevelvalues,brushDateScale,brushWaterlevelValueScale))}

            {!hideZoomBottomAxis && (
              <AxisBottom
                top={yBrushMax}
                scale={brushDateScale}
                numTicks={width > 520 ? 10 : 5}
                stroke={axisColor}
                tickStroke={axisColor}
                tickLabelProps={() => axisBottomTickLabelProps}
              />
            )}
            {!hideZoomLeftAxis && (
              <AxisLeft
                scale={brushDateScale}
                numTicks={0}
                stroke={axisColor}
                tickStroke={axisColor}
                tickLabelProps={() => axisLeftTickLabelProps}
              />
            )}
            <PatternLines
              id={PATTERN_ID}
              height={8}
              width={8}
              stroke={accentColor}
              strokeWidth={1}
              orientation={['diagonal']}
            />
            <Brush
              xScale={brushDateScale}
              yScale={brushWaterlevelValueScale}
              width={xBrushMax}
              height={yBrushMax}
              margin={brushMargin}
              handleSize={8}
              resizeTriggerAreas={['left', 'right']}
              brushDirection="horizontal"
              initialBrushPosition={initialBrushPosition}
              onChange={onBrushChange}
              onClick={() => setfilteredValues(allValuesAndDescriptors?.charwaterlevelvalues)}
              selectedBoxStyle={selectedBrushStyle}
            />
          </Group>
          {/* end of zoom part */}
      </svg>
      {(is_pap) && (<div
        style={{
          position: "absolute",
          top: margin.top / 2 - 10,
          right: "0px",
          display: "flex",
          justifyContent: "right"
        }}
        >
        <LegendOrdinal scale={legendPapColorScale} direction="column" labelMargin="0 15px 0 0" />
      </div>)}
      {tooltipData && (
        <div>
          <Tooltip
            top={0 }
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              minWidth: 160,
              textAlign: 'center',
              transform: 'translateX(-50%)',
            }}
          >
            {formatDate(getDate(tooltipData))+" : "+getTipValues(tooltipData)}
          </Tooltip>
        </div>
      )}
    </div>
  );
})