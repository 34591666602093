import { FunctionComponent, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { convert_date_to_UTC, format_date } from '../../helpers/format-date';
import { fw_nicely_displayed, sensor_hw_nicely_displayed, waterlevel_uuid_nicely_displayed } from '../../helpers/station-helper';
import TextTable from '../TextTable';
import WaterLevelSensor from '../../models/water-level-sensor';
import { io } from "socket.io-client";
import {authHeader} from '../../helpers/auth-headers';
import SiteService from '../../services/site-service';

const ModalShowSensorsTextTable: FunctionComponent = () => {
  const displayName = "ModalShowSensorsTextTable:";
  const enableDebug = false;
  const enableMoreDebug = false;
  const [sensors, setSensors] = useState<WaterLevelSensor[]|null>();
  const [showModal, setshowModal] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (enableDebug) console.log(displayName,"useEffect");
    if(!showModal){return;}
    /* BEGINNING websocket connection */
    const socket = io(SiteService.getWebsocketUrl(), {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
      extraHeaders: {
        "my-custom-header": "1234" // WARN: this will be ignored in a browser
      }
    });
    /* END websocket connection */
    /* BEGINNING listenner setup */
    socket.on("answer", (answer: any) => {
      if (enableDebug) console.log(displayName,"Answer:", answer);
    });
    socket.on("sensors_desc", (the_sensors_desc_str: string) => {
      const the_sensors_desc = JSON.parse(the_sensors_desc_str);
      if (enableMoreDebug) console.log(displayName,"the_sensors_desc:", the_sensors_desc);
      setSensors(the_sensors_desc);
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      console.log(displayName,"logged_out:", logged_out_desc_str);
      setSensors(null);
    });
    /* END listenner setup */
    /* START websocket requests */
    if(showModal || reload){
      if (enableDebug) console.log(displayName,"Request all sensors_desc");
      setSensors(null);
      const the_request = {
        type: "sensors_desc",
        number: 0,
        liste: [0],
        from: "station_id",
        filter: ["testvalues"],
        token: authHeader()
      }
      socket.emit("request", the_request);
      setReload(false);
    }
    /* END websocket requests */
    /* START websocket cleanup handler */
    return function cleanup() {
      if (enableDebug) console.log(displayName,"useEffect.cleanup")
      socket.disconnect();
    };
    /* END websocket cleanup handler */
  }, [showModal,reload]);

  const handleCloseModal = () => setshowModal(false);
  const handleShowModal = () => setshowModal(true);
  const handleReload = () => setReload(true);

  function modal_show_tree() {
    const TextValueColumns =
    [
      {
        header: "Sensors",
        footer: (props:any) => props.column.id,
        columns: [
        {
          header: "ID",
          accessorKey: "id",
          maxSize: 50,
        },
        {
          header: "Created",
          accessorKey: "created",
          minSize: 200,
          accessorFn: (row:any) =>convert_date_to_UTC(new Date(row.created)),
          cell: (info:any) => format_date(info.getValue()),
        },
        {
          header: "MAC",
          accessorKey: "mac",
          accessorFn: (row:any) => waterlevel_uuid_nicely_displayed(row),
        },
        {
          header: "Comments tests",
          accessorKey: "comments",
          maxSize:50,
        },
        {
          header: "Name",
          accessorKey: "name",
          maxSize:50,
        },
        {
          header: "Value",
          accessorKey: "current_value",
          maxSize:50,
        },
        {
          header: "Value Date",
          accessorKey: "date_current_value",
          minSize: 200,
          accessorFn: (row:any) =>convert_date_to_UTC(new Date(row.date_current_value)),
          cell: (info:any) => format_date(info.getValue()),
        },
        {
          header: "Inverted Value Date",
          accessorKey: "date_last_inverted_value",
          minSize: 200,
          accessorFn: (row:any) =>convert_date_to_UTC(new Date(row.date_last_inverted_value)),
          cell: (info:any) => format_date(info.getValue()),
        },
        {
          header: "Depth",
          accessorKey: "depth",
          maxSize:50,
        },
        {
          header: "Trigger Under Water",
          accessorKey: "trigger_under_water",
          maxSize:50,
        },
        {
          header: "FW",
          accessorFn: (row:any) =>fw_nicely_displayed(row.fw_version),
          maxSize:50,
        },
        {
          header: "HW",
          accessorKey: "hw_version",
          accessorFn: (row:any) =>sensor_hw_nicely_displayed(row.hw_version),
        },
        {
          header: "Transmitter",
          accessorKey: "transmitter_id",
          maxSize: 50,
        },
        {
          header: "Last Test",
          accessorKey: "test",
          maxSize: 50,
          columns: [
            {
              header: "Date",
              accessorKey: "lastsensortestvalue_date",
              maxSize: 50,
              accessorFn: (row:any) =>(row.lastsensortestvalue)&&(row.lastsensortestvalue.created),
            },
            {
              header: "ID",
              accessorKey: "lastsensortestvalue_id",
              maxSize: 50,
              accessorFn: (row:any) =>(row.lastsensortestvalue)&&(row.lastsensortestvalue.id),
            },
            {
              header: "Result",
              accessorKey: "lastsensortestvalue_result",
              maxSize: 50,
              accessorFn: (row:any) =>(row.lastsensortestvalue)&&(row.lastsensortestvalue.c_test_result),
            },
            {
              header: "Comments",
              accessorKey: "lastsensortestvalue_comments",
              maxSize: 50,
              accessorFn: (row:any) =>(row.lastsensortestvalue)&&(row.lastsensortestvalue.comments),
            },
            {
              header: "NbCon",
              accessorKey: "lastsensortestvalue_c_nb_con",
              maxSize: 50,
              accessorFn: (row:any) =>(row.lastsensortestvalue)&&(row.lastsensortestvalue.c_nb_con),
            },
          ]
        },
        ]
      },
      ]
      ;

    return (
      <Modal
        dialogClassName="modal-show-tree"
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header translate="yes" closeButton>
          <Modal.Title>Arbre des capteurs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col align-self-center">Now(UTC):{format_date(convert_date_to_UTC(new Date()))}</div>
              <div className="col align-self-center">Now:{format_date(new Date())}</div>
            </div>
            {
             (sensors) && (sensors.length) && (<TextTable orderBy='created' orderByDesc={false} allowPagination={true} numberOfElementDisplayed={30} searchedText={["id","mac"]} allValues={sensors} valuesColumns={TextValueColumns}/>)
             }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="squama-btn-navbar squama-btn-gestion-modal" onClick={handleReload}>Reload</button>
          <button type="button" className="squama-btn-navbar squama-btn-gestion-modal" onClick={handleCloseModal}>
            Fermer
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
  return (
    <div>
      {modal_show_tree()}
      <button type="button" className="squama-btn-navbar squama-btn-gestion" onClick={handleShowModal}>
        Visualiser les capteurs
      </button>
    </div>
  )
}

export default ModalShowSensorsTextTable;
