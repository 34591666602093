import {authHeader} from '../helpers/auth-headers';
import Datatosend from '../models/datatosend';
import SiteService from './site-service';

export default class DeviceapiService {

  static getDataToSend(): Promise<Datatosend[]> {
    return fetch(
      SiteService.getDeviceapiUrl()+`datatosend`,
      {
        headers : {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': authHeader()
        },
        mode:'cors'
      })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static isEmpty(data: Object): boolean {
    return Object.keys(data).length === 0;
  }

  static handleError(error: Error): void {
    console.error(error);
  }
}
