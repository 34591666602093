import { FunctionComponent, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { add_quarter_to_date, convert_date_to_UTC, format_date } from '../../helpers/format-date';
import { fw_nicely_displayed, transmitter_get_number_of_quarter_before_next_trame, transmitter_hw_str } from '../../helpers/station-helper';
import TextTable from '../TextTable';
import { Link } from 'react-router-dom';
import Login from '../../models/login';
import AuthenticationService from '../../services/authentication-service';

const ModalShowLoginsTextTable: FunctionComponent = () => {
  const displayName = "ModalShowLoginsTextTable:";
  const enableDebug = false;
  const enableMoreDebug = false;

  const [logins, setLogins] = useState<Login[]|null>([]);
  const [showModal, setshowModal] = useState(false);

  const handleCloseModal = () => setshowModal(false);
  const handleShowModal = () => {
    setLogins(null);
    AuthenticationService.getLogins().then(logins => {
      setLogins(logins);
    });
    setshowModal(true);
  }

  function modal_show_tree() {
    const TextValueColumns =
    [
      {
        header: "Logins",
        footer: (props:any) => props.column.id,
        columns: [
        {
          header: "Email",
          accessorKey: "email",
          maxSize:50,
        },
        {
          header: "Nom",
          accessorKey: "name",
          maxSize:250,
        },
        {
          header: "user_id",
          accessorKey: "user_id",
          maxSize:20,
        },
        {
          header: "Date du dernier login",
          accessorKey: "date",
          maxSize:200,
          accessorFn: (row:any) =>convert_date_to_UTC(new Date(row.date)),
          cell: (info:any) => format_date(info.getValue()),
        },
        {
          header: "Où ?",
          accessorKey: "entry",
          maxSize:50,
        },
        {
          header: "Nombre connexions",
          accessorKey: "count_login",
          maxSize:50,
        },
        ]
      },
      ]
      ;

    return (
      <Modal
        dialogClassName="modal-show-tree"
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header translate="yes" closeButton>
          <Modal.Title>Derniers logins par utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col align-self-center">Now(UTC):{format_date(convert_date_to_UTC(new Date()))}</div>
              <div className="col align-self-center">Now:{format_date(new Date())}</div>
            </div>
            {
             (logins) && (logins.length) && (<TextTable orderBy='date' orderByDesc={true} allowPagination={true} numberOfElementDisplayed={30} allValues={logins} valuesColumns={TextValueColumns}/>)
             }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="squama-btn-navbar squama-btn-gestion-modal" onClick={handleShowModal}>Reload</button>
          <button type="button" className="squama-btn-navbar squama-btn-gestion-modal" onClick={handleCloseModal}>
            Fermer
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
  return (
    <div>
      {modal_show_tree()}
      <button type="button" className="squama-btn-navbar squama-btn-gestion" onClick={handleShowModal}>
        Visualiser les logins
      </button>
    </div>
  )
}

export default ModalShowLoginsTextTable;
