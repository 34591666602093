import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import AuthenticationService from '../services/authentication-service';
import SquamaNavbar from '../components/squama-navbar';

const Logout: FunctionComponent = () => {
  AuthenticationService.logout();
  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-center-column-div the-background">
        <div className="flex-center white-font h1-font">Merci pour votre visite</div>
        <Link to='/accueil'><button type="button" className="squama-btn-navbar">
          Accueil
        </button></Link>
      </div>
    </div>
  );
}
  
export default Logout;