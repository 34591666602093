import { FunctionComponent, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import SquamaNavbar from '../components/squama-navbar';
import RandomLinkService from '../services/randomlink-service';
import {transApi} from '../helpers/simple-translator';

const SquamaRandomLink: FunctionComponent = ()=>{
  const params = useParams<{
    randomLink: string,
    thepath: string
  }>();
  const [message, setMessage] = useState<string>('');
  const [failureMessage, setFailureMessage] = useState<string>('');

  useEffect(() => {
    //console.log(params.randomLink);
    if(params.randomLink){
      if (window.location.pathname.includes("/unsubscribeme/"))
      {
        RandomLinkService.handleUnsubscribemeLink(params.randomLink).then(feedback => {
          if(feedback.status==="Success"){
            console.log("OK:"+feedback.message);
            setMessage(transApi(feedback.message));
          }else{
            console.log("KO:"+feedback.error);
            setFailureMessage(transApi(feedback.error));
          }
        })
      }else{
        RandomLinkService.handleLink(params.randomLink).then(feedback => {
          if(feedback.status==="Success"){
            console.log("OK:"+feedback.message);
            setMessage(transApi(feedback.message));
          }else{
            console.log("KO:"+feedback.error);
            setFailureMessage(transApi(feedback.error));
          }
        })
      }
    }
  }, [params.randomLink]);

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-center-div the-background">
        <div className="card login-card login-part">
          <div className="card-body">
            <h5 className="card-title">Traitement de votre demande en cours ...</h5>
            <div className="form-group">
              {message && <h6>{message}</h6>}
              {failureMessage && <h6 className="text-warning-font" >{failureMessage}</h6>}
            </div>
            <div className="card-action row justify-content-around">
              <Link to='/accueil'><button type="button" className="squama-btn-navbar">
                Accueil
              </button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default SquamaRandomLink;