import {authHeader} from '../helpers/auth-headers';
import ServerRequestAnswer from '../models/server-request-answer';
import SiteService from './site-service';

export default class RandomLinkService {

  static handleLinkOnAuth(basePath: string, theRandomLink: string): Promise<ServerRequestAnswer> {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
    };
    console.log("handleLinkOnAuth("+theRandomLink+")");
    return fetch(SiteService.getAuthenticationUrl() + `${basePath}/${theRandomLink}`, requestOptions)
    .then(response => response.json())
    .then(data => this.isEmpty(data) ? null : data)
    .catch(error => this.handleError(error));
  }

  static handleLinkOnApi(basePath: string, theRandomLink: string): Promise<ServerRequestAnswer> {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
    };
    console.log("handleLinkOnApi("+theRandomLink+")");
    return fetch(SiteService.getApiUrl() + `${basePath}/${theRandomLink}`, requestOptions)
    .then(response => response.json())
    .then(data => this.isEmpty(data) ? null : data)
    .catch(error => this.handleError(error));
  }

  static handleUnsubscribemeLink(theRandomLink: string): Promise<ServerRequestAnswer> {
    return this.handleLinkOnAuth("unsubscribeme", theRandomLink).then(AuthReturn => {
      return this.handleLinkOnApi("unsubscribeme", theRandomLink).then(ApiReturn => {
        if(ApiReturn.status==="Success"){
          return ApiReturn;
        }else{
          return AuthReturn;
        }
      })
    })
  }

  static handleLink(theRandomLink: string): Promise<ServerRequestAnswer> {
    return this.handleLinkOnAuth("randomlink", theRandomLink).then(AuthReturn => {
      return this.handleLinkOnApi("randomlink", theRandomLink).then(ApiReturn => {
        if(ApiReturn.status==="Success"){
          return ApiReturn;
        }else{
          return AuthReturn;
        }
      })
    })
  }

  static isEmpty(data: Object): boolean {
    console.log(data);
    return Object.keys(data).length === 0;
  }
  
  static handleError(error: Error): void {
    console.error(error);
  }
}
