import { FunctionComponent } from 'react';
import SquamaNavbar from '../components/squama-navbar';
import MyChartLogo from '../img/chart_logo-700x700.png';
import MyBellLogo from '../img/bell_logo-700x700.png';
import MyCloudLogo from '../img/cloud_logo-276x276.png';
import MyThreeFishLogoLogo from '../img/three_fish_516x145.png';
import MyRiver from "../img/river-1562x1167.jpg"
import MyTransmitter from "../img/bloc_transmetteur-488x365.png"

const SquamaAccueil: FunctionComponent = () => {
  
  function big_title(text:string){
    return (
      <div className="accueil-main-line accueil-main-column-line-title-bold-big">
        {text}
      </div>
    )
  }
  function title(text:string){
    return (
      <div className="accueil-main-line accueil-main-column-line-title text-bold-font">
        {text}
      </div>
    )
  }
  function centered_text(text:string){
    return (
      <div className="accueil-main-column-line-centered-text">
        {text}
      </div>
    )
  }
  function justify_text(text:string){
    return (
      <div className="accueil-main-column-line-justify-text">
        {text}
      </div>
    )
  }
  function justify_text_with_started_bold(boldtext:string, text:string){
    return (
      <div className="accueil-main-column-line-justify-text">
        <label className="text-bold-font my-label">{boldtext}</label>
        <label className="my-label">&nbsp;{text}</label>
      </div>
    )
  }
  function centered_picto(logo_url:string){
    return (
      <div className="accueil-main-column-line-centered-text">
        <img src={logo_url} className="accueil-alone-picto" alt=""/>
      </div>
    )
  }
  function centered_picture(picture_url:string){
    return (
      <div className="accueil-main-column-line-centered-text">
        <img className="accueil-img" src={picture_url} alt=""/>
      </div>
    )
  }
  function line_logotext(logo_url:string, text:string){
    return (
      <div className="accueil-main-column-line-logotext">
        <div className="accueil-main-column-line-logotext-logo">
        <img src={logo_url} className="accueil-picto" alt=""/>
        </div>
          <div className="accueil-main-column-line-logotext-text">
            {text}
          </div>
      </div>
    )
  }

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-center-div the-background">
        <div className="accueil-main">
          <div className="accueil-main-row">
            {big_title("Des outils d'aide à la gestion des milieux aquatiques")}
          </div>
          <div className="accueil-main-row">
            <div className="accueil-main-column">
              <div className="min-height-20"/>
              {title("Une meilleure réactivité avec")}
              {title("des sondes connectées")}
              <div className="min-height-20"/>
              <div className="min-height-20"/>
              {centered_text("Transmission des données en temps réel")}
              {centered_text("par un système longue portée")}
              <div className="min-height-20"/>
              {line_logotext(MyBellLogo, "Système d'alertes paramètrables.")}
              <div className="min-height-20"/>
              {line_logotext(MyChartLogo, "Visualisation des données en temps réel via une interface utilisateur en ligne.")}
              <div className="min-height-20"/>
              {line_logotext(MyCloudLogo, "Données sauvegardées sur le Cloud, téléchargeables n'importe où et à tout moment.")}
              <div className="min-height-20"/>
              <div className="min-height-20"/>
              <div className="min-height-20"/>
              {centered_picto(MyThreeFishLogoLogo)}
              <div className="min-height-20"/>
              <div className="min-height-20"/>
              <div className="min-height-20"/>
            </div>
            <div className="accueil-main-column">
              {centered_picture(MyTransmitter)}
              <div className="min-height-20"/>
              <div className="min-height-20"/>
              <div className="min-height-20"/>
              {title("Une solution adaptée")}
              {title("à vos besoins")}
              <div className="min-height-20"/>
              {justify_text("Chaque solution est personnalisable en nombre")}
              {justify_text("et type de capteur pour répondre au mieux à vos")}
              {justify_text("besoins.")}
              <div className="min-height-20"/>
            </div>
            <div className="accueil-main-column">
              <div className="min-height-20"/>
              {title("Gagnez en temps")}
              {title("et en efficacité")}
              <div className="min-height-20"/>
              {justify_text_with_started_bold("Optimisation des déplacements", "pour l'entretien")}
              {justify_text("des dispositifs et la récolte des données.")}
              <div className="min-height-20"/>
              {justify_text_with_started_bold("Optimisation du fonctionnement", "des dispositifs")}
              {justify_text("pour le franchissement en période sensible.")}
              <div className="min-height-20"/>
              {justify_text_with_started_bold("Fiabilisation des données", "pour l'analyse et")}
              {justify_text("l'interprétation des tendances et indicateurs.")}
              <div className="min-height-20"/>
              <div className="min-height-20"/>
              {centered_picture(MyRiver)}
              <div className="min-height-20"/>
              <div className="min-height-20"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default SquamaAccueil;