
const DEV_WEBSOCKET_SERVER_URL = "http://localhost:3002/"
const PRODUCTION_WEBSOCKET_SERVER_URL = "/"

const DEV_API_SERVER_URL = "http://192.168.1.101:3002/api/"
const PRODUCTION_API_SERVER_URL = "/api/"

const DEV_AUTHENTICATION_SERVER_URL = "http://192.168.1.101:3001/auth/"
const PRODUCTION_AUTHENTICATION_SERVER_URL = "/auth/"

const DEV_DEVICEAPI_SERVER_URL = "http://192.168.1.101:3003/deviceapi/"
const PRODUCTION_DEVICEAPI_SERVER_URL = "/deviceapi/"

export default class SiteService {
  static getWebsocketUrl():string {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return DEV_WEBSOCKET_SERVER_URL;
    }else{
      return PRODUCTION_WEBSOCKET_SERVER_URL;
    }
  }
  static getApiUrl():string {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return DEV_API_SERVER_URL;
    }else{
      return PRODUCTION_API_SERVER_URL;
    }
  }
  static getAuthenticationUrl():string {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return DEV_AUTHENTICATION_SERVER_URL;
    }else{
      return PRODUCTION_AUTHENTICATION_SERVER_URL;
    }
  }
  static getDeviceapiUrl():string {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return DEV_DEVICEAPI_SERVER_URL;
    }else{
      return PRODUCTION_DEVICEAPI_SERVER_URL;
    }
  }
}