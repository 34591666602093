import { FunctionComponent, useState, useEffect } from 'react';
import Station from '../models/station';
import SensorService from '../services/sensor-service';
import {
  get_all_analog_sensors_from_station,
  get_all_typed_analog_sensors_from_station,
  get_all_water_level_sensors_from_station,
  get_all_text_sensors_from_station,
  return_logo_from_type,
  is_station_assec,
  is_station_pap
  } from '../helpers/station-helper';
import AnalogChart from './AnalogChart';
import WaterlevelChart from './WaterlevelChart';
import TextTable from './TextTable';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import AuthenticationService from '../services/authentication-service';
import {CharWaterlevelValuesAndDescriptors} from '../models/chart-waterlevel-value';
import CharAnalogValues from '../models/chart-analog-values';
import AnalogSensor from '../models/analog-sensor';
import TextValue from '../models/text-value';
import html2canvas from 'html2canvas';

type Props = {
  station: Station,
  fullscreen: boolean
};

const StationCardChart: FunctionComponent<Props> = ({station, fullscreen}) => {
  const displayName = "StationCardChart:";
  const enableDebug = false;
  const current_user_is_god = AuthenticationService.getUserIsGod();
  let current_user_can_access = current_user_is_god;
  if(!current_user_is_god){
    current_user_can_access = station.contacts.find(contact => Number(contact.user_id)===Number(AuthenticationService.getUserID()))?.display_access;
  }
  const [inputTextValue, setInputTextValue] = useState<TextValue[] | undefined>(undefined);
  const [inputAnalogValue, setInputAnalogValue] = useState<CharAnalogValues[] | undefined>(undefined);
  const [inputWaterlevelValuesAndDescriptors, setInputWaterlevelValuesAndDescriptors] = useState<CharWaterlevelValuesAndDescriptors | undefined>(undefined);
  const [period, setPeriod] = useState("week");
  const [sensorSelected, setSensorSelected] = useState<Array<string>>([]);
  const TextValueColumns =
    [
      {
        header: "ID",
        accessorKey: "id",
      },
      {
        header: "Date",
        accessorKey: "date",
      },
      {
        header: "Value",
        accessorKey: "value",
      },
      {
        header: "Sensor ID",
        accessorKey: "sensor_id",
      },
      {
        header: "Modified?",
        accessorKey: "manually_modified",
      },
    ];


    useEffect(() => {
      if(enableDebug) console.log(displayName,"useEffect: station/sensorSelected.length:",sensorSelected)
      if(sensorSelected.length===0){
        let wl_sensors = get_all_water_level_sensors_from_station(station);
        if(is_station_assec(station)&&(wl_sensors)&&(wl_sensors.length===1)){
          let temperature_sensors = get_all_typed_analog_sensors_from_station(station, "temperature");
          if(temperature_sensors && temperature_sensors[0]){
            setSensorSelected(["analog#"+temperature_sensors[0].id.toString()]);
          }else{
            let analog_sensors = get_all_analog_sensors_from_station(station);
            if(analog_sensors && analog_sensors[0]){
              setSensorSelected(["analog#"+analog_sensors[0].id.toString()]);
            }
          }
        }else{
          let liquid_level_sensors = get_all_typed_analog_sensors_from_station(station, "liquid_level");
          if((liquid_level_sensors!==undefined)&&(liquid_level_sensors[0])){
            setSensorSelected(["analog#"+liquid_level_sensors[0].id.toString()]);
          }else{
            setSensorSelected(["onlywaterlevel"]);
          }
        }
      }
    },[station,sensorSelected.length]);

    useEffect(() => {
      if(enableDebug) console.log(displayName,"useEffect|sensorSelected:",sensorSelected)
      if(sensorSelected.length){
        if(sensorSelected.includes("onlywaterlevel")){
          getWaterlevelSensorChartData();
        }else{
          updateInputAnalogOrTextValue();
        }
      }
    },[sensorSelected,period]);

  function convert_period_in_french(the_period:string|undefined){
    if(the_period===undefined){
      return "";
    }
    if(period==="week"){
      return "une semaine";
    }
    if(period==="month"){
      return "un mois";
    }
    if(period==="year"){
      return "une année";
    }
    if(period==="all"){
      return "toujours";
    }
    return "";
  }

  const getSentNumberChartData = (transmitterId:number) => {
    if(enableDebug) console.log(displayName,"getSentNumberChartData("+transmitterId+","+period+")");
    SensorService.getChartgetSentNumber(transmitterId, period).then(r => {
      if(r && r.values_and_date_of_same_scale_and_unity.length>0){
        setInputAnalogValue([r]);
      }
    }).catch(error => console.log(displayName,error));
  }

  const getBatterySensorChartData = (transmitterId:number) => {
    if(enableDebug) console.log(displayName,"getBatterySensorChartData("+transmitterId+","+period+")");
    SensorService.getChartBatterySensor(transmitterId, period).then(r => {
      if(r && r.values_and_date_of_same_scale_and_unity.length>0){
        setInputAnalogValue([r]);
      }else{
        setInputAnalogValue([]);
      }
    }).catch(error => console.log(displayName,error));
  }

  const getAnalogSensorChartData = (sensorId:number) => {
    if(enableDebug) console.log(displayName,"getAnalogSensorChartData("+sensorId+","+period+")");
    if(enableDebug) console.log(displayName,"getAnalogSensorChartData inputAnalogValue:",inputAnalogValue);
    SensorService.getChartAnalogSensor(sensorId, period).then(r => {
      if(r && r.values_and_date_of_same_scale_and_unity.length>0){
        setInputAnalogValue([r]);
      }
    }).catch(error => console.log(displayName,error));
  }

  const getTextSensorData = (sensorId:number) => {
    if(enableDebug) console.log(displayName,"getATextSensorChartData("+sensorId+","+period+")");
    SensorService.getDatasTextSensor(sensorId, period).then(r => {
      if(enableDebug) console.log(displayName, "getTextSensorData got:",r);
      if(r && r.length>0){
        setInputTextValue(r);
      }
    }).catch(error => console.log(displayName,error));
  }

  const getWaterlevelAnalogSensorChartData = (sensorId:number) => {
    if(enableDebug) console.log(displayName,"getAnalogSensorChartData("+sensorId+","+period+")");
    SensorService.getChartWaterlevelAnalogSensor(sensorId, period).then(r => {
      if(r && r.values_and_date_of_same_scale_and_unity.length>0){
        setInputAnalogValue([r]);
      }
    }).catch(error => console.log(displayName,error));
  }

  const getWaterlevelSensorChartData = () => {
    SensorService.getChartWaterlevelSensorPerStation(station.id, period).then(r => {
        setInputWaterlevelValuesAndDescriptors(r);
        setInputAnalogValue(undefined);
        setInputTextValue(undefined);
      }).catch(error => console.log(displayName,error));
  }

  const updateSensorSelected = (analog_element:string) => {
    if(enableDebug) console.log(displayName,"previous sensorSelected:",sensorSelected);
    if(enableDebug) console.log(displayName,"updateSensorSelected(",analog_element,")");
    setSensorSelected([analog_element]);
    /*
    //Support multiple selections (TODO retrieve correctly multiples selections)
    if(analog_element === "onlywaterlevel"){
      setSensorSelected(["onlywaterlevel"]);
    }else if (sensorSelected.includes("onlywaterlevel")){
      setSensorSelected([analog_element]);
    }else{
      if (! sensorSelected.includes(analog_element)){
        setSensorSelected(sensorSelected.concat([analog_element]));
      }else{
        setSensorSelected(sensorSelected.filter(e => e !== analog_element));
      }
    }
    */
  }

  const updateInputAnalogOrTextValue = () => {
    if(!current_user_can_access){return;}
    if(enableDebug) console.log(displayName,"updateInputAnalogOrTextValue sensorSelected:",sensorSelected);
    if(enableDebug) console.log(displayName,"sensorSelected:",sensorSelected);
    for (let one_sensor of sensorSelected){
      const selected_id = Number(one_sensor.toString().split("#")[1]);
      if(enableDebug) console.log(displayName,"one_sensor:",one_sensor);
      if(enableDebug) console.log(displayName,"selected_id:",selected_id);
      if(selected_id<=0){return;}
      setInputWaterlevelValuesAndDescriptors(undefined);
      setInputAnalogValue(undefined);
      setInputTextValue(undefined);
      if(one_sensor.toString().includes("sentnb")){
        getSentNumberChartData(selected_id);
      }else if(one_sensor.toString().includes("battery")){
        getBatterySensorChartData(selected_id);
      }else if(one_sensor.toString().includes("wl-analog")){
        getWaterlevelAnalogSensorChartData(selected_id);
      }else if(one_sensor.toString().includes("analog")){
        getAnalogSensorChartData(selected_id);
      }else if(one_sensor.toString().includes("text")){
        getTextSensorData(selected_id);
      }
    }
  }

  function display_one_analog_sensor_button(sensor: AnalogSensor, display_only_god_element:Boolean) {
    const only_regular_user_type = ["temperature","liquid_level"];
    if(display_only_god_element && only_regular_user_type.includes(sensor.type)){
      return;
    }
    if(display_only_god_element && ! current_user_is_god){
      return;
    }
    if(!display_only_god_element && !only_regular_user_type.includes(sensor.type)){
      return;
    }
    return(
      <button key={"analog-bp-"+sensor.id} className="panel-chart-flex-sensor-type-each btn-sensor-type" onClick={()=>updateSensorSelected("analog#"+sensor.id)}>
        <div className="panel-chart-flex-sensor-type-each-one-name">{(sensor.name)?(sensor.name):(sensor.type)}</div>
        {return_logo_from_type(sensor.type+isLogoSelected("analog#"+sensor.id), "panel-chart-sensor-type-logo")}
      </button>
    )
  }

  function downloadURL(imgData:string){
    var a = document.createElement('a');
    a.href = imgData.replace("image/png", "image/octet-stream");
    a.download = 'graph.png';
    a.click();
  }

  function saveAsImage(){
    var input = document.getElementById('canvas');
    (input) && html2canvas(input).then((canvas) =>{
      let imgData = canvas.toDataURL('image/png').replace("image/png", "image/octet-stream");
       downloadURL(imgData);
    });
  }

  function display_all_sensors(station: Station) {
    let analog_sensors = get_all_analog_sensors_from_station(station);
    let wl_sensors = get_all_water_level_sensors_from_station(station);
    let text_sensors = get_all_text_sensors_from_station(station);
    return (
      <div className="panel-chart-flex-sensor-type">
        {
          (analog_sensors!==undefined) && (analog_sensors.length > 0) && analog_sensors.map(sensor =>display_one_analog_sensor_button(sensor, false))
        }
        {
          (wl_sensors!==undefined) && (wl_sensors.length > 0) && <button key="wl-bp" className="panel-chart-flex-sensor-type-each btn-sensor-type" onClick={()=>updateSensorSelected("onlywaterlevel")}>
            <div className="panel-chart-flex-sensor-type-each-one-name">Niveau d'eau</div>
            {return_logo_from_type("water_level"+isLogoSelected("onlywaterlevel"), "panel-chart-sensor-type-logo")}
          </button>
        }
        {
          (current_user_is_god) && (station.transmitters) && station.transmitters.map(transmitter => {
            return(
              <button key={"battery-bp-"+transmitter.id} className="panel-chart-flex-sensor-type-each btn-sensor-type" onClick={()=>updateSensorSelected("battery#"+transmitter.id)}>
                <div className="panel-chart-flex-sensor-type-each-one-name">{transmitter.mac}</div>
                {return_logo_from_type("battery"+isLogoSelected("battery#"+transmitter.id), "panel-chart-sensor-type-logo")}
              </button>
            )
          })
        }
        {
          (current_user_is_god) && (station.transmitters) && station.transmitters.map(transmitter => {
            return(
              <button key={"sentnb-bp-"+transmitter.id} className="panel-chart-flex-sensor-type-each btn-sensor-type" onClick={()=>updateSensorSelected("sentnb#"+transmitter.id)}>
                <div className="panel-chart-flex-sensor-type-each-one-name">{transmitter.mac}</div>
                {return_logo_from_type("transmitter"+isLogoSelected("sentnb#"+transmitter.id), "panel-chart-sensor-type-logo")}
              </button>
            )
          })
        }
        {
          (current_user_is_god) && (wl_sensors) && wl_sensors.map(wl => {
            return(
              <button key={"wl-analog-bp-"+wl.id} className="panel-chart-flex-sensor-type-each btn-sensor-type" onClick={()=>updateSensorSelected("wl-analog#"+wl.id)}>
                <div className="panel-chart-flex-sensor-type-each-one-name">WLAnalog-{wl.id}</div>
                {return_logo_from_type("water_level"+isLogoSelected("wl-analog#"+wl.id), "panel-chart-sensor-type-logo")}
              </button>
            )
          })
        }
        {
          (current_user_is_god) && (text_sensors) && text_sensors.map(textSensor => {
            return(
              <button key={"text-sensor-bp-"+textSensor.id} className="panel-chart-flex-sensor-type-each btn-sensor-type" onClick={()=>updateSensorSelected("text-sensor#"+textSensor.id)}>
                <div className="panel-chart-flex-sensor-type-each-one-name">{(textSensor.name)?(textSensor.name):(textSensor.type)}</div>
                {return_logo_from_type(textSensor.type+isLogoSelected("text-sensor#"+textSensor.id), "panel-chart-sensor-type-logo")}
              </button>
            )
          })
        }
        {
          (analog_sensors!==undefined) && analog_sensors.map(sensor =>display_one_analog_sensor_button(sensor, true))
        }
      </div>
    )
  }

  function isLogoSelected(trigger: string){
    for(let one_element of sensorSelected){
      if(one_element===trigger){
        return "-selected"
      }
    }
    return ""
  }

  function isBtnSelected(current: string | undefined, trigger: string){
    if ( (current) && (current === trigger) ){
      return "btn-selected"
    }
    return ""
  }

  function changePeriod(period: string){
    if(!current_user_can_access){return;}
    setPeriod(period);
  }

  function display_sensor_time(station: Station) {
    return (
      <div className="panel-chart-flex-sensor-time">
        <button className={"btn-sensor-time "+isBtnSelected(period, "week")} onClick={()=> changePeriod("week")}>Semaine</button>
        <button className={"btn-sensor-time "+isBtnSelected(period, "month")} onClick={()=> changePeriod("month")}>Mois</button>
        <button className={"btn-sensor-time "+isBtnSelected(period, "year")} onClick={()=> changePeriod("year")}>Année</button>
        <button className={"btn-sensor-time "+isBtnSelected(period, "all")} onClick={()=> changePeriod("all")}>Tous</button>
        <button key="download-bp" className="panel-chart-flex-sensor-type-each btn-sensor-type" onClick={()=>saveAsImage()}>
          {return_logo_from_type("download", "panel-chart-sensor-type-logo")}
        </button>
      </div>
    )
  }

  function display_chart(station : Station) {
    if(enableDebug){
      console.log(displayName, "inputTextValue:",inputTextValue);
      console.log(displayName, "inputAnalogValue:",inputAnalogValue);
      console.log(displayName, "inputWaterlevelValuesAndDescriptors:",inputWaterlevelValuesAndDescriptors);
    }
    return (
      <div id="canvas" className="panel-chart-flex-right-part">
          {
            ((inputTextValue !== undefined) && (inputTextValue !== null) && (inputTextValue.length > 0)) ? (
              <ParentSize>
                { ({ width, height }) => <TextTable orderByDesc={true} orderBy="date" searchedText={["value"]} allValues={inputTextValue} valuesColumns={TextValueColumns}/>}
              </ParentSize>
            ):undefined
          }
          {
            ((inputAnalogValue !== undefined) && (inputAnalogValue !== null) && (inputAnalogValue.length > 0)) ? (
              <ParentSize>
                { ({ width, height }) => <AnalogChart width={width} height={height} allValues={inputAnalogValue}/>}
              </ParentSize>
            ):undefined
          }
          {
            ((inputWaterlevelValuesAndDescriptors !== undefined) && (inputWaterlevelValuesAndDescriptors !== null) && (inputWaterlevelValuesAndDescriptors.charwaterlevelvalues !== undefined) && (inputWaterlevelValuesAndDescriptors.charwaterlevelvalues.length > 0)) ? (
              <ParentSize>
                { ({ width, height }) => <WaterlevelChart width={width} height={height} allValuesAndDescriptors={inputWaterlevelValuesAndDescriptors} is_pap={is_station_pap(station)}/>}
              </ParentSize>
            ):undefined
          }
          {
            ((inputTextValue === undefined)&&(inputAnalogValue===undefined)&&(inputWaterlevelValuesAndDescriptors===undefined))?(
              <div className='panel-station-default-setup-chart-center-text'>Pas de données depuis {convert_period_in_french(period)}</div>
            ):undefined
          }
      </div>
    )
  }

  function display_forbidden_chart(station : Station) {
    return (
      <div className="panel-chart-flex-right-part">
        {
          <div className="panel-chart-forbidden-access">
            {(!current_user_can_access) ? (<div className="text-warning-font">Vous n'avez pas accès à cette section</div>):(<div className="text-warning-font">&nbsp;</div>)}
          </div>
        }
      </div>
    )
  }
  return (
    <div className={"panel-chart-main-flex"+((fullscreen)?(" panel-station-default-fullscreen"):(" panel-station-default-not-fullscreen"))}>
      {display_all_sensors(station)}
      <div className="panel-chart-flex-right-part">
      {display_sensor_time(station)}
      {(current_user_can_access) ? display_chart(station):(undefined)}
      {(current_user_can_access) ? (undefined):display_forbidden_chart(station)}
      </div>
    </div>
  );
}

export default StationCardChart;
