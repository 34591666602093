import {adminAuthHeader} from '../helpers/auth-headers';
import SiteService from './site-service';

export default class EmailService {

  static sendEmailAPI(theSubject: string, theMessage:string, contactEmail:string): Promise<[]> {
    console.log("sendEmailAPI");
    return fetch(SiteService.getApiUrl()+`sendemail`, {
      method: 'POST',
      body: JSON.stringify({
        "subject":theSubject,
        "message":theMessage,
        "sender_email":contactEmail
      }),
      headers: {
        'Content-Type': 'application/json',
        'verif': adminAuthHeader()
      }
    })
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static isEmpty(data: Object): boolean {
    return Object.keys(data).length === 0;
  }
  
  static handleError(error: Error): void {
    console.error(error);
  }
}
