import { FunctionComponent } from 'react';
import SquamaNavbar from '../components/squama-navbar';
import ConfidentialPolitics1 from '../img/confidentialpolitics-1.png'
import ConfidentialPolitics2 from '../img/confidentialpolitics-2.png'
import ConfidentialPolitics3 from '../img/confidentialpolitics-3.png'
import ConfidentialPolitics4 from '../img/confidentialpolitics-4.png'
import LegalMetions1 from '../img/legalmentions-1.png'


const SquamaLegalMentionsConfidentialPolitics: FunctionComponent = () => {

  let type:string = "legalmentions";
  if(window.location.pathname==="/legalmentions"){
    type = "legalmentions";
  }else if(window.location.pathname==="/confidentialpolitics"){
    type = "confidentialpolitics";
  }

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-center-div the-background solution-plus-main">
        {(type === "legalmentions") && (
          <div className="solution-plus-main-column">
            <img src={LegalMetions1} className="image-mentions" alt=""/>
          </div>)
        }
        {(type === "confidentialpolitics") && (
          <div className="solution-plus-main-column">
            <img src={ConfidentialPolitics1} className="image-mentions" alt=""/>
            <img src={ConfidentialPolitics2} className="image-mentions" alt=""/>
            <img src={ConfidentialPolitics3} className="image-mentions" alt=""/>
            <img src={ConfidentialPolitics4} className="image-mentions" alt=""/>
          </div>)
        }
      </div>
    </div>
  );
}
  
export default SquamaLegalMentionsConfidentialPolitics;