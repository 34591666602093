export default class Defaut {
    // 1. Typage des propiétés d'une sonde de niveau d'eau.
    id: number;
    company_id: number;
    station_id: number;
    transmitter_id: number;
    analog_sensor_id: number;
    water_level_sensor_id: number;
    text_sensor_id: number;
    type: string;
    alarm_level_low: number;
    warning_level_low: number;
    warning_level_high: number;
    alarm_level_high: number;
    warning_days: number;
    alarm_days: number;
    // 2. Définition des valeurs par défaut des propriétés d'une sonde de niveau d'eau.
    constructor(
     id: number = 0,
     company_id: number = 0,
     station_id: number = 0,
     transmitter_id: number = 0,
     analog_sensor_id: number = 0,
     water_level_sensor_id: number = 0,
     text_sensor_id: number = 0,
     type: string = "",
     alarm_level_low: number = 0,
     warning_level_low: number = 0,
     warning_level_high: number = 0,
     alarm_level_high: number = 0,
     warning_days: number = 0,
     alarm_days: number = 0
    ) {
     // 3. Initialisation des propriétés d'une sonde de niveau d'eau.
     this.id = id;
     this.company_id = company_id;
     this.station_id = station_id;
     this.transmitter_id = transmitter_id;
     this.analog_sensor_id = analog_sensor_id;
     this.water_level_sensor_id = water_level_sensor_id;
     this.text_sensor_id = text_sensor_id;
     this.type = type;
     this.alarm_level_low = alarm_level_low;
     this.warning_level_low = warning_level_low;
     this.warning_level_high = warning_level_high;
     this.alarm_level_high = alarm_level_high;
     this.warning_days = warning_days;
     this.alarm_days = alarm_days;
    }
}