import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import AuthenticationService from '../services/authentication-service';
import SquamaNavbar from '../components/squama-navbar';

type ModalItems = {
  username: string;
}
const ForgotLogin: FunctionComponent = () => {

  const history = useNavigate();
  const { register, handleSubmit, formState: {errors} } = useForm<ModalItems>();

  const [message, setMessage] = useState<string>('Réinitialiser mon mot de passe');

  const onHookFormSubmit = (data: ModalItems) => {
    console.log("data", data);
    setMessage('👉 un nouveau mot de passe va vous êtes envoyé si vous existez sur la plateforme');
    AuthenticationService.resetUserPassword(data.username)
    window.setTimeout(() => {
      history('/');
   }, 3000)
  }

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <form className="main-center-div the-background" onSubmit={handleSubmit(onHookFormSubmit)}>
            <div className="card login-card login-part">
              <div className="card-body">
                    {message && <h5 className="card-title">{message}</h5>}
                    <div className="form-group">
                      <label htmlFor="username"><b>Identifiant : </b></label>
                      <input id="username" type="text" className="form-control" {...register("username",{ required: true, pattern: /^\S+@\S+$/i })}></input>
                      {errors.username && <div className="card-text red-font accent-1">Mauvais format d'email</div>}
                    </div>
                    <div className="card-action justify-content-around">
                      <button type="submit" className="btn-station-card btn-login">Réinitialiser</button>
                    </div>
              </div>
          </div>
      </form>
    </div>
  );
};
 
export default ForgotLogin;