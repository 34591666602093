import React, { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FaultTrigger from '../models/fault-trigger';
import Station from '../models/station';
import Contact from '../models/contact';
import StationService from '../services/station-service';
import {
        find_water_level_sensor_from_sensor_id,
        find_analog_sensor_from_sensor_id,
        is_station_pap,
        is_there_sensor_in_array,
        return_logo_from_type,
        return_color_for_water_level_from_bool,
        return_unity_from_type,
        get_all_analog_sensors_from_station,
        get_all_upstream_water_level_sensors_from_station,
        get_all_downstream_water_level_sensors_from_station,
        get_all_general_water_level_sensors_from_station,
        return_type_test,
        is_station_papang,
        is_station_fully_loaded,
        is_station_can_enable_pkd,
        station_get_pkd_fault_trigger
      } from '../helpers/station-helper';
import WaterLevelSensor from '../models/water-level-sensor';
import AnalogSensor from '../models/analog-sensor';
import ReactTooltip from 'react-tooltip'
import NumericInput from "react-numeric-input2";
import AuthenticationService from '../services/authentication-service';

type Props = {
  station: Station,
  fullscreen: boolean
};

type Item = {
  name: string,
  value?: any,
  initial_value?: any,
  error?: string,
  isValid?: boolean,
  isDisabled?: boolean,
  type?: string
}

const StationCardAlarm: FunctionComponent<Props> = ({station, fullscreen}) => {
  const displayName = "StationCardAlarm:";
  const local_debug = false;
  const local_very_debug = false;
  local_very_debug && console.log(displayName, "station:",station)
  const stationFullyLoaded = is_station_fully_loaded(station);
  const pkd_enabled = is_station_can_enable_pkd(station);
  const current_user_is_god = AuthenticationService.getUserIsGod();

  function does_user_can_access_modify():boolean{
    if(current_user_is_god){
      return true;
    }
    if(station && station.contacts){
      const returned = station.contacts.find(contact => Number(contact.user_id)===Number(AuthenticationService.getUserID()));
      if(returned){
        return returned.alarm_access;
      }
    }
  return false;
  }

  function does_user_have_technical_access():boolean{
    if(current_user_is_god){
      return true;
    }
    const myUserId = Number(AuthenticationService.getUserID());
    if(station && station.accesses){
      const my_access = station.accesses.find(access => Number(access.user_id)===Number(myUserId));
      if((my_access)&&(my_access!=null)){
        return my_access.general_access_technical;
      }
    }
    return false;
  }
  const current_user_can_modify = does_user_can_access_modify();
  const current_user_has_technical_access = does_user_have_technical_access();
  const history = useNavigate();

  const [updating, setUpdating] = useState<boolean>(false);
  const [items, setItems] = useState<Item[]>([]);
  const [isInMigration, setIsInMigration] = useState(false);
  const [allowWaterLevelWarning, setAllowWaterLevelWarning] = useState(false);
  const [allowWaterLevelAlarm, setAllowWaterLevelAlarm] = useState(false);
  const [allowWaterLevelEmbacleWarning, setAllowWaterLevelEmbacleWarning] = useState(false);
  const [allowWaterLevelEmbacleAlarm, setAllowWaterLevelEmbacleAlarm] = useState(false);
  const [enablePKD, setEnablePKD] = useState(false);

  let general_water_level_sensors = get_all_general_water_level_sensors_from_station(station);
  let upstream_water_level_sensors = get_all_upstream_water_level_sensors_from_station(station);
  let downstream_water_level_sensors = get_all_downstream_water_level_sensors_from_station(station);
  let analog_sensors = get_all_analog_sensors_from_station(station);

  function handleFlipFlopOnClick(id: string) {
    //console.log(displayName, "handleFlipFlopOnClick "+id);
    if(!current_user_can_modify){return}
    const theItems = [...items]
    theItems.find(one => {
      if(one.name === id){
        if(one.value){
          one.value = 0;
        }else{
          one.value = 1;
        }
      }
      return null;
    });
    setItems(theItems);
    if(id === "station-in_migration_forced"){
      updateIsInMigration(false);
    }
    //console.log(displayName, id+":"+getItemFromName(id).value)
  }

  function updateStartEndMigrationDate(theModifiedItem: Item, theItems: Array<Item>, modif:string):void{
    const local_debug = false;
    if(modif.includes("migration_date_start")){
      let p_end = getItemFromName("station-migration_date_end");
      if(p_end){
        local_debug && console.log(displayName, "retrieve station-migration_date_end:"+p_end.value);
        let p_start_date = new Date(theModifiedItem.value);
        let p_end_date = new Date(p_end.value.toString());
        if((p_start_date > p_end_date)||(p_end.value === "None")){
          console.log(displayName, "Cannot chose a starting date after ending date !=>Update ending date");
          //theModifiedItem.value = p_end.value;
          p_end.value = theModifiedItem.value;
        }
      }
    }else if(modif.includes("migration_date_end")){
      let p_start = getItemFromName("station-migration_date_start");
      if(p_start){
        local_debug && console.log(displayName, "retrieve station-migration_date_start:"+p_start.value);
        let p_start_date = new Date(p_start.value.toString());
        let p_end_date = new Date(theModifiedItem.value);
        if(p_start.value === "None"){
          p_start.value = theModifiedItem.value;
        }
        if(p_start_date > p_end_date){
          console.log(displayName, "Cannot chose a starting date after ending date !");
          theModifiedItem.value = p_start.value;
        }
      }
    }
  }

  const handleMigrationDateOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const local_debug = false;
    local_debug && console.log(displayName, "handleMigrationDateOnChange");
    local_debug && console.log(displayName, "id:"+e.target.id);
    local_debug && console.log(displayName, "value:"+e.target.value);
    const theItems = [...items];
    theItems.find(one => {
      if(one.name === e.target.id){
        local_debug && console.log(displayName, "found "+one.name+", old value:"+one.value);
        one.value = e.target.value;
        updateStartEndMigrationDate(one, theItems, e.target.id);
      }
      return null;
    })
    setItems(theItems);
  }

  function checkInMigrationPeriod(){
    const local_debug = false;
    let start_date = station.migration_date_start;//getItemFromName("station-migration_date_start").value;
    let end_date = station.migration_date_end;//getItemFromName("station-migration_date_end").value;
    if ((start_date!=null) && (end_date!=null)){
      let now = new Date()
      if(local_debug){
        console.log(displayName, "start_date:"+start_date);
        console.log(displayName, "now:"+now);
        console.log(displayName, "end_date:"+end_date);
      }
      let p_start_date = new Date(start_date);
      let p_end_date = new Date(end_date);
      if(local_debug){
        console.log(displayName, "p_start_date:"+p_start_date);
        console.log(displayName, "p_end_date:"+p_end_date);
      }
      if((p_start_date <= now) && (now <= p_end_date)){
        local_debug && console.log(displayName, "Currently in migration");
        return true;
      }
    }
    local_debug && console.log(displayName, "Currently NOT in migration");
    return false;
  }

  function updateIsInMigration(forced_before_set_items: boolean){
    if(is_station_pap(station)){
      //console.log(displayName, "station-in_migration_forced:"+getItemFromName("station-in_migration_forced").value)
      if(forced_before_set_items || (getItemFromName("station-in_migration_forced").value === 1)){
        setIsInMigration(true);
      }else{
        if(checkInMigrationPeriod()){
          setIsInMigration(true);
        }else{
          setIsInMigration(false);
        }
      }
    }else{
      setIsInMigration(false);
    }
  }

  function getItemFromName(name: string):Item {
    const default_returned: Item = {name:"null", value:"null", error:"unknown item:"+name};
    if (items !== undefined){
      let returned = items.find(one => one.name === name);
      if(returned !== undefined){
        return returned;
      }
    }
    return default_returned;
  }
/*
  function displayInputFields() {
    console.log(displayName, "########")
    console.log(displayName, "Into displayInputFields")
    items.map(item => {
      console.log(displayName, "item=> name:"+item.name+";value:"+item.value)
      return null;
    })
    //console.log(displayName, getItemFromName("station_name").value)
    console.log(displayName, "station-in_migration_forced:"+getItemFromName("station-in_migration_forced").value)
    console.log(displayName, "########")
  }
*/
  useEffect(() => {

    function createItem(the_name: string, initial_value: any, type: any):Item {
      return {
        name:the_name,
        value: initial_value,
        initial_value: initial_value,
        isValid: true,
        isDisabled: false,
        type: type
      }
    }

    function setAnalogSensorItems(sensors: Array<AnalogSensor>|undefined):void {
      if (sensors === undefined){return}
      sensors && sensors.map(sensor => {
        newItemArray.push(createItem("analog-alarm_level_low-"+sensor.id, sensor.alarm_level_low,sensor.type));
        newItemArray.push(createItem("analog-alarm_level_high-"+sensor.id, sensor.alarm_level_high,sensor.type));
        newItemArray.push(createItem("analog-warning_level_low-"+sensor.id, sensor.warning_level_low,sensor.type));
        newItemArray.push(createItem("analog-warning_level_high-"+sensor.id, sensor.warning_level_high,sensor.type));
        return null;
      });
    }

    function setWaterLevelSensorItems(sensors: Array<WaterLevelSensor>|undefined):void {
      if (sensors === undefined){return}
      sensors && sensors.map(sensor => {
        if(is_station_pap(station) && (sensor.type === "downstream") && (sensor.depth === 0)){
          newItemArray.push(createItem("water_level-alarm_level-"+sensor.id, -1,sensor.type));
          newItemArray.push(createItem("water_level-warning_level-"+sensor.id, -1,sensor.type));
          newItemArray.push(createItem("water_level-trigger_under_water-"+sensor.id, sensor.trigger_under_water,sensor.type));
        }else{
          newItemArray.push(createItem("water_level-alarm_level-"+sensor.id, sensor.alarm_level,sensor.type));
          newItemArray.push(createItem("water_level-warning_level-"+sensor.id, sensor.warning_level,sensor.type));
          newItemArray.push(createItem("water_level-trigger_under_water-"+sensor.id, sensor.trigger_under_water,sensor.type));
        }
        return null;
      });
    }

    function setDateFromTextDate(input: string, default_input: string){
      if(input){
        return input;
      }
      return default_input;
    }

    // Should not ever set state during rendering, so do this in useEffect instead.
    local_very_debug && console.log(displayName, "useEffect:start")
    let newItemArray: Array<Item> = [];
    newItemArray.push(createItem("station-name", station.name, null));
    newItemArray.push(createItem("station-migration_date_start", setDateFromTextDate(station.migration_date_start, "2021-01-01") , null));
    newItemArray.push(createItem("station-migration_date_end", setDateFromTextDate(station.migration_date_end, "2021-12-31") , null));
    newItemArray.push(createItem("station-in_migration_forced", (station.in_migration_forced)?(1):(0), null));
    const pkd_fault_trigger = station_get_pkd_fault_trigger(station);
    if(pkd_enabled && pkd_fault_trigger){
      setEnablePKD(true);
      newItemArray.push(createItem("station-alarm_pkd_days", pkd_fault_trigger.alarm_days, null));
      newItemArray.push(createItem("station-alarm_pkd_temperature", pkd_fault_trigger.alarm_level_high, null));
    }else{
      newItemArray.push(createItem("station-alarm_pkd_days", 15, null));
      newItemArray.push(createItem("station-alarm_pkd_temperature", 15, null));
    }

    station.contacts && station.contacts.map(contact => {
      newItemArray.push(createItem("contact-alarm-"+contact.user_id, contact.alarm, null));
      newItemArray.push(createItem("contact-warning-"+contact.user_id, contact.warning, null));
      newItemArray.push(createItem("contact-alimentation_alarm-"+contact.user_id, contact.alimentation_alarm, null));
      newItemArray.push(createItem("contact-alimentation_warning-"+contact.user_id, contact.alimentation_warning, null));
      newItemArray.push(createItem("contact-embacle_alarm-"+contact.user_id, contact.embacle_alarm, null));
      newItemArray.push(createItem("contact-embacle_warning-"+contact.user_id, contact.embacle_warning, null));
      newItemArray.push(createItem("contact-alarm_pkd-"+contact.user_id, contact.alarm_pkd, null));
      return null;
    });
    setAnalogSensorItems(analog_sensors);
    setWaterLevelSensorItems(general_water_level_sensors);
    setWaterLevelSensorItems(upstream_water_level_sensors);
    setWaterLevelSensorItems(downstream_water_level_sensors);
    setItems([...items,...newItemArray])
    updateIsInMigration((station.in_migration_forced)?(true):(false));
    local_very_debug && console.log(displayName, "useEffect:stop")
  }, [station]);
  //}, [station,items,analog_sensors,general_water_level_sensors,upstream_water_level_sensors,downstream_water_level_sensors]);

  function checkAtLeastOneWaterLevelOtherThanAucunAndSetUserSettings(theItems: Array<Item>, param_allowWaterLevelAlarm: boolean, param_allowWaterLevelWarning: boolean, param_allowWaterLevelEmbacleAlarm: boolean, param_allowWaterLevelEmbacleWarning: boolean){
    if(theItems){
      if(!param_allowWaterLevelAlarm){
        theItems.forEach(oneItem=>{
          if(oneItem.name.includes("contact-alimentation_alarm")){
            oneItem.value=0;
          }
        });
      }
      if(!param_allowWaterLevelWarning){
        theItems.forEach(oneItem=>{
          if(oneItem.name.includes("contact-alimentation_warning")){
            oneItem.value=0;
          }
        });
      }
      if(!param_allowWaterLevelEmbacleAlarm){
        theItems.forEach(oneItem=>{
          if(oneItem.name.includes("contact-embacle_alarm")){
            oneItem.value=0;
          }
        });
      }
      if(!param_allowWaterLevelEmbacleWarning){
        theItems.forEach(oneItem=>{
          if(oneItem.name.includes("contact-embacle_warning")){
            oneItem.value=0;
          }
        });
      }
    }
  }

  function checkAtLeastOneWaterLevelOtherThanAucun(theItems: Array<Item>){
    if(theItems){
      let param_allowWaterLevelWarning = false
      let param_allowWaterLevelAlarm = false
      let param_allowWaterLevelEmbacleWarning = false
      let param_allowWaterLevelEmbacleAlarm = false
      setAllowWaterLevelWarning(false);
      setAllowWaterLevelAlarm(false);
      setAllowWaterLevelEmbacleWarning(false);
      setAllowWaterLevelEmbacleAlarm(false);
      theItems.forEach(oneItem=>{
        if(oneItem.name.includes("water_level")){
          if((oneItem.type)&&(oneItem.type.includes("downstream"))){
            if(oneItem.name.includes("warning")&&(oneItem.value>=0)){
              //console.log(displayName, "Embacle WARN ENABLED=>"+oneItem.name+":"+oneItem.value)
              setAllowWaterLevelEmbacleWarning(true);
              param_allowWaterLevelEmbacleWarning = true;
            }
            if(oneItem.name.includes("alarm")&&(oneItem.value>=0)){
              //console.log(displayName, "Embacle ALARM ENABLED=>"+oneItem.name+":"+oneItem.value)
              setAllowWaterLevelEmbacleAlarm(true);
              param_allowWaterLevelEmbacleAlarm = true;
            }
          }else{
            if(oneItem.name.includes("warning")&&(oneItem.value>=0)){
              //console.log(displayName, "WARN ENABLED=>"+oneItem.name+":"+oneItem.value)
              setAllowWaterLevelWarning(true);
              param_allowWaterLevelWarning = true;
            }
            if(oneItem.name.includes("alarm")&&(oneItem.value>=0)){
              //console.log(displayName, "ALARM ENABLED=>"+oneItem.name+":"+oneItem.value)
              setAllowWaterLevelAlarm(true);
              param_allowWaterLevelAlarm = true;
            }
          }
        }
      });
      checkAtLeastOneWaterLevelOtherThanAucunAndSetUserSettings(items, param_allowWaterLevelAlarm, param_allowWaterLevelWarning, param_allowWaterLevelEmbacleAlarm, param_allowWaterLevelEmbacleWarning);
    }
  }

  useEffect(() => {
    if(items.length){
      checkAtLeastOneWaterLevelOtherThanAucun(items);
    }
  }, [items]);

  const updateStation = () => {
    //console.log(displayName, "station-card-alarm:updateStation fullscreen:"+fullscreen);
    StationService.updateStation(station).then(() => {
      history(window.location.pathname);
      window.location.reload();
    });
  }

  const handleOnChangeWaterLevelSensor = (input: HTMLInputElement, value: number | null) => {
    //console.log(displayName, e)
    //console.log(displayName, e.target)
    const theItems = [...items];
    //console.log(displayName, "Into handleInputChangeWaterLevelSensor=>("+fieldName+"):"+fieldValue)
    //const newItem: Item = { name: fieldName, value: fieldValue };
    theItems.find(one => {
      if(one.name === input.id){
        one.value = value;
        updateMaxWithMinValueWaterLevelSensor(one, theItems, input.id)
      }
      return null;
    })
    setItems(theItems);
  }

  const handleOnChangeAnalogSensor = (input: HTMLInputElement, value: number | null) => {
    //console.log(displayName, "handleOnChangeAnalogSensor update "+input.id+":"+value);
    if(value !== null){
      const theItems = [...items]
      theItems.find(one => {
        if(one.name === input.id){
          //one.value = fieldValue.replace(".",',');
          //one.value = fieldValue.replace(/^[0-9\b]+$/g,'');
          one.value = value;
          if(one.value === ""){
            one.value = 0;
          }
          updateMaxWithMinValueAnalogSensor(one, theItems, input.id);
        }
        return null;
      })
      setItems(theItems);
    }
  }

  function findItemByName(theItems: Array<Item>, name:string):Item | undefined{
    //console.log(displayName, "Try to find :"+name);
    let returned = theItems.filter(theItem => (theItem.name === name));
    return returned[0];
  }

  function ModifiedMustBeHigherOrEqual(theModifiedItem: Item | undefined, theOtherItem: Item | undefined):void{
    if (theModifiedItem !== undefined && theOtherItem !== undefined) {
      //console.log(displayName, "Before Modified MustBeHigherOrEqual name:"+theModifiedItem.name+"="+theModifiedItem.value+"; other name:"+theOtherItem.name+"="+theOtherItem.value)
      //console.log(displayName, "Result:"+(theModifiedItem.value < theOtherItem.value))
      if(theModifiedItem.value < theOtherItem.value){
        theOtherItem.value = theModifiedItem.value;
      }
      //console.log(displayName, "After Modified MustBeHigherOrEqual name:"+theModifiedItem.name+"="+theModifiedItem.value+"; other name:"+theOtherItem.name+"="+theOtherItem.value)
    }
  }

  function ModifiedMustBeLowerOrEqual(theModifiedItem: Item | undefined, theOtherItem: Item | undefined):void{
    if (theModifiedItem !== undefined && theOtherItem !== undefined) {
      //console.log(displayName, "Before Modified ModifiedMustBeLowerOrEqual name:"+theModifiedItem.name+"="+theModifiedItem.value+"; other name:"+theOtherItem.name+"="+theOtherItem.value)
      if(theModifiedItem.value > theOtherItem.value){
        theOtherItem.value = theModifiedItem.value;
      }
      //console.log(displayName, "After Modified ModifiedMustBeLowerOrEqual name:"+theModifiedItem.name+"="+theModifiedItem.value+"; other name:"+theOtherItem.name+"="+theOtherItem.value)
    }
  }

  function updateMaxWithMinValueAnalogSensor(theModifiedItem: Item, theItems: Array<Item>, modif:string):void{
    //console.log(displayName, "updateMaxWithMinValueAnalogSensor start")
    if(modif.includes("analog-alarm_level_low-")){
      let splited_name = modif.split("-")
      let sensor_id = splited_name[splited_name.length-1];
      let corresponding_warning_level_low = findItemByName(theItems, "analog-warning_level_low-"+sensor_id);
      let corresponding_warning_level_high = findItemByName(theItems, "analog-warning_level_high-"+sensor_id);
      let corresponding_alarm_level_high = findItemByName(theItems, "analog-alarm_level_high-"+sensor_id);
      ModifiedMustBeLowerOrEqual(theModifiedItem, corresponding_warning_level_low);
      ModifiedMustBeLowerOrEqual(corresponding_warning_level_low, corresponding_warning_level_high);
      ModifiedMustBeLowerOrEqual(corresponding_warning_level_high, corresponding_alarm_level_high);
    }else if(modif.includes("analog-warning_level_low-")){
      let splited_name = modif.split("-")
      let sensor_id = splited_name[splited_name.length-1];
      let corresponding_alarm_level_low = findItemByName(theItems, "analog-alarm_level_low-"+sensor_id);
      let corresponding_warning_level_high = findItemByName(theItems, "analog-warning_level_high-"+sensor_id);
      let corresponding_alarm_level_high = findItemByName(theItems, "analog-alarm_level_high-"+sensor_id);
      ModifiedMustBeHigherOrEqual(theModifiedItem, corresponding_alarm_level_low);
      ModifiedMustBeLowerOrEqual(theModifiedItem, corresponding_warning_level_high);
      ModifiedMustBeLowerOrEqual(corresponding_warning_level_high, corresponding_alarm_level_high);
    }else if(modif.includes("analog-warning_level_high-")){
      let splited_name = modif.split("-")
      let sensor_id = splited_name[splited_name.length-1];
      let corresponding_alarm_level_low = findItemByName(theItems, "analog-alarm_level_low-"+sensor_id);
      let corresponding_warning_level_low = findItemByName(theItems, "analog-warning_level_low-"+sensor_id);
      let corresponding_alarm_level_high = findItemByName(theItems, "analog-alarm_level_high-"+sensor_id);
      ModifiedMustBeLowerOrEqual(theModifiedItem, corresponding_alarm_level_high);
      ModifiedMustBeHigherOrEqual(theModifiedItem, corresponding_warning_level_low);
      ModifiedMustBeHigherOrEqual(corresponding_warning_level_low, corresponding_alarm_level_low);
    }else if(modif.includes("analog-alarm_level_high-")){
      let splited_name = modif.split("-")
      let sensor_id = splited_name[splited_name.length-1];
      let corresponding_warning_level_low = findItemByName(theItems, "analog-warning_level_low-"+sensor_id);
      let corresponding_alarm_level_low = findItemByName(theItems, "analog-alarm_level_low-"+sensor_id);
      let corresponding_warning_level_high = findItemByName(theItems, "analog-warning_level_high-"+sensor_id);
      ModifiedMustBeHigherOrEqual(theModifiedItem, corresponding_warning_level_high);
      ModifiedMustBeHigherOrEqual(corresponding_warning_level_high, corresponding_warning_level_low);
      ModifiedMustBeHigherOrEqual(corresponding_warning_level_low, corresponding_alarm_level_low);
    }
    //console.log(displayName, "updateMaxWithMinValueAnalogSensor end")
  }

  function updateMaxWithMinValueWaterLevelSensor(theModifiedItem: Item, theItems: Array<Item>, modif:string):void{
    //console.log(displayName, "updateMaxWithMinValueWaterLevelSensor start")
    if(modif.includes("water_level-warning_level-")){
      let splited_name = modif.split("-")
      let sensor_id = splited_name[splited_name.length-1];
      let corresponding_alarm_level = findItemByName(theItems, "water_level-alarm_level-"+sensor_id);
      ModifiedMustBeLowerOrEqual(theModifiedItem, corresponding_alarm_level);
    }else if (modif.includes("water_level-alarm_level-")){
      let splited_name = modif.split("-")
      let sensor_id = splited_name[splited_name.length-1];
      let corresponding_warning_level = findItemByName(theItems, "water_level-warning_level-"+sensor_id);
      ModifiedMustBeHigherOrEqual(theModifiedItem, corresponding_warning_level);
    }
    //console.log(displayName, "updateMaxWithMinValueWaterLevelSensor end")
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    //console.log(displayName, e)
    //console.log(displayName, e.target)
    const theItems = [...items]
    const fieldName: string = e.target.id;
    const fieldValue: number = (e.target.checked) ? 1:0;
    //console.log(displayName, "handleCheckboxChange ("+fieldName+"):"+fieldValue);
    const theItem = theItems.find(one => one.name === fieldName)
    if(theItem !== undefined) {theItem.value = fieldValue;}
    setItems(theItems);
  }

  const update_water_level_sensor = (item: Item): void => {
    //console.log(displayName, "water_level sensor change :"+item.name)
    const sensor_id = Number(item.name.split("-")[2])

    const sensor = find_water_level_sensor_from_sensor_id(station, sensor_id);
    if(sensor !== undefined){
      if (sensor.id === sensor_id){
        if(item.name.includes("name")){
          //console.log(displayName, "Sensor id :"+String(sensor_id)+" get a new name:"+item.value)
          sensor.name = item.value;
        }else if(item.name.includes("warning_level")){
          if(is_station_pap(station) && (sensor.type === "downstream") && (sensor.depth === 0)){
            sensor.warning_level = -1;
          }else{
            sensor.warning_level = item.value;
          }
        }else if(item.name.includes("alarm_level")){
          if(is_station_pap(station) && (sensor.type === "downstream") && (sensor.depth === 0)){
            sensor.alarm_level = -1;
          }else{
            sensor.alarm_level = item.value;
          }
        }else if(item.name.includes("trigger_under_water")){
          sensor.trigger_under_water = item.value;
        }

      }
    }
  }

  const update_analog_sensor = (item: Item): void => {
    //console.log(displayName, "analog sensor change :"+item.name)
    const sensor_id = Number(item.name.split("-")[2])

    const sensor = find_analog_sensor_from_sensor_id(station, sensor_id);
    if(sensor !== undefined){
      if (sensor.id === sensor_id){
        if(item.name.includes("name")){
          //console.log(displayName, "Sensor id :"+String(sensor_id)+" get a new name:"+item.value)
          sensor.name = item.value;
        }
        if(item.name.includes("alarm_level_low")){
          sensor.alarm_level_low = item.value;
        }
        if(item.name.includes("alarm_level_high")){
          sensor.alarm_level_high = item.value;
        }
        if(item.name.includes("warning_level_low")){
          sensor.warning_level_low = item.value;
        }
        if(item.name.includes("warning_level_high")){
          sensor.warning_level_high = item.value;
        }
      }
    }
  }

  const update_contacts = (item: Item): void => {
    //console.log(displayName, "Contact change :"+item.name)
    const contact_id = Number(item.name.split("-")[2])

    const the_contact = station.contacts.find(contact => contact.user_id === contact_id);
    if(the_contact !== undefined){
      //console.log(displayName, "The contact designed is:"+the_contact.email)
      if(item.name.includes("embacle_warning")){
        the_contact.embacle_warning = item.value;
      }else if(item.name.includes("embacle_alarm")){
        the_contact.embacle_alarm = item.value;
      }else if(item.name.includes("alimentation_warning")){
        the_contact.alimentation_warning = item.value;
      }else if(item.name.includes("alimentation_alarm")){
        the_contact.alimentation_alarm = item.value;
      }else if(item.name.includes("warning")){
        the_contact.warning = item.value;
      }else if(item.name.includes("alarm_pkd")){
        if(enablePKD){
          the_contact.alarm_pkd = item.value;
        }else{
          //Disable PKD alarm if pkd is disabled
          the_contact.alarm_pkd = false;
        }
      }else if(item.name.includes("alarm")){
        the_contact.alarm = item.value;
      }
    }
  }

  const validateForm = () => {
    /*
    let newForm: FormInputs = inputFields;
    console.log(displayName, newForm.station_name.value)
    */
    items.map(one => {
      if ((one.value !== one.initial_value)||(one.name.includes("pkd"))){
        console.log(displayName, "Item:"+one.name+" has changed :"+one.initial_value+"=>"+one.value)
        if(one.name === 'station-name'){
          station.name = one.value;
        }else if(one.name === 'station-migration_date_start'){
          station.migration_date_start = one.value;
        }else if(one.name === 'station-migration_date_end'){
          station.migration_date_end = one.value;
        }else if(one.name === 'station-in_migration_forced'){
          station.in_migration_forced = one.value;
        }else if((one.name === 'station-alarm_pkd_days')){
          const pkd_fault_trigger = station_get_pkd_fault_trigger(station);
          if(enablePKD){
            if(!pkd_fault_trigger){
              console.log(displayName, "create a new alert alarm_pkd_days");
              let one_fault_trigger = new FaultTrigger(0)
              one_fault_trigger.station_id = station.id;
              one_fault_trigger.type = "pkd";
              one_fault_trigger.alarm_days = one.value;
              station.faulttriggers = [one_fault_trigger];
            }else{
              console.log(displayName, "update alert alarm_pkd_days");
              pkd_fault_trigger.alarm_days = one.value;
            }
          }else{
            console.log(displayName, "update alert alarm_pkd_days to delete");
            if(pkd_fault_trigger){
              pkd_fault_trigger.id = 0;
              pkd_fault_trigger.alarm_days = 0;
              pkd_fault_trigger.alarm_level_high = 0;
            }
          }
        }else if(one.name === 'station-alarm_pkd_temperature'){
          const pkd_fault_trigger = station_get_pkd_fault_trigger(station);
          if(enablePKD){
              if(!pkd_fault_trigger){
              console.log(displayName, "create a new alert alarm_pkd_temperature");
              let one_fault_trigger = new FaultTrigger(0)
              one_fault_trigger.station_id = station.id;
              one_fault_trigger.type = "pkd";
              one_fault_trigger.alarm_level_high = one.value;
              station.faulttriggers = [one_fault_trigger];
            }else{
              console.log(displayName, "update alert alarm_pkd_temperature");
              pkd_fault_trigger.alarm_level_high = one.value;
            }
          }else{
            console.log(displayName, "update alert alarm_pkd_temperature to delete");
            if(pkd_fault_trigger){
              pkd_fault_trigger.id = 0;
              pkd_fault_trigger.alarm_days = 0;
              pkd_fault_trigger.alarm_level_high = 0;
            }
          }
        }
        if(one.name.includes("water_level")){
          update_water_level_sensor(one)
        }
        if(one.name.includes("analog")){
          update_analog_sensor(one)
        }
        if(one.name.includes("contact")){
          update_contacts(one)
        }
      }
      return null;
    })
    return true;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isFormValid = validateForm();
    if(isFormValid){
      console.log(displayName, "Setup form is valid");
      setUpdating(true);
      updateStation();
    }
  }

  function alarm_water_level_sensor(sensors: Array<WaterLevelSensor>|undefined, dsensors: Array<WaterLevelSensor>|undefined, type: string, name: string) {
    /*
     * type : 
     * -> general : standard waterlevel display
     * -> upstream : show alimentation
     * -> downstream : show embacle
     */
    if(sensors === undefined){
      //console.log(displayName, "Please provide sensors");
      return;
    }
    if((type === "downstream") && (dsensors === undefined)){
      console.log(displayName, "Please provide upstream/downstream sensors");
      return;
    }

    function myNumericInputFormat(value: string | null) {
      //console.log(displayName, "value:"+value);
      if(!value){
        return "Aucun";
      }
      if(value === "0" ){
        return "Direct";
      }
      if(value === "-1" ){
        return "Aucun";
      }
      return value;
    }
    function myNumericInputParse(stringValue: string) {
      //console.log(displayName, "stringValue:"+stringValue);
      if(stringValue === "Direct"){
        return 0;
      }
      if(stringValue === "Aucun"){
        return -1;
      }
      return parseInt(stringValue);
    }

    function getCorrectName(type: string){
      if(type === "upstream"){
        return "Profondeur :";
      }else if(type === "downstream"){
        return "Différence de niveau :";
      }
      return "Nom :";
    }

    function getDSensor(pos:number){
      if(dsensors){
        return dsensors[pos];
      }
      return null;
    }

    function getStateOfDSensor(pos:number):boolean{
      if(dsensors){
        let tmp = getDSensor(pos);
        if(tmp){
          return tmp.current_value;
        }
      }
      return false;
    }

    function getUSensor(pos:number){
      if(sensors){
        return sensors[pos];
      }
      return null;
    }

    function getStateOfUSensor(pos:number):boolean{
      if(sensors){
        let tmp = getUSensor(pos);
        if(tmp){
          return tmp.current_value;
        }
      }
      return false;
    }

    function getCorrectSensor(type: string){
      if(type === "downstream"){
        return dsensors;
      }
      return sensors;
    }

    let ptSensor = getCorrectSensor(type);
    if (type !== "general"){
      ptSensor=ptSensor?.sort((a, b) => a.depth < b.depth ? 1 : -1)
    }

    return(
      <div className="panel-setup-station-line">
        {alarm_display_logo_and_title(type, name)}
        <div className="panel-setup-station-line-content">
          <div className="panel-setup-station-line-content-in">
            <div className="panel-setup-station-line-content-name">
              {getCorrectName(type)}
            </div>
            <div className="panel-setup-station-line-content-waterlevel-live">
              Live :
            </div>
            <div data-tip="Conditions normales de fonctionnement" className="panel-setup-station-line-content-waterlevel-state">
              {(type!=="downstream") && ("État Normal:")}
            </div>
            <div className="panel-setup-station-line-content-waterlevel-warning-alarm">
              <div className="panel-setup-station-line-content-waterlevel-warning-alarm-input">
                <div data-tip={"Niveau de Vigilance"} className={"orangeled led-sensor"}></div>
              </div>
              <div className="panel-setup-station-line-content-waterlevel-warning-alarm-text" />
            </div>
            <div className="panel-setup-station-line-content-waterlevel-warning-alarm">
              <div className="panel-setup-station-line-content-waterlevel-warning-alarm-input">
                <div data-tip={"Niveau d'Urgence"} className={"redled led-sensor"}></div>
              </div>
              <div className="panel-setup-station-line-content-waterlevel-warning-alarm-text" />
            </div>
          </div>
            {
              ptSensor && ptSensor.map((sensor, pos) => (
              <div key={"sensor-station-"+station.id+"-sensor-"+sensor.id} className="panel-setup-station-line-content-in">
                <input className="panel-setup-station-line-content-name" type="text" value={(type==="general") ?((sensor.name)?(sensor.name):""):(sensor.depth+"cm")} disabled />
                <div className="panel-setup-station-line-content-waterlevel-live">
                  <div className="panel-setup-station-line-content-waterlevel-live-value">
                  {
                    (type==="downstream") && (
                      <div className={ return_color_for_water_level_from_bool(getStateOfUSensor(pos)) + "led led-sensor"}></div>
                    )
                  }
                  </div>
                  <div className="panel-setup-station-line-content-waterlevel-live-value" />
                  <div className="panel-setup-station-line-content-waterlevel-live-value">
                  {
                    (type==="downstream") ? (
                      <div className={ return_color_for_water_level_from_bool(getStateOfDSensor(pos)) + "led led-sensor"}></div>
                    ):(
                      <div className={ return_color_for_water_level_from_bool(sensor.current_value) + "led led-sensor"}></div>
                    )
                  }
                  </div>
                  <div className="panel-setup-station-line-content-waterlevel-live-days">
                    {sensor.last_update}
                  </div>
                </div>
                {
                  (type!=="downstream") ? (
                    <div data-tip={(getItemFromName("water_level-trigger_under_water-"+sensor.id).value) ? ("En eau"):("Hors d'eau")} className="panel-setup-station-line-content-waterlevel-state" onClick={()=>handleFlipFlopOnClick("water_level-trigger_under_water-"+sensor.id)}>
                      {(getItemFromName("water_level-trigger_under_water-"+sensor.id).value) ? (return_logo_from_type("water_level-under_water", "logo-sensor-half-size-oblon-png")):(return_logo_from_type("water_level-over_water", "logo-sensor-half-size-oblon-png"))}
                    </div>
                  ):(
                    <div className="panel-setup-station-line-content-waterlevel-state"/>
                  )
                }
                { (is_station_pap(station) && (sensor.type === "downstream") && (sensor.depth >= 0)) ? (<div className="panel-setup-station-line-content-waterlevel-warning-alarm"/>) :
                (
                    <div className="panel-setup-station-line-content-waterlevel-warning-alarm">
                      <NumericInput id={"water_level-warning_level-"+sensor.id}
                        className="panel-setup-station-line-content-waterlevel-warning-alarm-input disable-direct-value-numeric-input"
                        min={-1} max={365} step={1}
                        mobile={false} disabled={!current_user_can_modify}
                        format={myNumericInputFormat} parse={myNumericInputParse}
                        value={getItemFromName("water_level-warning_level-"+sensor.id).value}
                        onChange={(valueAsNumber:number, valueAsString:string, input:HTMLInputElement)=>handleOnChangeWaterLevelSensor(input, valueAsNumber)}
                      />
                      <div className="panel-setup-station-line-content-waterlevel-warning-alarm-text">{(getItemFromName("water_level-warning_level-"+sensor.id).value > 1)?("jours"):("jour")}</div>
                    </div>
                  )
                }
                {
                   (is_station_pap(station) && (sensor.type === "downstream") && (sensor.depth >= 0)) ? (<div className="panel-setup-station-line-content-waterlevel-warning-alarm"/>) :
                   (
                    <div className="panel-setup-station-line-content-waterlevel-warning-alarm">
                      <NumericInput id={"water_level-alarm_level-"+sensor.id}
                        className="panel-setup-station-line-content-waterlevel-warning-alarm-input disable-direct-value-numeric-input"
                        min={-1} max={365} step={1}
                        mobile={false} disabled={!current_user_can_modify}
                        format={myNumericInputFormat} parse={myNumericInputParse}
                        value={getItemFromName("water_level-alarm_level-"+sensor.id).value}
                        onChange={(valueAsNumber:number, valueAsString:string, input:HTMLInputElement)=>handleOnChangeWaterLevelSensor(input, valueAsNumber)}
                      />
                      <div className="panel-setup-station-line-content-waterlevel-warning-alarm-text">{(getItemFromName("water_level-alarm_level-"+sensor.id).value > 1)?("jours"):("jour")}</div>
                    </div>
                  )
                }
              </div>
              )
            )}
        </div>
      </div>
    )
  }

  function alarm_analog_sensor(all_sensors: Array<AnalogSensor>|undefined, type: string, name: string) {
    if(all_sensors === undefined){return}

    let sensors = all_sensors.filter(sensor => (sensor.type === type))

    if (sensors.length <= 0){
      return;
    }

    function input_definition_per_type(sensorType:string, variable:string, disabled:boolean){
      let min = 0;
      let max = 30;
      let step = 0.1;
      let precision = 1;
      if(sensorType === "temperature"){
        min=-10;
        max=40;
      }else if(sensorType === "oxymeter"){
        min=0;
        max=50;
      }else if(sensorType === "turbidity"){
        min=0;
        max=255;
        step=1;
      }else if(sensorType === "days"){
        min=1;
        step=1;
        precision=0;
      }else if(sensorType === "liquid_level"){
        min=0;
        step=1;
        max = 6553;
      }else {
        console.log(displayName, "Unkown sensor type:"+sensorType);
      }
      return(<NumericInput id={variable}
              mobile={false}
              min={min} max={max} step={step} precision={precision}
              className="panel-setup-station-sensor-input full-width disable-direct-value-numeric-input"
              disabled={!current_user_can_modify||disabled} snap strict
              value={getItemFromName(variable).value}
              onChange={(valueAsNumber:number, valueAsString:string, input:HTMLInputElement)=>handleOnChangeAnalogSensor(input, valueAsNumber)}
            />)
    }

    return(
      <div className="panel-setup-station-line">
        {alarm_display_logo_and_title(type, return_type_test(type))}
        <div className="panel-setup-station-line-content">
          <div className="panel-setup-station-line-content-in">
            <div className="panel-setup-station-line-content-name">
              Nom :
            </div>
            <div className="panel-setup-station-line-content-analog-warning-alarm">
              <div data-tip={"Niveau d'Urgence"} className={"redled led-sensor"}></div>
            </div>
            <div className="panel-setup-station-line-content-analog-warning-alarm">
              <div data-tip={"Niveau de Vigilance"} className={"orangeled led-sensor"}></div>
            </div>
            <div className="panel-setup-station-line-content-analog-live">
              {(type === "temperature")?("Moyenne:"):("Live :")}
            </div>
            <div className="panel-setup-station-line-content-analog-warning-alarm">
            <div data-tip={"Niveau de Vigilance"} className={"orangeled led-sensor"}></div>
            </div>
            <div className="panel-setup-station-line-content-analog-warning-alarm">
              <div data-tip={"Niveau d'Urgence"} className={"redled led-sensor"}></div>
            </div>
          </div>
          {sensors && sensors.map(sensor => (
          <div key={"sensor-station-"+station.id+"-sensor-"+sensor.id} className="panel-setup-station-line-content-in">
            <div className="panel-setup-station-line-content-name">
              <div className="panel-setup-station-sensor-input-text full-width">
                <input id={"sensor-station-"+station.id+"-sensor-"+sensor.id+"-name"} type="text" className="stay-in-div" disabled={true} value={(sensor.name)?(sensor.name):("")} />
              </div>
            </div>
            <div className="panel-setup-station-line-content-analog-warning-alarm">
              {input_definition_per_type(type,"analog-alarm_level_low-"+sensor.id, !current_user_can_modify)}
            </div>
            <div className="panel-setup-station-line-content-analog-warning-alarm">
              {input_definition_per_type(type,"analog-warning_level_low-"+sensor.id, !current_user_can_modify)}
            </div>
            <div className="panel-setup-station-line-content-analog-live">
              &#60;&nbsp;&nbsp;{(type === "temperature")?(sensor.mean):(sensor.current_value)}{return_unity_from_type(type)}&nbsp;&nbsp;&#62;
            </div>
            <div className="panel-setup-station-line-content-analog-warning-alarm">
              {input_definition_per_type(type,"analog-warning_level_high-"+sensor.id, !current_user_can_modify)}
            </div>
            <div className="panel-setup-station-line-content-analog-warning-alarm">
              {input_definition_per_type(type,"analog-alarm_level_high-"+sensor.id, !current_user_can_modify)}
            </div>
          </div>
          ))}
          {(type === "temperature") && (pkd_enabled) && (
          <div key="station-pkd" className="panel-setup-station-line-content-in">
            <div className="panel-setup-station-line-content-name">
              <div className="panel-setup-station-line-content-name-half">
                {return_logo_from_type("pkd", "logo-sensor-half-size-png")}
              </div>
              <div className="panel-setup-station-line-content-name-half" onClick={()=>(current_user_can_modify)&&setEnablePKD(!enablePKD)}>
                {return_logo_from_type((enablePKD)?("bp-oblon-selected"):("bp-oblon"), "logo-sensor-half-size-oblon-png")}
              </div>
            </div>
            <div className="panel-setup-station-line-content-analog-warning-alarm">
              T°C
            </div>
            <div className="panel-setup-station-line-content-analog-warning-alarm">
              {input_definition_per_type("temperature","station-alarm_pkd_temperature", !current_user_can_modify||!enablePKD)}
            </div>
            <div className="panel-setup-station-line-content-analog-warning-alarm-2col">
              Jours Consécutifs :
            </div>
            <div className="panel-setup-station-line-content-analog-warning-alarm">
              {input_definition_per_type("days","station-alarm_pkd_days", !current_user_can_modify||!enablePKD)}
            </div>
          </div>
          )}
        </div>
      </div>
    )
  }

  function alarm_contacts(contacts: Array<Contact>, name: string, typeAnalogSensor: string) {
    //console.log(displayName, contacts)
    function display_one_contact(contact: Contact){
      return (
        (
          <div key={"line-content-contact-station-"+station.id+"-contact-"+contact.user_id} className="panel-setup-station-line-content-in">
            <div key={"contact-station-"+station.id+"-contact-"+contact.user_id} className="panel-setup-station-line-content-in">
              <div className="panel-setup-station-line-content-contact-email">
                <input id={"contact-email-"+contact.user_id} data-tip={contact.email} type="text" className="stay-in-div" disabled={true} value={contact.name} />
              </div>
              <div className="panel-setup-station-line-content-contact-warning-alarm-header">
                <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                  <input id={"contact-alimentation_warning-"+contact.user_id} type="checkbox" className="filled-in" disabled={!current_user_can_modify||!allowWaterLevelWarning} checked={(getItemFromName("contact-alimentation_warning-"+contact.user_id).value > 0)?(true):(false)} onChange={e => handleCheckboxChange(e)}/>
                </div>
                <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                  <input id={"contact-alimentation_alarm-"+contact.user_id} type="checkbox" className="filled-in" disabled={!current_user_can_modify||!allowWaterLevelAlarm} checked={(getItemFromName("contact-alimentation_alarm-"+contact.user_id).value > 0)?(true):(false)} onChange={e => handleCheckboxChange(e)}/>
                </div>
              </div>
              {(is_station_pap(station)) && (
                <div className="panel-setup-station-line-content-contact-warning-alarm-header">
                  <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                    <input id={"contact-embacle_warning-"+contact.user_id} type="checkbox" className="filled-in" disabled={!current_user_can_modify||!allowWaterLevelEmbacleWarning} checked={(getItemFromName("contact-embacle_warning-"+contact.user_id).value > 0)?(true):(false)} onChange={e => handleCheckboxChange(e)}/>
                  </div>
                  <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                    <input id={"contact-embacle_alarm-"+contact.user_id} type="checkbox" className="filled-in" disabled={!current_user_can_modify||!allowWaterLevelEmbacleAlarm} checked={(getItemFromName("contact-embacle_alarm-"+contact.user_id).value > 0)?(true):(false)} onChange={e => handleCheckboxChange(e)}/>
                  </div>
                </div>
              )}
              <div className="panel-setup-station-line-content-contact-warning-alarm-header">
                <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                  <input id={"contact-warning-"+contact.user_id} type="checkbox" className="filled-in" disabled={!current_user_can_modify} checked={(getItemFromName("contact-warning-"+contact.user_id).value > 0)?(true):(false)} onChange={e => handleCheckboxChange(e)}/>
                </div>
                <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                  <input id={"contact-alarm-"+contact.user_id} type="checkbox" className="filled-in" disabled={!current_user_can_modify} checked={(getItemFromName("contact-alarm-"+contact.user_id).value > 0)?(true):(false)} onChange={e => handleCheckboxChange(e)}/>
                </div>
              </div>
              {(pkd_enabled)&&(
              <div className="panel-setup-station-line-content-contact-warning-alarm-header">
                <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                  <input id={"contact-alarm_pkd-"+contact.user_id} type="checkbox" className="filled-in" disabled={!current_user_can_modify||!enablePKD} checked={(getItemFromName("contact-alarm_pkd-"+contact.user_id).value > 0)?(true && enablePKD):(false)} onChange={e => handleCheckboxChange(e)}/>
                </div>
              </div>
              )}
              </div>
          </div>
        )
      )
    }

    function alarm_contacts_display_allowed_contacts()
    {
      if((contacts === undefined) || (contacts === null) || (contacts.length < 1)){
        return ;
      }
      if(!current_user_has_technical_access){
        const contact = contacts.find(contact => Number(contact.user_id) === Number(AuthenticationService.getUserID()))
        if(contact){
          return display_one_contact(contact);
          }
      }else{
        return contacts && contacts.map(contact => display_one_contact(contact));
      }
    }

    return(
      <div className="panel-setup-station-line">
        {alarm_display_logo_and_title("contact", return_type_test("contact"))}
        <div className="panel-setup-station-line-content">
          <div className="panel-setup-station-line-content-in">
            <div className="panel-setup-station-line-content-contact-email"/>
            {(is_station_pap(station)) ? (
              <div data-tip={"Alimentation"} className="panel-setup-station-line-content-contact-warning-alarm-header">
                {return_logo_from_type("alimentation", "logo-sensor-2-3-size-png")}
              </div>
            ):(
              <div data-tip={(is_station_papang(station))?("Niveaux d'eau"):("Niveau d'eau")} className="panel-setup-station-line-content-contact-warning-alarm-header">
                {return_logo_from_type("water_level", "logo-sensor-2-3-size-png")}
              </div>
            )
            }
            {(is_station_pap(station))&&(
              <div data-tip={"Embâcles"} className="panel-setup-station-line-content-contact-warning-alarm-header">
                {return_logo_from_type("embacle", "logo-sensor-2-3-size-png")}
              </div>
            )}
            <div data-tip={"Paramètres physico-chimiques"} className="panel-setup-station-line-content-contact-warning-alarm-header">
              {return_logo_from_type(typeAnalogSensor, "logo-sensor-2-3-size-png")}
            </div>
            {(pkd_enabled)&&(
            <div data-tip={"Alerte PKD"} className="panel-setup-station-line-content-contact-warning-alarm-header">
              {return_logo_from_type("pkd", "logo-sensor-2-3-size-png")}
            </div>
            )}
          </div>
          <div className="panel-setup-station-line-content-in">
            <div className="panel-setup-station-line-content-contact-email">
              E-mail :
            </div>
            <div className="panel-setup-station-line-content-contact-warning-alarm-header">
              <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                <div data-tip={"Niveau de Vigilance"} className={"orangeled led-sensor"}/>
              </div>
              <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                <div data-tip={"Niveau d'Urgence"} className={"redled led-sensor"}/>
              </div>
            </div>
            {
              (is_station_pap(station)) && (
                <div className="panel-setup-station-line-content-contact-warning-alarm-header">
                  <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                    <div data-tip={"Niveau de Vigilance"} className={"orangeled led-sensor"}/>
                  </div>
                  <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                    <div data-tip={"Niveau d'Urgence"} className={"redled led-sensor"}/>
                  </div>
                </div>
              )
            }

            <div className="panel-setup-station-line-content-contact-warning-alarm-header">
              <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                <div data-tip={"Niveau de Vigilance"} className={"orangeled led-sensor"}/>
              </div>
              <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                <div data-tip={"Niveau d'Urgence"} className={"redled led-sensor"}/>
              </div>
            </div>
            {(pkd_enabled)&&(
            <div className="panel-setup-station-line-content-contact-warning-alarm-header">
              <div className="panel-setup-station-line-content-contact-just-warning-alarm-header">
                <div data-tip={"Alerte"} className={"redled led-sensor"}/>
              </div>
            </div>
            )}
          </div>
            {alarm_contacts_display_allowed_contacts()}
        </div>
      </div>
    )
  }

  function alarm_display_logo_and_title(type: string, title:string){
    return(
      <div className="panel-setup-station-line-logo">
        <div>{(title===null)?(""):(title)}</div>
        <div>{return_logo_from_type(type, "logo-sensor-png")}</div>
      </div>
    )
  }

  function alarm_migration_period() {
    return(
      <div className="panel-setup-station-line">
        {alarm_display_logo_and_title("migration", "Période de migration")}
        <div className="panel-setup-station-line-content">
          <div className="panel-setup-station-line-content-in">
            {(isInMigration) ? (<div>
              <div className="text-warning-font">Période de migration en cours</div>
              <div className="text-small-warning-font">Niveau d'eau : L'alerte Vigilance devient immédiate</div>
              <div className="text-small-warning-font">Niveau d'eau : L'alerte Urgence se déclenche aux valeurs hors migration de l'alerte de Vigilance</div>
            </div>):(<div className="text-warning-font">&nbsp;</div>)}
          </div>
          <div className="panel-setup-station-line-content-in">
            <div>Du :</div>
            <input id="station-migration_date_start" disabled={!current_user_can_modify} type="date" value={getItemFromName("station-migration_date_start").value} onChange={(e) => handleMigrationDateOnChange(e)}/>
            <div>Au :</div>
            <input id="station-migration_date_end" disabled={!current_user_can_modify} type="date" value={getItemFromName("station-migration_date_end").value} onChange={(e) => handleMigrationDateOnChange(e)}/>
            <div data-tip={(getItemFromName("station-in_migration_forced").value) ? ("Migration observée forcée"):("Migration observée non forcée")} className="panel-setup-station-line-content-waterlevel-state" onClick={()=>handleFlipFlopOnClick("station-in_migration_forced")}>
              {(getItemFromName("station-in_migration_forced").value) ? (return_logo_from_type("migration_select_in_progress", "logo-sensor-half-size-oblon-png")):(return_logo_from_type("migration_select_not_in_progress", "logo-sensor-half-size-oblon-png"))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  function getNameOfAllSensorLogo() {
    let sensor_type_registered: Array<string> = [];

    if(analog_sensors){
      analog_sensors.map(one => {
        if(!sensor_type_registered.includes(one.type)){
          sensor_type_registered.push(one.type);
        }
        return null;
      });
      if (sensor_type_registered.length === 1){
        return analog_sensors[0].type;
      }
    }
    return "temperature";
    //return "all_analog";
  }

  if (local_debug){
    //console.log(displayName, "general_water_level_sensors:"+general_water_level_sensors);
    //console.log(displayName, "upstream_water_level_sensors:"+upstream_water_level_sensors);
    //console.log(displayName, "downstream_water_level_sensors:"+downstream_water_level_sensors);
    //(downstream_water_level_sensors) && (downstream_water_level_sensors[0]) && console.log(displayName, downstream_water_level_sensors[0]);
    //displayInputFields();
    //console.log(displayName, "isInMigration:"+isInMigration);
    //console.log(displayName, "fullscreen:"+fullscreen);
  }

  //https://codesandbox.io/s/react-dynamic-form-fields-3fjbd?from-embed=&file=/src/index.js
  return (
    <form onSubmit={e => handleSubmit(e)}>
      <div className="panel-setup-station-main">
        {(is_station_pap(station)) && alarm_migration_period()}
        {is_there_sensor_in_array(general_water_level_sensors) && alarm_water_level_sensor(general_water_level_sensors, undefined, "general", (is_station_papang(station))?("Niveaux d'eau"):("Niveau d'eau"))}
        {is_there_sensor_in_array(upstream_water_level_sensors) && alarm_water_level_sensor(upstream_water_level_sensors, undefined, "upstream", "Alimentation")}
        {is_there_sensor_in_array(downstream_water_level_sensors) && alarm_water_level_sensor(upstream_water_level_sensors, downstream_water_level_sensors, "downstream", "Embâcles")}
        {alarm_analog_sensor(analog_sensors, "liquid_level", " ")}
        {alarm_analog_sensor(analog_sensors, "temperature", " ")}
        {alarm_analog_sensor(analog_sensors, "oxymeter", " ")}
        {alarm_analog_sensor(analog_sensors, "turbidity", " ")}
        {alarm_contacts(station.contacts, "Contacts", getNameOfAllSensorLogo())}
        <button type="submit" className="btn-station-card align-self-center" disabled={updating||!current_user_can_modify||!stationFullyLoaded}>
          {(updating||!stationFullyLoaded)?return_logo_from_type("loader","icon-sensor-30"):"Valider"}
        </button>
      </div>
      <ReactTooltip type="light" />
    </form>
  );
  
}
  
export default StationCardAlarm;