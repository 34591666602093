import { FunctionComponent } from 'react';
import TransmetteurIMG from "../img/transmetteur-300x199.png";
import CapteursIMG from "../img/capteurs-300x201.png";
import ThermeauPDF from "../documents/thermeau.pdf";
import FlowPDF from "../documents/flow.pdf";
import PapPDF from "../documents/papeye.pdf";
import VigeelPDF from "../documents/vigeel.pdf";
import PresentationCommercialPDF from "../documents/plaquette_presentation.pdf";
import SquamaNavbar from '../components/squama-navbar';
import { return_logo_from_type } from '../helpers/station-helper';

const SquamaSolution: FunctionComponent = () => {

    return (

    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-center-div the-background">
        <div className="solutions-main">
          <div className="solutions-1">
            QU'EST CE QU'UNE SOLUTION SQUAMA ?
          </div>
          <div className="solutions-10_40-210_240">
            <div className="solutions-10_13-20_24-30_32-40_42">
              <img src={TransmetteurIMG} className="solutions-30-40" alt=""/>
              <div className="solutions-31-41">
                MODULE TRANSMETTEUR
              </div>
              <div className="solutions-32-42">
              Autonome en énergie
              <br/>
              Fréquence d’émission programmable
              <br/>
              Faible encombrement
              </div>
            </div>
            <div className="solutions-10_13-20_24-30_32-40_42">
              <div className="solutions-10-and-13-and-22">
              Une solution SQUAMA est composée d’au moins un <b>module transmetteur</b> connectés à différents <b>modules capteurs</b> (température, niveau, oxygène...).
              </div>
              <div className="solutions-logo-text">
                {return_logo_from_type("echanges", "solutions-logo-text_logo")}
                <div className="solutions-logo-text_text">
                Partage facilité
                </div>
              </div>
              <div className="solutions-10-and-13-and-22">
              Chaque solution inclut l’accès à l’interface en ligne pour 5 utilisateurs, l'abonnement réseau pendant 5 ans et la sauvegarde des données brutes pendant 1 an.
              </div>
            </div>
            <div className="solutions-10_13-20_24-30_32-40_42">
              <div className="solutions-logo-text">
                {return_logo_from_type("modulable", "solutions-logo-text_logo")}
                <div className="solutions-logo-text_text">
                Modulable/Réutilisable
                </div>
              </div>
              <div className="solutions-10-and-13-and-22">
              Elle est <b>modulable et évolutive</b> en connectant simplement un module capteur supplémentaire à la solution existante.
              </div>
              <div className="solutions-logo-text">
                {return_logo_from_type("maintenance", "solutions-logo-text_logo")}
                <div className="solutions-logo-text_text">
                Facile à installer
                </div>
              </div>
            </div>
            <div className="solutions-10_13-20_24-30_32-40_42">
              <img src={CapteursIMG} className="solutions-30-40" alt=""/>
              <div className="solutions-31-41">
                MODULES CAPTEUR
              </div>
              <div className="solutions-32-42">
                Modules intelligents 
                <br/>
                Boîtiers résinés totalement étanches
                <br/>
                Faciles à connecter 
              </div>
            </div>
          </div>
          <div className="solutions-100">
          Des configurations types sont proposées pour répondre à des <b>besoins spécifiques</b>:
          </div>
          <div className="solutions-100">
            <a href={PresentationCommercialPDF} target="_blank" rel="noreferrer" className="solutions-100-button">Plaquette de présentation</a>
          </div>
          <div className="solutions-10_40-210_240">
            <div className="solutions-210_214-220_224-230_234-240_244">
              <div className="solutions-logo-text-solution">
                {return_logo_from_type("temperature", "solutions-logo-text-solution_logo")}
                <div className="solutions-logo-text-solution_text">
                  <div className="solutions-logo-text-solution_text-up">
                    LA SOLUTION
                  </div>
                  <div className="solutions-logo-text-solution_text-down">
                    THERM'EAU
                  </div>
                </div>
              </div>
              <div className="solutions-10-and-13-and-22">
              Pour comprendre l’effet des températures de l’eau sur les milieux aquatiques à l’heure du changement climatique, il est essentiel de produire des données fiables.
              </div>
              <div className="solutions-213_223_233_243">
                Observatoire thermique / réseaux de suivis
                <br/>
                Suivis ponctuels (reproduction, migration...)
                <br/>
                Alertes seuils de risques, (mortalités,  parasites...)
                <br/>
                Comportement / aires de répartition
                <br/>
                Réchauffement climatique
                <br/>
                Suivis de rejets
              </div>
              <div className="solutions-214_224_234_244">
                <a href={ThermeauPDF} target="_blank" rel="noreferrer" className="solutions-214_224_234_244-button">En savoir plus</a>
              </div>
            </div>
            <div className="solutions-210_214-220_224-230_234-240_244">
              <div className="solutions-logo-text-solution">
                {return_logo_from_type("water_level", "solutions-logo-text-solution_logo")}
                <div className="solutions-logo-text-solution_text">
                  <div className="solutions-logo-text-solution_text-up">
                    LA SOLUTION
                  </div>
                  <div className="solutions-logo-text-solution_text-down">
                    F'LOW
                  </div>
                </div>
              </div>
              <div className="solutions-10-and-13-and-22">
              La solution F’LOW permet de suivre des hauteurs d’eau caractéristiques à l’aide de capteurs de type on/off.
              </div>
              <div className="solutions-213_223_233_243">
                Risques d’assecs
                <br/>
                Mise en eau d’annexes hydrauliques
                <br/>
                Alimentation de frayères aménagées
                <br/>
                Exondation de zones à enjeux  (frayères, échouages...)
                <br/>
                Débits réservés / Régimes d’éclusées
                <br/>
                Franchissabilité de radiers
                <br/>
                Niveaux et cotes de plan d’eau
              </div>
              <div className="solutions-214_224_234_244">
                <a href={FlowPDF} target="_blank" rel="noreferrer" className="solutions-214_224_234_244-button">En savoir plus</a>
              </div>
            </div>
            <div className="solutions-210_214-220_224-230_234-240_244">
              <div className="solutions-logo-text-solution">
                {return_logo_from_type("pap", "solutions-logo-text-solution_logo")}
                <div className="solutions-logo-text-solution_text">
                  <div className="solutions-logo-text-solution_text-up">
                    LA SOLUTION
                  </div>
                  <div className="solutions-logo-text-solution_text-down">
                    PAP'EYE
                  </div>
                </div>
              </div>
              <div className="solutions-10-and-13-and-22">
              La solution PAP’EYE permet d’optimiser la fonctionnalité de tous les types de passes à poissons (passes à bassins, pré-barrages, rampes…) en suivant les niveaux d’eau au sein des ouvrages franchissement.
              </div>
              <div className="solutions-213_223_233_243">
                Gestion des embâcles
                <br/>
                Respect des droits d’eau pour l’alimentation de la passe
                <br/>
                Suivi des défauts d’entretien
                <br/>
                Suivi des conditions de migration 
              </div>
              <div className="solutions-214_224_234_244">
                <a href={PapPDF} target="_blank" rel="noreferrer" className="solutions-214_224_234_244-button">En savoir plus</a>
              </div>
            </div>
            <div className="solutions-210_214-220_224-230_234-240_244">
              <div className="solutions-logo-text-solution">
                {return_logo_from_type("vigeel", "solutions-logo-text-solution_logo")}
                <div className="solutions-logo-text-solution_text">
                  <div className="solutions-logo-text-solution_text-up">
                    LA SOLUTION
                  </div>
                  <div className="solutions-logo-text-solution_text-down">
                    VIG'EEL
                  </div>
                </div>
              </div>
              <div className="solutions-10-and-13-and-22">
              La solution VIG’EEL est spécialement configurée pour les passes-pièges à anguilles.  Elle s’appuie sur des capteurs de niveau installés à des endroits stratégiques pour détecter les problèmes de fonctionnement de la passe.
              </div>
              <div className="solutions-213_223_233_243">
                Surveillance de l’alimentation en eau
                <br/>
                Risques de débordement
                <br/>
                Vidange du bac de captures
                <br/>
                Deconnexion de la rampe au plan d’eau aval
                <br/>
                Suivi des conditions de stabulation
                <br/>
                Ecoulement sur la rampe
              </div>
                <div className="solutions-214_224_234_244">
                  <a href={VigeelPDF} target="_blank" rel="noreferrer" className="solutions-214_224_234_244-button">En savoir plus</a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default SquamaSolution;