import React, { FunctionComponent } from 'react';
import SquamaNavbar from '../components/squama-navbar';

const PageNotFound: FunctionComponent = () => {
  
  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-center-column-div the-background">
        <h1 className="white-font">Cette page n'existe pas !</h1>
        <button type="button" className="squama-btn-navbar" onClick={(e) => {
        e.preventDefault();
        window.location.href='/accueil';
        }}>
          Accueil
        </button>
      </div>
    </div>
  );
}
  
export default PageNotFound;