import React, { FunctionComponent, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import Bcrypt from "react-native-bcrypt";

import Company from '../../models/company';
import Contact from '../../models/contact';

import { io } from "socket.io-client";
import { WebsocketEmitRequest } from '../../models/websocket';
import { authHeader } from '../../helpers/auth-headers';
import SiteService from '../../services/site-service';
import { return_logo_from_type } from '../../helpers/station-helper';

type ModalItems = {
  companyIdSelected: number;
  userIdSelected: number;
  askExternalUserIdSelected: boolean;
  externalUserIdSelected: number;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  contactRole: string;
  contactNotHashedPasswordYet: string;
  contactNotHashedConfirmedPasswordYet: string;
}

const ModalCreateModifyUser: FunctionComponent = () => {
  const displayName = "ModalCreateModifyUser:";
  const enableDebug = false;
  const enableDebugWebsocket = false;
  const enableDebugUseEffect = false;
  const showPassword = true;

  const [websocketEmit, setWebsocketEmit] = useState<WebsocketEmitRequest | undefined>(undefined);

  const { register, handleSubmit, setValue, watch } = useForm<ModalItems>();

  const [companies, setCompanies] = useState<Company[] | null>(null);
  const [contacts, setContacts] = useState<Contact[] | null>(null);
  const [allSquamaContacts, setAllSquamaContacts] = useState<Contact[] | null>(null);
  const [usersRetrieved, setUsersRetrieved] = useState<boolean>(false);
  const [generatePassword, setGeneratePassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setshowModal] = useState<boolean>(false);

  const companyIdSelectedValue = Number(watch("companyIdSelected", 0));
  const askExternalUserIdSelectedValue = Boolean(watch("askExternalUserIdSelected", false));
  const userIdSelectedValue = Number(watch("userIdSelected"));
  const externalUserIdSelectedValue = Number(watch("externalUserIdSelected"));

  useEffect(() => {
    (enableDebugUseEffect) && console.log(displayName, "useEffect websocket start");
    if (externalUserIdSelectedValue > 0) {
      setValue("userIdSelected", 0);
    }
    if (userIdSelectedValue > 0) {
      setValue("externalUserIdSelected", 0);
    }
  }, [externalUserIdSelectedValue, userIdSelectedValue, enableDebugUseEffect, setValue]);

  useEffect(() => {
    (enableDebugWebsocket) && console.log(displayName, "useEffect websocket start");
    const socket = io(SiteService.getWebsocketUrl(), {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
      extraHeaders: {
        "my-custom-header": "1234" // WARN: this will be ignored in a browser
      }
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      (enableDebugWebsocket) && console.log(displayName, "logged_out:", logged_out_desc_str);
    });
    socket.on("answer", (answer: any) => {
      (enableDebugWebsocket) && console.log(displayName, "Answer:", answer);
    });
    socket.on("contacts_desc", (the_desc_str: string) => {
      const the_contacts = JSON.parse(the_desc_str);
      (enableDebugWebsocket) && console.log(displayName, "the_contacts:", the_contacts);
      if (askExternalUserIdSelectedValue) {
        setAllSquamaContacts(the_contacts.sort(function(a:Contact,b:Contact){return(a.user_id - b.user_id)}));
      } else {
        setContacts(the_contacts);
        setUsersRetrieved(true);
      }
    });
    socket.on("companies_desc", (the_desc_str: string) => {
      const the_companies = JSON.parse(the_desc_str);
      (enableDebugWebsocket) && console.log(displayName, "the_companies:", the_companies);
      setCompanies(the_companies);
      setLoading(false);
    });
    socket.on("done", (values_desc_str: string) => {
      (enableDebugWebsocket) && console.log(displayName, "got done:", values_desc_str);
      setshowModal(false);
      reset_page();
    });
    if (websocketEmit) {
      if (enableDebugWebsocket) console.log(displayName, "----------> websocket emit :", websocketEmit);
      socket.emit(websocketEmit.channel, websocketEmit.request);
    }
    (enableDebugWebsocket) && console.log(displayName, "useEffect websocket end");
    return function cleanup() {
      (enableDebugWebsocket) && console.log(displayName, "useEffect websocket cleanup")
      socket.disconnect();
    };
  }, [websocketEmit]);

  useEffect(() => {
    (enableDebugUseEffect) && console.log(displayName, "useEffect showModal start");
    if (showModal) {
      (enableDebugUseEffect) && console.log(displayName, "Request all companies_desc");
      reset_page();
      const the_request: any = {
        type: "companies_desc",
        number: 0,
        liste: [],
        from: "token",
        token: authHeader()
      }
      setWebsocketEmit({ channel: "request", request: the_request });
    } else {
      reset_page();
    }
    (enableDebugUseEffect) && console.log(displayName, "useEffect showModal end");
  }, [showModal]);

  useEffect(() => {
    (enableDebugUseEffect) && console.log(displayName, "useEffect companyIdSelectedValue start");
    if (companyIdSelectedValue) {
      (enableDebugUseEffect) && console.log(displayName, "Ask all contacts for company id:", companyIdSelectedValue);
      const the_contacts_request: any = {
        type: "contacts_desc",
        number: 1,
        liste: [companyIdSelectedValue],
        from: "company_id",
        filter: ["access"],
        token: authHeader()
      }
      setWebsocketEmit({ channel: "request", request: the_contacts_request });
    }
    (enableDebugUseEffect) && console.log(displayName, "useEffect companyIdSelectedValue end");
  }, [companyIdSelectedValue]);

  useEffect(() => {
    (enableDebugUseEffect) && console.log(displayName, "useEffect askExternalUserIdSelectedValue start");
    if (askExternalUserIdSelectedValue) {
      (enableDebugUseEffect) && console.log(displayName, "Ask all SQUAMA contacts");
      const the_request: any = {
        type: "contacts_desc",
        number: 0,
        liste: [0],
        from: "company_id",
        token: authHeader()
      }
      setWebsocketEmit({ channel: "request", request: the_request });
    }
    (enableDebugUseEffect) && console.log(displayName, "useEffect askExternalUserIdSelectedValue end");
  }, [askExternalUserIdSelectedValue]);

  useEffect(() => {
    (enableDebugUseEffect) && console.log(displayName, "useEffect userIdSelectedValue:", userIdSelectedValue);
    if (userIdSelectedValue) {
      if (contacts) {
        const the_selected_contact = contacts.find(one => one.id === userIdSelectedValue);
        (enableDebugUseEffect) && console.log(displayName, "contacts:", contacts);
        (enableDebugUseEffect) && console.log(displayName, "the_selected_contact:", the_selected_contact);
        if (the_selected_contact) {
          setValue("contactName", the_selected_contact.name)
          setValue("contactEmail", the_selected_contact.email);
          setValue("contactPhone", the_selected_contact.phone);
          setValue("contactRole", the_selected_contact.role);
          setValue("contactNotHashedPasswordYet", "");
          setValue("contactNotHashedConfirmedPasswordYet", "");
        }
      }
    } else {
      setValue("contactName", "");
      setValue("contactEmail", "");
      setValue("contactPhone", "");
      setValue("contactRole", "user");
      setValue("contactNotHashedPasswordYet", "");
      setValue("contactNotHashedConfirmedPasswordYet", "");
    }
  }, [userIdSelectedValue]);

  useEffect(() => {
    (enableDebugUseEffect) && console.log(displayName, "useEffect generatePassword:", generatePassword);
    if (generatePassword) {
      const the_pass = generatePass(12);
      setValue("contactNotHashedPasswordYet", the_pass);
      setValue("contactNotHashedConfirmedPasswordYet", the_pass);
      setGeneratePassword(false);
    }
  }, [generatePassword]);

  function generatePass(password_length: number): string {
    let pass = '';
    const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
      'abcdefghijklmnopqrstuvwxyz0123456789'
      + '@!#$+&$';
    for (let i = 1; i <= password_length; i++) {
      let char = Math.floor(Math.random() * str.length + 1);
      pass += str.charAt(char);
    }
    return pass;
  }

  function reset_page() {
    setCompanies(null);
    setContacts(null);
    setAllSquamaContacts(null);
    setValue("userIdSelected", 0);
    setValue("askExternalUserIdSelected", false);
    setValue("externalUserIdSelected", 0);
    setValue("companyIdSelected", 0)
    setValue("contactName", "");
    setValue("contactEmail", "");
    setValue("contactPhone", "");
    setValue("contactRole", "user");
    setValue("contactNotHashedPasswordYet", "");
    setValue("contactNotHashedConfirmedPasswordYet", "");
  }

  function modal_create_user() {
    const onHookFormSubmit = (data: ModalItems) => {
      (enableDebug) && console.log(displayName, "data", data);
      let the_command = {
        "command": "",
        "user_id": 0,
        "user_name": data.contactName,
        "user_email": data.contactEmail,
        "user_phone": data.contactPhone,
        "user_complete_role": data.contactRole,
        "user_hashed_password": Bcrypt.hashSync(data.contactNotHashedPasswordYet),
        "company_id": companyIdSelectedValue
      };
      if (!userIdSelectedValue && !externalUserIdSelectedValue) {
        (enableDebug) && console.log(displayName, "Creating a new user");
        if (data.contactNotHashedPasswordYet !== data.contactNotHashedConfirmedPasswordYet) {
          (enableDebug) && console.log(displayName, "Password are not similar");
          window.confirm('Les mots de passe sont différents')
          return
        }
        the_command["command"] = "add_user_to_company_id";
        const the_request: any = {
          type: "commands",
          number: 1,
          liste: [JSON.stringify(the_command)],
          from: "token",
          token: authHeader()
        }
        setWebsocketEmit({ channel: "request", request: the_request });
      } else if (userIdSelectedValue && contacts && !externalUserIdSelectedValue) {
        (enableDebug) && console.log(displayName, "Updating a user");
        the_command["command"] = "update_user_to_company_id";
        the_command["user_id"] = userIdSelectedValue;
        if(!data.contactNotHashedPasswordYet||data.contactNotHashedPasswordYet===""){
          the_command["user_hashed_password"]="";
        }
        const the_request: any = {
          type: "commands",
          number: 1,
          liste: [JSON.stringify(the_command)],
          from: "token",
          token: authHeader()
        }
        setWebsocketEmit({ channel: "request", request: the_request });
      } else if (!userIdSelectedValue && externalUserIdSelectedValue && allSquamaContacts) {
        (enableDebug) && console.log(displayName, "Add an external user the user id:", externalUserIdSelectedValue);
        the_command["command"] = "add_existing_user_to_company_id";
        the_command["user_id"] = externalUserIdSelectedValue;
        const the_external_contact = allSquamaContacts.find(one => (one.id === externalUserIdSelectedValue));
        if (the_external_contact) {
          the_command["user_name"] = the_external_contact.name;
          the_command["user_phone"] = the_external_contact.phone;
          the_command["user_email"] = the_external_contact.email;
          the_command["user_hashed_password"] = the_external_contact.hashed_password;
        }
        const the_request: any = {
          type: "commands",
          number: 1,
          liste: [JSON.stringify(the_command)],
          from: "token",
          token: authHeader()
        }
        setWebsocketEmit({ channel: "request", request: the_request });
      }
    };

    function delete_user(the_contact_id: number, the_company_id: number, completly_delete: boolean) {
      (enableDebug) && console.log(displayName, "delete_user the_contact_id:", the_contact_id, ",the_company_id:", the_company_id, ",completly_delete:", completly_delete);
      const the_selected_contact = (contacts) ? (contacts.find(one => one.id === userIdSelectedValue)) : (null);
      if (!the_selected_contact) {
        console.warn(displayName, "Unable to find the contact with id:", the_contact_id);
        return;
      }
      if (completly_delete) {
        const the_request: any = {
          type: "commands",
          number: 1,
          liste: [JSON.stringify({ "command": "remove_user_from_squama", "user_id": the_selected_contact.id, "user_email": the_selected_contact.email })],
          from: "token",
          token: authHeader()
        }
        setWebsocketEmit({ channel: "request", request: the_request });
      } else {
        const the_request: any = {
          type: "commands",
          number: 1,
          liste: [JSON.stringify({ "command": "remove_user_from_company_id", "user_id": the_selected_contact.id, "user_email": the_selected_contact.email, "company_id": the_company_id })],
          from: "token",
          token: authHeader()
        }
        setWebsocketEmit({ channel: "request", request: the_request });
      }
    }

    const onHookFormFullDelete = () => {
      (enableDebug) && console.log(displayName, "onHookFormFullDelete");
      if (window.confirm('Etes-vous sur de supprimer complètement ce Contact de SQUAMA?')) {
        (userIdSelectedValue) && delete_user(userIdSelectedValue, companyIdSelectedValue, true);
      }
    };

    const onHookFormDelete = () => {
      (enableDebug) && console.log(displayName, "onHookFormDelete");
      if (window.confirm('Etes-vous sur de supprimer ce Contact de la société?')) {
        (userIdSelectedValue) && delete_user(userIdSelectedValue, companyIdSelectedValue, false);
      }
    };

    return (
      <Modal
        show={showModal}
        onHide={() => setshowModal(false)}
        onSubmit={handleSubmit(onHookFormSubmit)}
        backdrop="static"
        centered
      >
        <Modal.Header translate="yes" closeButton>
          <Modal.Title className="squama-font">Créer/Modifier un utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* Select existing company */}
            <div className="row m-0">
              <div className="col align-self-center">Nom de la société :</div>
              <div className="col align-self-center">
                <select className="squama-font" {...register("companyIdSelected", { required: !externalUserIdSelectedValue })}>
                  <option value="0">Sélectionner</option>
                  {(companies) && companies.map(company => { return (<option value={company.id} key={company.id}>{company.name}</option>) })}
                </select>
              </div>
              {(loading) ? (<div className="col-1 align-self-center">{return_logo_from_type("loader", "logo-sensor-half-size-png")}</div>) : undefined}
            </div>
            {((usersRetrieved) && (companyIdSelectedValue > 0) && !(askExternalUserIdSelectedValue)) ? (
              <React.Fragment>
                <hr />
                {/* Select new/existing station */}
                <div className="row m-0">
                  <div className="col" />
                  <div className="col align-self-center">
                    <select className="squama-font" {...register("userIdSelected", { required: !externalUserIdSelectedValue })}>
                      <option value="0">NouveauContact</option>
                      {(contacts) && contacts.map(contact => { return (<option value={contact.id} key={contact.id}>{contact.email}</option>) })}
                    </select>
                  </div>
                  <div className="col" />
                </div>
                <div className="row m-0">
                  <div className="col-5 align-self-center squama-font">Nom* :</div>
                  <div className="col-7 align-self-center">
                    <input id="contactName" type="text" className="stay-in-div" {...register("contactName", { required: !externalUserIdSelectedValue })} />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-5 align-self-center squama-font">Email* :</div>
                  <div className="col-7 align-self-center">
                    <input id="contactEmail" type="text" className="stay-in-div" {...register("contactEmail", { required: !externalUserIdSelectedValue })} />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-5 align-self-center squama-font">Phone* :</div>
                  <div className="col-7 align-self-center">
                    <input id="contactPhone" type="text" className="stay-in-div" {...register("contactPhone", { required: !externalUserIdSelectedValue })} />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-5 align-self-center squama-font">Mot de passe* :</div>
                  <div className="col-7 align-self-center">
                    <input id="contactNotHashedPasswordYet" type={showPassword ? 'text' : 'password'} className="stay-in-div" {...register("contactNotHashedPasswordYet", { required: (!userIdSelectedValue && !externalUserIdSelectedValue) })} />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-5 align-self-center squama-font">Re-taper Mot de passe* :</div>
                  <div className="col-7 align-self-center">
                    <input id="contactNotHashedConfirmedPasswordYet" type={showPassword ? 'text' : 'password'} className="stay-in-div" {...register("contactNotHashedConfirmedPasswordYet", { required: (!userIdSelectedValue && !externalUserIdSelectedValue) })} />
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-7 align-self-center squama-font">Générer le mot de passe</div>
                  <div className="col-5 align-self-center" onClick={() => setGeneratePassword(true)}>{return_logo_from_type("setup", "logo-sensor-half-size-png")}</div>
                </div>
                {(userIdSelectedValue) ? (
                  <div className="col align-self-center">
                    <button type="button" className="squama-btn-navbar squama-btn-gestion-delete squama-btn-gestion-modal-bigger" onClick={onHookFormDelete}>
                      Supprimer de cette société
                    </button>
                    <button type="button" className="squama-btn-navbar squama-btn-gestion-delete squama-btn-gestion-modal-bigger" onClick={onHookFormFullDelete}>
                      Supprimer de tout SQUAMA
                    </button>
                  </div>
                ) : undefined}
                <hr />
              </React.Fragment>) : undefined}
            {((usersRetrieved) && (companyIdSelectedValue > 0)) ? (
              <React.Fragment>
                <div className="row m-0">
                  <div className="col-7 align-self-center squama-font">Un autre utilisateur SQUAMA ?</div>
                  <div className="col-5 align-self-center">
                    <input id="ask-all-contacts" type="checkbox" className="filled-in" {...register("askExternalUserIdSelected")} />
                  </div>
                </div>
                {
                  (allSquamaContacts) && (
                    <select className="squama-font" {...register("externalUserIdSelected", { required: false })}>
                      <option value="0">ContactExistant</option>
                      {allSquamaContacts.map(contact => { return (<option value={contact.id} key={contact.id}>{contact.name + "(" + contact.email + ")"}</option>) })}
                    </select>
                  )
                }
                <hr />
                <div className="row m-0">
                  <div className="col-5 align-self-center squama-font">Role* :</div>
                  <div className="col-7 align-self-center">
                    <div className="squama-font"><input type="radio" id="god" value="god" {...register("contactRole", { required: !externalUserIdSelectedValue })} />Dieu</div>
                    <div className="squama-font"><input type="radio" id="admin_contract_technical" value="admin_contract_technical" {...register("contactRole", { required: !externalUserIdSelectedValue })} />Admin du contrat et technique</div>
                    <div className="squama-font"><input type="radio" id="admin_contract" value="admin_contract" {...register("contactRole", { required: !externalUserIdSelectedValue })} />Admin du contrat</div>
                    <div className="squama-font"><input type="radio" id="admin_technical" value="admin_technical" {...register("contactRole", { required: !externalUserIdSelectedValue })} />Admin technique</div>
                    <div className="squama-font"><input type="radio" id="user" value="user" {...register("contactRole", { required: !externalUserIdSelectedValue })} />Utilisateur</div>
                    <div className="squama-font"><input type="radio" id="admin_nanogis" value="admin_nanogis" {...register("contactRole", { required: !externalUserIdSelectedValue })} />Admin Nanogis</div>
                    <div className="squama-font"><input type="radio" id="nanogis" value="nanogis" {...register("contactRole", { required: !externalUserIdSelectedValue })} />Utilisateur Nanogis</div>
                  </div>
                </div>
              </React.Fragment>
            ) : undefined}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="squama-btn-navbar squama-btn-gestion-modal" onClick={() => setshowModal(false)}>
            Annuler
          </button>
          <button type="submit" className="squama-btn-navbar squama-btn-gestion squama-btn-gestion-modal" onClick={handleSubmit(onHookFormSubmit)}>
            Enregistrer
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
  return (
    <div className="squama-item-navbar">
      {modal_create_user()}
      <button type="button" className="squama-btn-navbar squama-btn-gestion" onClick={() => setshowModal(true)}>
        Créer/Modifier un utilisateur
      </button>
    </div>
  )
}
export default ModalCreateModifyUser;
