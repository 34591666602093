
export function fillContactMessage(contact: string, company: string, address: string, tel: string, email: string, object: string, message: string) {
    let out: string = "";
    out += "<h1>Message en provenance de la page contact https://www.squama.fr</h1>\n"
    out += "<b><u>Object : </u></b>" + object + "\n"
    out += "<b><u>Contact : </u></b>" + contact + "\n"
    out += "<b><u>Société : </u></b>" + company + "\n"
    out += "<b><u>Adresse : </u></b>" + address + "\n"
    out += "<b><u>Téléphone : </u></b>" + tel + "\n"
    out += "<b><u>Email : </u></b>" + email + "\n"
    out += "<b><u>Message : </u></b>\n***\n" + message + "\n***\n\n"
    out += "=>https://www.squama.fr/login"
    return out;
}
