import { FunctionComponent, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { convert_date_to_UTC, format_date } from '../../helpers/format-date';
import { fw_nicely_displayed, generic_uuid_nicely_displayed, sensor_hw_nicely_displayed, sensor_result_string, transmitter_hw_number_to_str, transmitter_hw_str } from '../../helpers/station-helper';
import TextTable from '../TextTable';
import { io } from "socket.io-client";
import {authHeader} from '../../helpers/auth-headers';
import SiteService from '../../services/site-service';
import SensorTestValue from '../../models/sensor-test-value';
import SensorService from '../../services/sensor-service';

type Props = {
  uuid_1: number,
  uuid_2: number,
  uuid_3: number
};
const ModalShowOneSensorTest: FunctionComponent<Props> = ({uuid_1, uuid_2, uuid_3}) => {
  const displayName = "ModalShowOneSensorTest:";
  const enableDebug = true;
  const enableMoreDebug = true;
  const [sensorTestValue, setSensorTestValue] = useState<SensorTestValue|null>();
  const [showModal, setshowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [inputComments, setInputComments] = useState("");

  useEffect(() => {
    if (enableDebug) console.log(displayName,"useEffect");
    if(!showModal){return;}
    /* BEGINNING websocket connection */
    const socket = io(SiteService.getWebsocketUrl(), {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
      extraHeaders: {
        "my-custom-header": "1234" // WARN: this will be ignored in a browser
      }
    });
    /* END websocket connection */
    /* BEGINNING listenner setup */
    socket.on("answer", (answer: any) => {
      if (enableDebug) console.log(displayName,"Answer:", answer);
    });
    socket.on("sensor_tests_desc", (the_sensors_test_desc_str: string) => {
      const the_sensors_test_desc = JSON.parse(the_sensors_test_desc_str);
      if (enableMoreDebug) console.log(displayName,"the_sensors_test_desc:", the_sensors_test_desc);
      if(the_sensors_test_desc[0]){
        setSensorTestValue(the_sensors_test_desc[0]);
        setInputComments(the_sensors_test_desc[0].comments);
      }
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      console.log(displayName,"logged_out:", logged_out_desc_str);
      setSensorTestValue(null);
    });
    /* END listenner setup */
    /* START websocket requests */
    if(showModal || reload){
      if (enableDebug) console.log(displayName,"Request all sensors_desc");
      setSensorTestValue(null);
      const the_request = {
        type: "sensor_tests_desc",
        number: 1,
        liste: [{"uuid_1":uuid_1,"uuid_2":uuid_2,"uuid_3":uuid_3}],
        from: "uuid",
        token: authHeader()
      }
      socket.emit("request", the_request);
      setReload(false);
    }
    /* END websocket requests */
    /* START websocket cleanup handler */
    return function cleanup() {
      if (enableDebug) console.log(displayName,"useEffect.cleanup")
      socket.disconnect();
    };
    /* END websocket cleanup handler */
  }, [showModal,reload]);

  const handleCloseModal = () => setshowModal(false);
  const handleShowModal = () => setshowModal(true);
  const handleReload = () => setReload(true);

  function modal_show_one_sensor_test() {
    
    function fill_two_lines(the_value:string|string[]|number, title:boolean, class_name_add:string = "") {
      return (
          <tr><td colSpan={2} className={((title)?"panel-installation-transmitter-table-head ":"panel-installation-transmitter-table-element ")+class_name_add}>{the_value?.toString()}</td></tr>
      )
    }
    function one_element(left:string, right:string|string[]|number, left_class:string = "", right_class:string = "", data_tip_string:string = "") {
      return (
        <tr data-tip={data_tip_string}>
          <td className={"panel-installation-transmitter-table-head "+left_class}>{left}</td>
          <td className={"panel-installation-transmitter-table-element "+right_class}>{right?.toString()}</td>
        </tr>
      )
    }


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      const local_debug = false;
      e.preventDefault();
      local_debug && console.log(displayName,"Setup form is valid")
      if(sensorTestValue && (sensorTestValue!==undefined)){
        sensorTestValue.comments = inputComments;
        SensorService.updateSensorTestValue(sensorTestValue).then(() => {
          enableDebug && console.log(displayName,"Sent update");
          //history(window.location.pathname);
          //window.location.reload();
        });
      }
    }

    return (
      <Modal
        dialogClassName="modal-show-one-sensor-test"
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header translate="yes" closeButton>
          <Modal.Title>Résutats du test du capteur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {(sensorTestValue)&&(
            <form onSubmit={e => handleSubmit(e)}>
              <table className="modal-one-sensor-test-table">
                <tbody className="panel-installation-transmitter-table-line">
                  {fill_two_lines("Capteur concerné", true)}
                  {one_element(
                    "UUID",
                    generic_uuid_nicely_displayed(sensorTestValue.c_uuid_1,sensorTestValue.c_uuid_2,sensorTestValue.c_uuid_3)
                  )}
                  {one_element("SensorTestValue.id",sensorTestValue.id)}
                  {one_element("Date du test",(sensorTestValue.created)&&format_date(sensorTestValue.created))}
                  {one_element("FW",fw_nicely_displayed(sensorTestValue.c_fw_version))}
                  {one_element("HW",sensor_hw_nicely_displayed(sensorTestValue.c_hw_version))}
                  {one_element("Commentaires Initial:",sensorTestValue.comments)}
                  {fill_two_lines("Commentaires:", true)}
                  <tr><td colSpan={2} className="panel-installation-transmitter-table-element">
                  <textarea className="modal-one-sensor-test-table-textarea panel-station-installer-line-height" id={"comments"} cols={5} value={inputComments} onChange={e => setInputComments(e.target.value)}/>
                  </td></tr>
                  {one_element("Capteur WL analogique:",sensorTestValue.c_wl_analog_1)}
                  {one_element("Capteur Alimentation:",(sensorTestValue.c_vin_mv)&&(sensorTestValue.c_vin_mv+"mv"))}
                  {one_element("Température:",(sensorTestValue.c_temperature_1)&&(sensorTestValue.c_temperature_1+"°C"))}
                  {one_element("Pression:",(sensorTestValue.c_pression_1)&&(sensorTestValue.c_pression_1+"(10mbar)"))}
                  {one_element("Température Capteur de Pression:",sensorTestValue.c_pression_temperature_1)}
                  {one_element("Resultat de test:",sensorTestValue.c_test_result)}
                  {fill_two_lines("Capteur connecté en sortie:", true)}
                  {one_element("Température:",sensorTestValue.c_next_temperature+"°C")}
                  {one_element("Capteur Alimentation:",(sensorTestValue.c_next_vin_mv)&&(sensorTestValue.c_next_vin_mv+"mv"))}
                  {fill_two_lines("Transmetteurs testeur:", true)}
                  {one_element("Transmetteur HW",transmitter_hw_number_to_str(sensorTestValue.t_hw_version))}
                  {one_element("Transmetteur FW",fw_nicely_displayed(sensorTestValue.t_fw_version))}
                  {one_element("Transmetteur MAC",sensorTestValue.t_mac)}
                  {one_element("Transmetteur ID",sensorTestValue.t_id)}
                  {one_element("Transmetteur Connection",sensorTestValue.t_conn_type)}
                  {one_element("Transmetteur Opérateur",sensorTestValue.t_operator)}
                  {one_element("Transmetteur Batterie(%)",sensorTestValue.t_battery+"%")}
                  {one_element("Transmetteur Batterie(mv)",sensorTestValue.t_battery_mv+"mv")}
                  <button type="submit" className="btn-station-card align-self-center">Valider</button>
                </tbody>
              </table>
            </form>
             )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="squama-btn-navbar squama-btn-gestion-modal" onClick={handleReload}>Reload</button>
          <button type="button" className="squama-btn-navbar squama-btn-gestion-modal" onClick={handleCloseModal}>
            Fermer
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
  return (
    <div>
      {modal_show_one_sensor_test()}
      <div onClick={handleShowModal}>
        {generic_uuid_nicely_displayed(uuid_1, uuid_2, uuid_3)}
      </div>
    </div>
  )
}

export default ModalShowOneSensorTest;
