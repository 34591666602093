import AuthenticationService from '../services/authentication-service';

export function isCurrentUserGod(){
    if (AuthenticationService.getUserIsGod()){
        return true;
    }
    return false;
}

export function isCurrentUserAdminNanogis(){
    if (AuthenticationService.getUserIsAdminNanogis()){
        return true;
    }
    return false;
}

export function isLoggedIn(){
    if (AuthenticationService.getUserID()!==undefined){
        return true;
    }
    return false;
}
