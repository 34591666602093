import { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { io } from "socket.io-client";

import SquamaNavbar from '../components/squama-navbar';
import LoaderLogo from "../img/loader-661x661.gif";

import Station from '../models/station';
import Company from '../models/company';
import { authHeader } from '../helpers/auth-headers';
import SiteService from '../services/site-service';
import { get_all_typed_analog_sensors_from_station, get_all_water_level_sensors_from_station, return_logo_from_type } from '../helpers/station-helper';
import TextTable from '../components/TextTable';
import ReactTooltip from 'react-tooltip';
import { numberForcedOneDecimal } from '../helpers/tools';

const SquamaCompanyListBoard: FunctionComponent = () => {
  const displayName = "SquamaCompanyListBoard:";
  const enableDebug = false;
  const enableCycleDebug = false;
  const enableDebugUseEffect = false;
  const enableMoreDebug = false;
  const params = useParams<{ id: string }>();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [stations, setStations] = useState<Station[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number>(0);

  const history = useNavigate();

  useEffect(() => {
    (enableDebugUseEffect) && console.log(displayName, "useEffect:", "WEBSOCKET");
    /* BEGINNING websocket connection */
    const socket = io(SiteService.getWebsocketUrl(), {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
      extraHeaders: {
        "my-custom-header": "1234" // WARN: this will be ignored in a browser
      }
    });
    /* END websocket connection */
    /* BEGINNING listenner setup */
    socket.on("answer", (answer: any) => {
      if (answer.type === "station_id") {
        (enableDebug) && console.log(displayName, "Answer for all station_id:", answer);
        //station_id answer list the station_id that the user is allowed to access for the company previously requested
        //we will ask station_desc by station_id
        const the_request: any = {
          type: "stations_desc",
          number: answer.number,
          liste: answer.liste,
          from: "station_id",
          token: authHeader()
        }
        socket.emit("request", the_request);
      }
    });
    socket.on("companies_desc", (the_companies_desc_str: string) => {
      const the_companies = JSON.parse(the_companies_desc_str);
      if (enableMoreDebug) console.log(displayName, "Companies:", the_companies);
      setCompanies(the_companies);
      if (!params.id) {
        history("/companies/" + the_companies[0].id.toString());
      }
    });
    socket.on("stations_desc", (the_stations_desc_str: string) => {
      const the_stations = JSON.parse(the_stations_desc_str);
      if (enableDebug) console.log(displayName, "Stations length:", the_stations.length);
      if (enableMoreDebug) console.log(displayName, "Station:", the_stations);
      setStations(the_stations);
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      console.log(displayName, "logged_out:", logged_out_desc_str);
      setStations([]);
    });
    /* END listenner setup */
    /* START websocket requests */
    if (enableDebug) console.log(displayName, "Request all companies_desc");
    const the_request: any = {
      type: "companies_desc",
      number: 0,
      liste: [],
      from: "token",
      token: authHeader()
    }
    socket.emit("request", the_request);
    /* END websocket requests */
    if (params.id) {
      if (enableDebug) console.log(displayName, "Display only stations of the company");
      setSelectedCompany(Number(+params.id));
      setStations([]);
      const the_request: any = {
        type: "station_id",
        number: 0,
        from: "company_id",
        liste: [+params.id],
        filter: [],
        token: authHeader()
      }
      socket.emit("request", the_request);
    }
    /* START websocket cleanup handler */
    return function cleanup() {
      if (enableDebug) console.log(displayName, "useEffect.cleanup")
      socket.disconnect();
    };
    /* END websocket cleanup handler */
  }, [params.id]);

  function is_btn_selected(currentShowedCompanyId: number) {
    if (currentShowedCompanyId === selectedCompany) {
      return "btn-selected"
    }
    return ""
  }

  function disable_select_company() {
    if (selectedCompany === 0) {
      return true;
    }
    return false;
  }

  if (enableCycleDebug) {
    console.log(displayName, "---- CYCLE ----");
    console.log(displayName, "stations:", stations);
  }
  function get_station_general_status(station: Station): string {
    if (station && station.least_days_since_last_trame_received > 1) {
      return "grey";
    }
    if (station) {
      return station.general_status;
    }
    return "grey";
  }

  function get_station_current_temperature(station: Station): string {
    const temperature_sensors = get_all_typed_analog_sensors_from_station(station, "temperature");
    if (temperature_sensors?.[0]) {
      return String(numberForcedOneDecimal(temperature_sensors[0].current_value)) + "°C";
    }
    return "Inconnue";
  }

  function get_station_min_max_temperature(station: Station): string {
    const temperature_sensors = get_all_typed_analog_sensors_from_station(station, "temperature");
    if (temperature_sensors?.[0]?.min && temperature_sensors?.[0]?.max) {
      return String(numberForcedOneDecimal(temperature_sensors[0].min)) + "°C - " + String(numberForcedOneDecimal(temperature_sensors[0].max)) + "°C";
    }
    return "Inconnue";
  }

  function get_station_mean_temperature(station: Station): string {
    const temperature_sensors = get_all_typed_analog_sensors_from_station(station, "temperature");
    if (temperature_sensors?.[0]) {
      return String(numberForcedOneDecimal(temperature_sensors[0].mean)) + "°C";
    }
    return "Inconnue";
  }

  function get_station_temperature_status(station: Station): string {
    const temperature_sensors = get_all_typed_analog_sensors_from_station(station, "temperature");
    if (temperature_sensors?.[0]) {
      return temperature_sensors[0].general_status;
    }
    return "grey";
  }

  function get_station_waterlevel_status(station: Station): string {
    let waterlevel_sensors = get_all_water_level_sensors_from_station(station);
    if (waterlevel_sensors?.[0]) {
      return waterlevel_sensors[0].general_status;
    }
    return "grey";
  }

  function get_station_id_name_dict(station: Station) {
    if (station) {
      return { id: station.id, name: station.name };
    }
    return { id: 0, name: "" };
  }
  const light_column_size = 120;
  const TextValueColumns =
    [
      {
        id:"type",
        header: "Type",
        accessorKey: "type",
        cell: (info: any) => (<div className="flex items-center" data-tip={info.getValue()}>{return_logo_from_type(info.getValue(), "logo-sensor-half-size-png")}</div>),
        size: 80,
        //size: 200, //starting column size
        //minSize: 50, //enforced during column resizing
        //maxSize: 500, //enforced during column resizing
      },
      {
        id:"state",
        header: "Etat de la station",
        accessorFn: (row: any) => get_station_general_status(row),
        cell: (info: any) => <div className={info.getValue() + "led led-sensor-2x"}></div>,
        size: light_column_size,
      },
      {
        id:"name",
        header: "Nom de la station",
        accessorFn: (row: any) => get_station_id_name_dict(row),
        cell: (info: any) => <Link className=" flex text-left" to={'/stations/' + info.getValue().id + '/setup'}>{info.getValue().name}</Link>,
        minSize: 100,
        maxSize: 500,
      },
      {
        id:"t_current",
        header: "T°C mesurée instantanée",
        accessorFn: (row: any) => get_station_current_temperature(row),
        size: light_column_size,
      },
      {
        id:"t_min_max",
        header: "T°C min-max journalière",
        accessorFn: (row: any) => get_station_min_max_temperature(row),
        size: light_column_size,
      },
      {
        id:"t_mean",
        header: "T°C moyenne journalière",
        accessorFn: (row: any) => get_station_mean_temperature(row),
        size: light_column_size,
      },
      {
        id:"t_state",
        header: "État capteur température",
        accessorFn: (row: any) => get_station_temperature_status(row),
        cell: (info: any) => <div className={info.getValue() + "led led-sensor-2x"}></div>,
        size: light_column_size,
      },
      {
        id:"wl_state",
        header: "État capteur eau",
        accessorFn: (row: any) => get_station_waterlevel_status(row),
        cell: (info: any) => <div className={info.getValue() + "led led-sensor-2x"}></div>,
        size: 95,
      }
    ]

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      {(companies && (companies?.length > 1)) ? (<div><hr></hr></div>) : (null)}
      <div className="row-navbar">
        {(companies) ? (undefined) : (<div><img className="loader-logo" src={LoaderLogo} alt="" /></div>)}
        {companies && (companies?.length > 1) && companies.map(company => (
          <div key={"div-" + company.id} className="squama-item-navbar squama-item-navbar-companies">
            <Link to={'/companies/' + company.id + '/list'}>
              <button type="button" key={"button-" + company.id} className={"squama-btn-navbar-companies " + is_btn_selected(company.id)} disabled={disable_select_company()}>
                {company.name}
              </button>
            </Link>
          </div>
        ))}
      </div>
      <div><hr></hr></div>
      <div className="main-center-div the-background">
        <div className='flex-station-cards-column'>
          <div className="panel-station-default">
            <div className="panel-station-heading panel-station-thermeau-heading">
              <div className='station_title_bar station_title_text items-center'>
                AFFICHAGE LISTE
              </div>
            </div>
            {
              (stations?.length>0) && (
                <TextTable
                  orderBy='state'
                  displayFooter={false}
                  orderByDesc={true}
                  allowPagination={true}
                  numberOfElementDisplayed={1000}
                  searchedText={["id", "mac"]}
                  allValues={stations}
                  valuesColumns={TextValueColumns}
                  classNameHeader="company-list-table-text-thead"
                  classNameTH="company-list-table-text-td-th"
                  classNameTD="company-list-table-text-td-th"
                />
              )
            }
            <div className="panel-station-footer"></div>
          </div>
        </div>
      </div>
      <ReactTooltip type="light" />
    </div>
  );
}
export default SquamaCompanyListBoard;
