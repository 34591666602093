import {authHeader} from '../helpers/auth-headers';
import CharAnalogValues from '../models/chart-analog-values';
import TextValue from '../models/text-value';
import { CharWaterlevelValuesAndDescriptors } from '../models/chart-waterlevel-value';
import SiteService from './site-service';
import SensorTestValue from '../models/sensor-test-value';

export default class SensorService {

  static getDownloadAnalogSensor(analogSensorId: number, period: string): Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
      'period': period
    }
    return fetch(SiteService.getApiUrl()+`analog_sensor_download/${analogSensorId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getChartgetSentNumber(transmitterId: number, period: string): Promise<CharAnalogValues> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
      'period': period
    }
    return fetch(SiteService.getApiUrl()+`sent_number_transmitter_chart/${transmitterId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getChartBatterySensor(transmitterId: number, period: string): Promise<CharAnalogValues> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
      'period': period
    }
    return fetch(SiteService.getApiUrl()+`battery_transmitter_chart/${transmitterId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getChartAnalogSensor(analogSensorId: number, period: string): Promise<CharAnalogValues> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
      'period': period
    }
    return fetch(SiteService.getApiUrl()+`analog_sensor_chart/analog/${analogSensorId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getDownloadWaterlevelSensor(waterlevelSensorId: number, period: string): Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
      'period': period
    }
    return fetch(SiteService.getApiUrl()+`waterlevel_sensor_download/${waterlevelSensorId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getDownloadWaterlevelSensorPerStation(StationId: number, period: string): Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
      'period': period
    }
    return fetch(SiteService.getApiUrl()+`station_waterlevel_sensor_download/${StationId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getDownloadSensorsBrutValuesPerStation(StationId: number, period: string): Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
      'period': period
    }
    return fetch(SiteService.getApiUrl()+`station_brut_sensors_download/${StationId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getDownloadTransmittersBrutValuesPerStation(StationId: number, period: string): Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
      'period': period
    }
    return fetch(SiteService.getApiUrl()+`station_brut_transmitters_download/${StationId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getDownloadBatteriesPerStation(StationId: number, period: string): Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
      'period': period
    }
    return fetch(SiteService.getApiUrl()+`station_batteries_download/${StationId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getChartWaterlevelSensorPerStation(StationId: number, period: string): Promise<CharWaterlevelValuesAndDescriptors> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
      'period': period
    }
    return fetch(SiteService.getApiUrl()+`station_waterlevel_sensor_chart/${StationId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getChartWaterlevelAnalogSensor(waterlevelAnalogSensorId: number, period: string): Promise<CharAnalogValues> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
      'period': period
    }
    return fetch(SiteService.getApiUrl()+`analog_sensor_chart/waterlevel/${waterlevelAnalogSensorId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static getDatasTextSensor(sensorId: number, period: string): Promise<TextValue[]> {
    const headers = {
      'Content-Type': 'application/json',
      'token': authHeader(),
      'period': period
    }
    return fetch(SiteService.getApiUrl()+`text_sensor_data/${sensorId}`,{headers})
    .then(response => response.json())
    .catch(error => this.handleError(error));
  }

  static updateSensorTestValue(sensor_test_value: SensorTestValue): Promise<SensorTestValue> {
    return fetch(SiteService.getApiUrl()+`sensortestvalue/${sensor_test_value.id}`, {
        method: 'PUT',
        body: JSON.stringify(sensor_test_value),
        headers: { 'Content-Type': 'application/json',
                  'token': authHeader()}
      })
      .then(response => response.json())
      .catch(error => this.handleError(error));
  }

  static isEmpty(data: Object): boolean {
    return Object.keys(data).length === 0;
  }
  
  static handleError(error: Error): void {
    console.error(error);
  }
}
